export const TOGGLE_NAVTOGGLE = "TOGGLE_NAVTOGGLE";
export type TOGGLE_NAVTOGGLE = typeof TOGGLE_NAVTOGGLE;

export const TOGGLE_INFOSUBNAV = "TOGGLE_INFOSUBNAV";
export type TOGGLE_INFOSUBNAV = typeof TOGGLE_INFOSUBNAV;

export const CHANGE_INFOSUBNAV = "CHANGE_INFOSUBNAV";
export type CHANGE_INFOSUBNAV = typeof CHANGE_INFOSUBNAV;

export const TOGGLE_DATASETSSUBNAV = "TOGGLE_DATASETSSUBNAV";
export type TOGGLE_DATASETSSUBNAV = typeof TOGGLE_DATASETSSUBNAV;

export const CHANGE_DATASETSSUBNAV = "CHANGE_DATASETSSUBNAV";
export type CHANGE_DATASETSSUBNAV = typeof CHANGE_DATASETSSUBNAV;

export const TOGGLE_SEARCHBAR_TOGGLE = "TOGGLE_SEARCHBAR_TOGGLE";
export type TOGGLE_SEARCHBAR_TOGGLE = typeof TOGGLE_SEARCHBAR_TOGGLE;
