import axios from "axios";
import {ISiteCoreResponse} from "./SiteCoreActions";

export function languageToSiteCoreLanguage(language: string): string {
    switch (language) {
        case "nl":
            return "nl-NL";
        case "en":
            return "en-GB";
        case "de":
            return "en-GB";
        default: return "nl-NL";
    }
}

const createFilter = (filters: IODataFilter[]) => {
    return filters.map((filter) => {
        return filter.Property.concat(" ").concat(filter.Operator.toString()).concat(" ").concat(filter.Value);
    }).join(" and "); // improve query. Now you can only make an "AND" query.
};

interface ISortOrder {
    Property: string;
    Sort: Sorting;
}

export enum ODataFilterOperator {
    //Or = "or",
    Equals = "eq",
    NotEquals = "ne",
    GreaterThan = "gt",
    GreaterThanOrEqual = "ge",
    LesserThan = "lt",
    LesserThanOrEqual = "le"
}

// improve query. Now you can only make an "AND" query.
/*
export interface IODataFilter2 {
    OperandA?: IODataFilter2 | string;
    Operator: ODataFilterOperator;
    OperandB?: IODataFilter2 | string;
}

const createFilter2 = (filter: IODataFilter2): string => {
    if (typeof filter.OperandA === "string" && typeof filter.OperandB === "string") {
        return filter.OperandA.concat(" ").concat(filter.Operator.toString()).concat(" ").concat(filter.OperandB);
    } else if (typeof filter.OperandA !== "string" && typeof filter.OperandB === "string") {
        console.error("not yet implemented");
        throw new Error("not yet implemented");
    } else if (typeof filter.OperandA === "string" && typeof filter.OperandB !== "string") {
        console.error("not yet implemented");
        throw new Error("not yet implemented");
    } else if (typeof filter.OperandA !== "string" && typeof filter.OperandA !== "undefined" &&
                typeof filter.OperandB !== "string" && typeof filter.OperandB !== "undefined") {
        return createFilter2(filter.OperandA).concat(" ").concat(filter.Operator.toString()).concat(" ").concat(createFilter2(filter.OperandB));
    }
    return "";
};

const convertFilter = (filter: IODataFilter): IODataFilter2 => {
    return {OperandA: filter.Property, Operator: filter.Operator, OperandB: filter.Value};
};

const createPagesFilter2 = (filters: IODataFilter[]) => {

    const t: IODataFilter2 = {
        OperandA: { OperandA: { OperandA: "UniqueId", Operator: ODataFilterOperator.Equals, OperandB: "'b4885f0d-e314-4d17-9624-3c74443b3f29-nl-nl'" },
                    Operator: ODataFilterOperator.Or,
                    OperandB: { OperandA: "UniqueId", Operator: ODataFilterOperator.Equals, OperandB: "'tatata'" }
        },

            //{ OperandA: "UniqueId", Operator: ODataFilterOperator.Equals, OperandB: "'b4885f0d-e314-4d17-9624-3c74443b3f29-nl-nl'" },
        Operator: ODataFilterOperator.Or,
        OperandB: { OperandA: "UniqueId", Operator: ODataFilterOperator.Equals, OperandB: "'6ba3a81f-8256-4f24-ac23-35e711c3519d-nl-nl'"}
    };
    return t;
};
*/

export interface IODataFilter {
    Property: string;
    Operator: ODataFilterOperator;
    Value: string;
}

export enum Sorting {
    asc = "asc",
    desc = "desc"
}

export const createSiteCoreUrlByContentPath = (baseUrl: string, siteCorePath: string[], top?: number, filters?: IODataFilter[], orderby?: ISortOrder) => {
    let base = `${baseUrl}(Path='${siteCorePath.join("_")}')`;
    if (top) {
        base = base.concat(`?$top=${top}`);
    }
    const url: string[] = [base];
    if (filters && filters.length > 0) {
        url.push(`$filter=${createFilter(filters)}`);
    }

    if (orderby) {
        const key = orderby.Property;
        const value = orderby.Sort;
        url.push(`$orderby=${key} ${value}`);
    }

    return url.join("&");
};

const createPagesFilter = (filters: IODataFilter[]) => {
    return filters.map((filter) => {
        return filter.Property.concat(" ").concat(filter.Operator.toString()).concat(" ").concat(filter.Value);
    }).join(" or "); // improve query. Now you can only make an "AND" query.
};

export const createSiteCoreUrlUniquePage = (baseUrl: string, top?: number, filters?: IODataFilter[], orderby?: ISortOrder) => {
    let base = `${baseUrl}Pages?`;
    if (top) {
        base = base.concat(`$top=${top}`);
    }
    const url: string[] = [base];
    if (filters && filters.length > 0) {
        url.push(`$filter=${createPagesFilter(filters)}`);
    }

    if (orderby) {
        const key = orderby.Property;
        const value = orderby.Sort;
        url.push(`$orderby=${key} ${value}`);
    }

    return url.join("&");
};

export const generateSitecoreUniqueId  = (currentLanguage: string, siteCoreId: string) => {
    return `'${siteCoreId}-${languageToSiteCoreLanguage(currentLanguage).toString().toLowerCase()}'`;
};

export const getSiteCoreContentByPath = (siteCoreBaseUrl: string, siteCorePath: string[], top: number, filters: IODataFilter[], orderby: ISortOrder, timeout?: number) => {
    return axios.get(createSiteCoreUrlByContentPath(
        `${siteCoreBaseUrl}GetSearchResultsByPath`,
        siteCorePath,
        top,
        filters,
        orderby), { timeout: (timeout !== undefined) ? timeout : 0 });
};

export const getSiteCorePages = (siteCoreBaseUrl: string, siteCoreResponses: ISiteCoreResponse[], orderby: ISortOrder, timeout?: number) => {
    const uniqueIds: string[] = siteCoreResponses.map((e: ISiteCoreResponse) => {
        return `'${e.UniqueId}'` || "";
    });

    const filters: IODataFilter[] = uniqueIds.map((e: string) =>
        ({Property: "UniqueId", Operator: ODataFilterOperator.Equals, Value: e }));
    return axios.get(createSiteCoreUrlUniquePage(
        `${siteCoreBaseUrl}`,
        undefined,
        filters,
        orderby), { timeout: (timeout !== undefined) ? timeout : 0 });
};
