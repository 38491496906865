import {ISiteCorePageResponse, ISiteCoreResponse} from "../actions/SiteCore/SiteCoreActions";

const siteCoreFaqResponses: ISiteCorePageResponse[] = [
    {
        PageType: "Page",
        ImageAlt: "",
        Image: "https://cdn.cbs.nl/images/645a554d546a364b635368592f5349363257555133673d3d/900x450.jpg",
        Category: undefined,
        Photographer: undefined,
        LeadText: undefined,
        Body: "<p>U kunt contact opnemen met de <a href=\"https://www.cbs.nl/nl-nl/over-ons/contact/infoservice\" target=\"_blank\">Infoservice </a>van het CBS (telefoon 088 5707070).</p>\n<p>Het CBS heeft een LinkedIn groep &lsquo;<a href=\"https://www.linkedin.com/groups/5190698/\" target=\"_blank\">Centraal Bureau voor de Statistiek; Open Data&rsquo;</a>. Hier verschijnen&nbsp;aankondigingen&nbsp;en is plaats&nbsp;voor opmerkingen over de odata services.</p>\n<p>&nbsp;</p>",
        Authors: undefined,
        Location: "Nederland",
        References: { Items: []},
        Downloads: { Items: []},
        Links: { Items: []},
        Url: "https://www-acc.cbs.nl/nl-nl/statline/dataportaal/faq/heeft-het-cbs-een-helpdesk-voor-vragen-over-open-data-",
        Title: "Heeft het CBS een helpdesk voor vragen over open data?",
        MetaDescription: "Testvraag",
        TaxonomyTags: [],
        Themes: [],
        Series: [],
        UniqueId: "6ba3a81f-8256-4f24-ac23-35e711c3519d-nl-nl",
        Language: "nl-NL",
        Version: 1,
        SortOrder: 0,
        ItemNameLowerCase: "vraag1",
        Updated: "2019-08-19T16:21:13+02:00",
        Created: "2019-07-23T11:41:07+02:00",
        ReleaseTime: "0001-01-01T00:00:00Z"
    },
    {
        PageType: "Page",
        ImageAlt: "",
        Image: "https://cdn.cbs.nl/images/645a554d546a364b635368592f5349363257555133673d3d/900x450.jpg",
        Category: undefined,
        Photographer: undefined,
        LeadText: undefined,
        Body: "<p>Er zijn twee manieren om toegang tot datasets te krijgen in het portaal voor open data. Een manier is om een of meer zoektermen in te tikken in het zoekvak rechtsboven in de menubalk (zoeken is nog in ontwikkeling). Een andere is om te bladeren door de datasets, die ingedeeld zijn per catalogus; klik hiervoor op Datasets in de menubalk.</p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>",
        Authors: undefined,
        Location: "Nederland",
        References: { Items: []},
        Downloads: { Items: []},
        Links: { Items: []},
        Url: "https://www-acc.cbs.nl/nl-nl/statline/dataportaal/faq/hoe-kan-ik-datasets-vinden-in-het-dataportaal-",
        Title: "Hoe kan ik datasets vinden in het dataportaal?",
        MetaDescription: "",
        TaxonomyTags: [],
        Themes: [],
        Series: [],
        UniqueId: "b4885f0d-e314-4d17-9624-3c74443b3f29-nl-nl",
        Language: "nl-NL",
        Version: 1,
        SortOrder: 200,
        ItemNameLowerCase: "vraag2",
        Updated: "2019-08-19T16:21:41+02:00",
        Created: "2019-07-31T13:34:38+02:00",
        ReleaseTime: "0001-01-01T00:00:00Z"
    },
    {
        PageType: "Page",
        ImageAlt: "",
        Image: "https://cdn.cbs.nl/images/645a554d546a364b635368592f5349363257555133673d3d/900x450.jpg",
        Category: undefined,
        Photographer: undefined,
        LeadText: undefined,
        Body: "You can contact the Statistics Netherlands&rsquo; <a href=\"https://www.cbs.nl/en-gb/about-us/contact/infoservice\" target=\"_blank\">Infoservice</a>.\n<p>The CBS has a LinkedIn group &lsquo;<a href=\"https://www.linkedin.com/groups/5190698/\" target=\"_blank\">Centraal Bureau voor de Statistiek; Open Data&rsquo;</a>. Announcements are made in this group and you can&nbsp;post comments about the odata services.</p>\n<p>&nbsp;</p>",
        Authors: undefined,
        Location: "Nederland",
        References: { Items: []},
        Downloads: { Items: []},
        Links: { Items: []},
        Url: "https://www-acc.cbs.nl/en-gb/statline/dataportaal/faq/does-the-cbs-have-a-helpdesk-for-questions-about-open-data-",
        Title: "Does the CBS have a helpdesk for questions about open data?",
        MetaDescription: "",
        TaxonomyTags: [],
        Themes: [],
        Series: [],
        UniqueId: "6ba3a81f-8256-4f24-ac23-35e711c3519d-en-gb",
        Language: "en-GB",
        Version: 1,
        SortOrder: 0,
        ItemNameLowerCase: "vraag1",
        Updated: "2019-08-19T16:24:01+02:00",
        Created: "2019-07-31T10:33:52+02:00",
        ReleaseTime: "0001-01-01T00:00:00Z"
    },
    {
        PageType: "Page",
        ImageAlt: "",
        Image: "https://cdn.cbs.nl/images/645a554d546a364b635368592f5349363257555133673d3d/900x450.jpg",
        Category: undefined,
        Photographer: undefined,
        LeadText: undefined,
        Body: "<p>There are two ways to access the datasets in the open data portal.&nbsp;One is to search for data by entering one or more search terms in the search box at the upper right corner of the menu bar (searching is still under construction). The other is to browse through the datasets, which have been classified by catalogus;&nbsp;you can do this by clicking the Datasets tab.</p>",
        Authors: undefined,
        Location: "Nederland",
        References: { Items: []},
        Downloads: { Items: []},
        Links: { Items: []},
        Url: "https://www-acc.cbs.nl/en-gb/statline/dataportaal/faq/how-can-i-find-datasets-in-the-data-portal-",
        Title: "How can I find datasets in the data portal?",
        MetaDescription: "",
        TaxonomyTags: [],
        Themes: [],
        Series: [],
        UniqueId: "b4885f0d-e314-4d17-9624-3c74443b3f29-en-gb",
        Language: "en-GB",
        Version: 1,
        SortOrder: 200,
        ItemNameLowerCase: "vraag2",
        Updated: "2019-08-19T16:25:11+02:00",
        Created: "2019-07-31T13:34:37+02:00",
        ReleaseTime: "0001-01-01T00:00:00Z"
    }
];

export const siteCoreResponsesEnglishFaq: ISiteCoreResponse[] = siteCoreFaqResponses.filter((e: ISiteCoreResponse) => e.Language === "en-GB");

export const siteCoreResponsesDutchFaq: ISiteCoreResponse[] = siteCoreFaqResponses.filter((e: ISiteCoreResponse) => e.Language === "nl-NL");
