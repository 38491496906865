import React from "react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import "./BreadCrumbs.scss";

export interface IBreadCrumbItem {
    key: number;
    text: string | undefined;
    url: string;
}

export interface IBreadCrumbsProps {
    open: boolean;
    items: IBreadCrumbItem[];
}

export const BreadCrumbs: React.FunctionComponent<IBreadCrumbsProps> = ({ open, items }) =>
{
    const [isOpen, setIsOpen] = React.useState(open);
    
    const getListItem = (item: IBreadCrumbItem) => {
        return <li key={item.key} className="breadcrumb-item">
            <Link to={item.url} className="">
                {item.text}
            </Link>
        </li>
    }

    const _openMobileMenu = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setIsOpen((prev) => !prev);
    }

    const _renderMobile = (items: IBreadCrumbItem[]) => {
        const reversedItems = items.map(e => e).reverse();
        const text = reversedItems[0].text + " / ... / " + reversedItems[items.length - 1].text;
        return (
            <div>
                <a href="/" onClick={(e) => _openMobileMenu(e)} aria-expanded={isOpen} aria-controls="div-breadcrumb-mob">{text}</a>
                {
                    isOpen ?
                        <div className="breadcrumb-mob" id="div-breadcrumb-mob">
                            <ol>
                                {reversedItems.map((item => getListItem(item)))}
                            </ol>
                        </div>
                        :
                        <></>
                }
            </div>

        );
    }

    const _renderDesktop = (items: IBreadCrumbItem[]) => {
        const reversedItems = items.map(e => e).reverse();
        return (
            <div>
                <ol>
                    {reversedItems.map((item => getListItem(item)))}
                </ol>
            </div>
        );
    }

    return (
        <nav className="breadcrumbs" aria-label="breadcrumbs">
            <MediaQuery maxWidth={992}>
                {_renderMobile(items)}
            </MediaQuery>
            <MediaQuery minWidth={992}>
                {_renderDesktop(items)}
            </MediaQuery>
        </nav>
    );
}