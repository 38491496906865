import {defaultCatalogsState, ICatalogsState} from "./ICatalogsState";
import {defaultCurrentDatasetState, ICurrentDatasetState} from "./ICurrentDatasetState";
import {defaultFiltersState, IFiltersState} from "./IFiltersState";
import { defaultLocalizationState, ILocalizationState } from "./ILocalizationState";
import {defaultSiteCoreState, ISiteCoreState} from "./ISiteCoreState";
import {defaultUIState, IUIState} from "./IUIState";
import { defaultSettingsState, ISettingsState } from "./ISettingsState";
import { defaultThemesState, IThemesState } from "./IThemesState";

export interface IStoreState {
    Themes: IThemesState;
    Catalogs: ICatalogsState;
    CurrentDataset: ICurrentDatasetState;
    Localization: ILocalizationState;
    Selection: IFiltersState;
    SiteCore: ISiteCoreState;
    UIState: IUIState;
    Settings: ISettingsState;
}

export function defaultState() {
    return {
        Themes: defaultThemesState(),
        Catalogs: defaultCatalogsState(),
        CurrentDataset: defaultCurrentDatasetState(),
        Localization: defaultLocalizationState(),
        Selection: defaultFiltersState(),
        SiteCore: defaultSiteCoreState(),
        UIState: defaultUIState(),
        Settings: defaultSettingsState()
    };
}
