import axios, {AxiosError} from "axios";
import {Dispatch} from "redux";
import {
    siteCoreResponsesDutchFaq,
    siteCoreResponsesEnglishFaq
} from "../../mockdata/SiteCoreFaqContent";
import {
    siteCoreResponsesDutchHomepage,
    siteCoreResponsesEnglishHomepage,
    siteCoreResponsesGermanHomepage
} from "../../mockdata/SiteCoreHomepageContent";
import { siteCoreResponsesDutchBanner, siteCoreResponsesEnglishBanner } from "../../mockdata/SiteCoreBannerContent";
import {siteCoreResponsesDutchInfopage, siteCoreResponsesEnglishInfopage} from "../../mockdata/SiteCoreInfopageContent";
import {
    siteCoreResponsesDutchPrivacypage,
    siteCoreResponsesEnglishPrivacypage
} from "../../mockdata/SiteCorePrivacypageContent";
import {IStoreState} from "../../types/IStoreState";
import {
    generateSitecoreUniqueId,
    getSiteCoreContentByPath,
    getSiteCorePages,
    IODataFilter,
    languageToSiteCoreLanguage,
    ODataFilterOperator,
    Sorting
} from "./SiteCoreApi";
import * as constants from "./SiteCoreConstants";
import {MAX_SITECORE_TIMEOUT_MS, privacyPageSitecoreId, urlToSitecoreUniqueId} from "./SiteCoreConstants";

// ACTION DEFINITION
export interface IActionGetPage {
    type: constants.GET_PAGE;
    siteCoreResponse: ISiteCoreResponse;
}

export interface IActionGetHomePage {
    type: constants.GET_HOMEPAGE;
    siteCoreResponses: ISiteCoreResponse[];
}

export interface IActionGetHomePageBanner {
    type: constants.GET_HOMEPAGE_BANNER;
    siteCoreResponse: ISiteCoreResponse;
}

export interface IActionGetFaq {
    type: constants.GET_FAQ;
    siteCoreResponses: ISiteCorePageResponse[];
}

export interface IActionGetInfoPage {
    type: constants.GET_INFOPAGE;
    siteCoreResponse: ISiteCoreResponse;
}

export interface IActionGetPrivacyPage {
    type: constants.GET_PRIVACYPAGE;
    siteCoreResponse: ISiteCoreResponse;
}

export interface IActionSetFetchingSiteCore {
    type: constants.SET_FETCHING_SITECORE;
    isFetching: boolean;
}

type Nullable<T> = T | null;

export interface ISiteCoreResponse {
    CbsType?: string;
    Type?: string;
    Permalink?: Nullable<string>;
    Snippet?: string;
    Image?: Nullable<string>;
    ImageAlt?: Nullable<string>;
    UnderEmbargo?: boolean;
    PublishTimeUnknown?: boolean;
    RegionalClassification?: string;
    ReleaseTime?: string;
    Language?: Nullable<string>;
    SortOrder?: number;
    Url?: Nullable<string>;
    Title?: Nullable<string>;
    Themes?: Nullable<string>[];
    UniqueId?: string;
}

export interface ISiteCorePageResponse {
    Authors?: Nullable<string>;
    Body?: Nullable<string>;
    Category?: string[];
    Created?: string;
    Downloads?: {
        Items?: any[]
    };
    Image?: Nullable<string>;
    ImageAlt?: Nullable<string>;
    ItemNameLowerCase?: Nullable<string>;
    Language?: Nullable<string>;
    LeadText?: Nullable<string>;
    Links?: {
        Items?: any[]
    };
    Location?: Nullable<string>;
    MetaDescription?: Nullable<string>;
    PageType?: string;
    Photographer?: Nullable<string>;
    References?: {
        Items?: any[]
    };
    ReleaseTime?: string;
    Series?: Nullable<string>[];
    SortOrder?: number;
    TaxonomyTags?: Nullable<string>[];
    Themes?: Nullable<string>[];
    Title?: Nullable<string>;
    UniqueId?: string;
    Updated?: string;
    Url?: Nullable<string>;
    Version?: number;
}

// Union Action Types
export type SiteCoreActions =
    IActionGetPage |
    IActionSetFetchingSiteCore |
    IActionGetHomePage |
    IActionGetInfoPage |
    IActionGetPrivacyPage |
    IActionGetHomePageBanner |
    IActionGetFaq;

// Action Creators

const getFaqSuccess = (siteCoreResponses: ISiteCorePageResponse[]): IActionGetFaq => {
    return {
        siteCoreResponses,
        type: constants.GET_FAQ
    };
};

const getHomePageBannerSuccess = (siteCoreResponse: ISiteCoreResponse): IActionGetHomePageBanner => {
    return {
        siteCoreResponse,
        type: constants.GET_HOMEPAGE_BANNER
    };
};

const getHomePageSuccess = (siteCoreResponses: ISiteCoreResponse[]): IActionGetHomePage => {
    return {
        siteCoreResponses,
        type: constants.GET_HOMEPAGE
    };
};

const getInfoPageSuccess = (siteCoreResponse: ISiteCorePageResponse): IActionGetInfoPage => {
    return {
        siteCoreResponse,
        type: constants.GET_INFOPAGE
    };
};

const getPrivacyPageSuccess = (siteCoreResponse: ISiteCorePageResponse): IActionGetPrivacyPage => {
    return {
        siteCoreResponse,
        type: constants.GET_PRIVACYPAGE
    };
};

const isFetching = (isFetching: boolean): IActionSetFetchingSiteCore => {
    return {
        isFetching,
        type: constants.SET_FETCHING_SITECORE
    };
};

//todo: create generic way for fallback data.
export function getInfoPage(language: string, uniqueId: string = ""): any {
    const getFallbackInfoPage = (currentLanguage: string) => {
        switch (currentLanguage) {
            case "nl": return siteCoreResponsesDutchInfopage;
            case "en": return siteCoreResponsesEnglishInfopage;
            case "de": return siteCoreResponsesEnglishInfopage;
            default: return siteCoreResponsesDutchInfopage;
        }
    };

    const siteCoreUniqueIdWithLanguage = (currentLanguage: string, siteCoreUniqueId: string) => {
        return `${siteCoreUniqueId}-${languageToSiteCoreLanguage(currentLanguage)}`.toLowerCase();
    };

    return (dispatch: Dispatch, getState: () => IStoreState) => {
        const { hostSiteCoreODataApiUrl } = getState().Settings;
        const { currentLanguage } = getState().Localization;
        const siteCoreUniqueId: any = urlToSitecoreUniqueId.find((e: any) => e.id === uniqueId);

        dispatch(isFetching(true));
        return getSiteCoreContentByPath(
            hostSiteCoreODataApiUrl,
            ["statline", "dataportaal", "informatie"],
            10,
            [
                    {
                        Property: "UniqueId",
                        Operator: ODataFilterOperator.Equals,
                        Value: `${generateSitecoreUniqueId(currentLanguage, siteCoreUniqueId.uniqueId)}`
                    } as IODataFilter,
                    {
                        Property: "Language",
                        Operator: ODataFilterOperator.Equals,
                        Value: `'${languageToSiteCoreLanguage(currentLanguage)}'`
                    }
                ],
            {Property: "SortOrder", Sort: Sorting.asc},
            MAX_SITECORE_TIMEOUT_MS)
            .then(({ data }: any) => {
                return axios.get(data.value[0].Permalink)
                    .then(({ data }: any) => {
                        dispatch(getInfoPageSuccess(data));
                    });
            })
            .catch((error: AxiosError) => {
                // If SiteCore times out or is not reachable respond with fallBackData
                if (!error.code || error.code === "ECONNABORTED" || error.code === "ERR_NETWORK") {
                    dispatch(getInfoPageSuccess(getFallbackInfoPage(currentLanguage)
                        .filter((e: ISiteCoreResponse) => e.UniqueId !== undefined && e.UniqueId.toLowerCase() === siteCoreUniqueIdWithLanguage(currentLanguage, siteCoreUniqueId.uniqueId))[0]));
                    return;
                }
                throw error;
            })
            .finally(() => {
                dispatch(isFetching(false));
            });
    };
}

export function getPrivacyPage(): any {
    const getFallbackPrivacyPage = (currentLanguage: string) => {
        switch (currentLanguage) {
            case "nl": return siteCoreResponsesDutchPrivacypage;
            case "en": return siteCoreResponsesEnglishPrivacypage;
            case "de": return siteCoreResponsesEnglishPrivacypage;
            default: return siteCoreResponsesDutchPrivacypage;
        }
    };

    return (dispatch: Dispatch, getState: () => IStoreState) => {
        const { hostSiteCoreODataApiUrl } = getState().Settings;
        const { currentLanguage } = getState().Localization;
        dispatch(isFetching(true));
        return getSiteCoreContentByPath(
            hostSiteCoreODataApiUrl,
            ["statline", "dataportaal"],
            10,
            [
                {
                    Property: "UniqueId",
                    Operator: ODataFilterOperator.Equals,
                    Value: `${generateSitecoreUniqueId(currentLanguage, privacyPageSitecoreId)}`
                },
                {
                    Property: "Language",
                    Operator: ODataFilterOperator.Equals,
                    Value: `'${languageToSiteCoreLanguage(currentLanguage)}'`
                } as IODataFilter],
            {Property: "SortOrder", Sort: Sorting.asc},
            MAX_SITECORE_TIMEOUT_MS)
            .then(({ data }: any) => {
                return axios.get(data.value[0].Permalink)
                    .then(({ data }: any) => {
                        dispatch(getPrivacyPageSuccess(data));
                    });
            })
            .catch((error: AxiosError) => {
                // if timeout with sitecore. Respond fallBackData
                if (!error.code || error.code === "ECONNABORTED") {
                    dispatch(getPrivacyPageSuccess(getFallbackPrivacyPage(currentLanguage)[0] as ISiteCorePageResponse));
                    return;
                }
                throw error;
            })
            .finally(() => {
                dispatch(isFetching(false));
            });
    };
}

export function getHomePage(): any {
    const getFallbackHomePageData = (currentLanguage: string) => {
        switch (currentLanguage) {
            case "nl": return siteCoreResponsesDutchHomepage;
            case "en": return siteCoreResponsesEnglishHomepage;
            case "de": return siteCoreResponsesGermanHomepage;
            default: return siteCoreResponsesDutchHomepage;
        }
    };

    const getGermanFallbackData = (dispatch: Dispatch) => {
        return new Promise((resolve, reject) => {
            resolve(getFallbackHomePageData("de"));
        })
        .then((response: any) => {
            dispatch(getHomePageSuccess(response));
        })
        .finally(() => {
            dispatch(isFetching(false));
        });
    };

    return (dispatch: Dispatch, getState: () => IStoreState) => {
        const { hostSiteCoreODataApiUrl } = getState().Settings;
        const { HomePage } = getState().SiteCore;
        const { currentLanguage } = getState().Localization;
        
        //  do we have to get new homepage data.
        if (HomePage && HomePage.length > 0 && HomePage[0].Language === languageToSiteCoreLanguage(currentLanguage)) {
            return;
        }

        dispatch(isFetching(true));

        if (currentLanguage === "de") {
            return getGermanFallbackData(dispatch);
        }

        return getSiteCoreContentByPath(
                hostSiteCoreODataApiUrl,
                ["statline", "dataportaal", "homepage", "tekstblokken"],
                10,
                [
                    {
                        Property: "Language",
                        Operator: ODataFilterOperator.Equals,
                        Value: `'${languageToSiteCoreLanguage(currentLanguage)}'`
                    } as IODataFilter],
                {Property: "SortOrder", Sort: Sorting.asc},
                MAX_SITECORE_TIMEOUT_MS)
            .then(({ data }: any) => {
                dispatch(getHomePageSuccess(data.value));
            })
            .catch((error: AxiosError) => {
                // if timeout with sitecore. Respond fallBackData
                if (error.isAxiosError && (error.message === 'Network Error' || error.code === "ECONNABORTED")) {
                    dispatch(getHomePageSuccess(getFallbackHomePageData(currentLanguage)));
                    return;
                }
                throw error;
            })
            .finally(() => {
                dispatch(isFetching(false));
            });
    };
}

export function getHomePageBanner(): any {
    const replaceSiteCoreImageUrl = (sitecoreResponse: ISiteCoreResponse): ISiteCoreResponse => {
        // https://cdn.cbs.nl/images/34475031533542435141777748646e334758625455413d3d/900x450.jpg
        const pattern = /-*(\d+)x(\d+)/;
        const desktopSize = "1200x600";

        if (sitecoreResponse && sitecoreResponse.Image) {
            sitecoreResponse.Image = sitecoreResponse.Image.replace(pattern, desktopSize);
        }
        return sitecoreResponse;
    };

    const getFallbackHomePageBannerData = (currentLanguage: string) => {
        switch (currentLanguage) {
            case "nl": return siteCoreResponsesDutchBanner;
            case "en": return siteCoreResponsesEnglishBanner;
            case "de": return siteCoreResponsesEnglishBanner;
            default: return siteCoreResponsesDutchBanner;
        }
    };

    return (dispatch: Dispatch, getState: () => IStoreState) => {
        const { hostSiteCoreODataApiUrl } = getState().Settings;
        const { HomePageBanner } = getState().SiteCore;
        const { currentLanguage } = getState().Localization;
        
        //  do we have to get new banner data.
        if (HomePageBanner && HomePageBanner.Language === languageToSiteCoreLanguage(currentLanguage)) {
            return;
        }

        dispatch(isFetching(true));

        return getSiteCoreContentByPath(
            hostSiteCoreODataApiUrl,
            ["statline", "dataportaal", "homepage", "banner"],
            10,
            [
                {
                    Property: "Language",
                    Operator: ODataFilterOperator.Equals,
                    Value: `'${languageToSiteCoreLanguage(currentLanguage)}'`
                } as IODataFilter],
            {Property: "SortOrder", Sort: Sorting.asc},
            MAX_SITECORE_TIMEOUT_MS)
            .then(({ data }: any) => {
                const sitecoreResponse: ISiteCoreResponse = data.value[0];
                replaceSiteCoreImageUrl(sitecoreResponse);
                dispatch(getHomePageBannerSuccess(sitecoreResponse));
            })
            .catch((error: AxiosError) => {
                // if timeout with sitecore. Respond fallBackData (mockdata eerste beste)
                if (error.isAxiosError && (error.message === 'Network Error' || error.code === "ECONNABORTED")) {
                    dispatch(getHomePageBannerSuccess(getFallbackHomePageBannerData(currentLanguage)[0]));
                    return;
                }
                throw error;
            })
            .finally(() => {
                dispatch(isFetching(false));
            });
    };

}

export function getFaq(): any {
    const getFallbackFaqData = (currentLanguage: string) => {
        switch (currentLanguage) {
            case "nl": return siteCoreResponsesDutchFaq;
            case "en": return siteCoreResponsesEnglishFaq;
            case "de": return siteCoreResponsesEnglishFaq;
            default: return siteCoreResponsesDutchFaq;
        }
    };

    return (dispatch: Dispatch, getState: () => IStoreState) => {
        const { hostSiteCoreODataApiUrl } = getState().Settings;
        const { Faq } = getState().SiteCore;
        const { currentLanguage } = getState().Localization;

        //  do we have to get new banner data.
        if (Faq && Faq.length > 0 && Faq[0].Language === languageToSiteCoreLanguage(currentLanguage)) {
            return;
        }

        dispatch(isFetching(true));

        return getSiteCoreContentByPath(
            hostSiteCoreODataApiUrl,
            ["statline", "dataportaal", "faq"],
            10,
            [
                {
                    Property: "Language",
                    Operator: ODataFilterOperator.Equals,
                    Value: `'${languageToSiteCoreLanguage(currentLanguage)}'`
                } as IODataFilter],
            {Property: "SortOrder", Sort: Sorting.asc},
            MAX_SITECORE_TIMEOUT_MS)
            .then(({ data }: any) => {
                const sitecoreResponses: ISiteCoreResponse[] = data.value;
                if (sitecoreResponses.length === 0) {
                    dispatch(getFaqSuccess([]));
                } else {
                    return getSiteCorePages(hostSiteCoreODataApiUrl, sitecoreResponses, {Property: "SortOrder", Sort: Sorting.asc}).then(({data}: any) => {
                        dispatch(getFaqSuccess(data.value));
                    });
                }
            })
            .catch((error: AxiosError) => {
                // if timeout with sitecore. Respond fallBackData (mockdata eerste beste)
                if (!error.code || error.code === "ECONNABORTED") {
                    dispatch(getFaqSuccess(getFallbackFaqData(currentLanguage) as ISiteCorePageResponse[]));
                    return;
                }
                throw error;
            })
            .finally(() => {
                dispatch(isFetching(false));
            });
    };
}
