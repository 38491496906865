import React from "react";

import "./TextBlock.scss";

export interface ITextBlockProps {
    Title: string;
    Content: string;
    isFetching?: boolean;
}

export default class TextBlock extends React.Component<ITextBlockProps, {}> {

    public render() {
        const { Title, Content } = this.props;
        return (
            <div className="textblock">
                <h2>{Title}</h2>
                <div className="blockcontent" dangerouslySetInnerHTML={{ __html: Content }}></div>
            </div>
        );
    }
}
