import { IDatasetDimension } from "../../actions/CCBDataset/CCBDatasetActions";
import { AbstractStepDefinition } from "./AbstractStepDefinition";

export class DimensionsWithoutCodeListsStepDefinition extends AbstractStepDefinition {
    public dimension: IDatasetDimension;

    constructor(title: string, index: number, dimension: IDatasetDimension) {
        super(title, index);
        this.dimension = dimension;
    }
}