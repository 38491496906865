import {ISiteCoreResponse} from "../actions/SiteCore/SiteCoreActions";

const siteCoreResponses: ISiteCoreResponse[] = [
    {
        CbsType: "3",
        Type: "Page",
        Permalink: "https://www-acc.cbs.nl/oData/v1/Pages('0480672a-6570-4b10-966e-2ef88cbda2e4-nl-nl')",
        Snippet: "<p>Welkom bij de b&egrave;ta-versie van het odata 4 dataportaal. Het CBS biedt haar open data services aan via dit portaal. Het gaat onder meer om datasets uit de databank StatLine.&nbsp;Iedere dataset heeft een korte beschrijving van zijn inhoud en structuur. Ook is er een link naar de webservice en een preview. De datasets zijn geordend per catalogus.</p>\n<p>In de b&egrave;ta-versie van het dataportaal is voorlopig nog een beperkt deel van de CBS datasets beschikbaar. </p>",
        ImageAlt: "",
        Image: "https://cdn.cbs.nl/images/6878667a68357679735864436b7152432f4c763338673d3d/900x450.jpg",
        UnderEmbargo: false,
        PublishTimeUnknown: false,
        RegionalClassification: undefined,
        ReleaseTime: "2019-08-15T13:43:16+02:00",
        Language: "nl-NL",
        SortOrder: 150,
        Url: "https://www-acc.cbs.nl/nl-nl/statline/dataportaal/homepage/tekstblokken/introductie",
        Title: "Introductie",
        Themes: [],
        UniqueId: "0480672a-6570-4b10-966e-2ef88cbda2e4-nl-nl"
    },
    {
        CbsType: "3",
        Type: "Page",
        Permalink: "https://www-acc.cbs.nl/oData/v1/Pages('9b0c367e-733f-4cc2-9e52-de9cd4dc7711-nl-nl')",
        Snippet: "<p>Open data zijn overheidsdata die elektronisch beschikbaar worden gesteld. Ze zijn vooral interessant voor data-analisten en ontwikkelaars. Zij kunnen hiermee geautomatiseerd data inlezen in een software tool of diensten ontwikkelen voor apps en websites.</p>\n<p>\nHet gebruik van open data van het CBS is gratis en vrij van rechten. De services voor open data (API's) maken gebruik van open standaarden, het zogeheten odata 4 protocol.</p>",
        ImageAlt: "",
        Image: "https://cdn.cbs.nl/images/6878667a68357679735864436b7152432f4c763338673d3d/900x450.jpg",
        UnderEmbargo: false,
        PublishTimeUnknown: false,
        RegionalClassification: undefined,
        ReleaseTime: "2019-08-19T10:29:14+02:00",
        Language: "nl-NL",
        SortOrder: 300,
        Url: "https://www-acc.cbs.nl/nl-nl/statline/dataportaal/homepage/tekstblokken/wat-zijn-open-data-",
        Title: "Wat zijn open data?",
        Themes: [],
        UniqueId: "9b0c367e-733f-4cc2-9e52-de9cd4dc7711-nl-nl"
    },
    {
        CbsType: "3",
        Type: "Page",
        Permalink: "https://www-acc.cbs.nl/oData/v1/Pages('b16980ad-e31a-4399-813a-c7291ceda266-nl-nl')",
        Snippet: "De tabellen uit de databank StatLine van het CBS zijn volledig beschikbaar via de services voor open data. Daarnaast zijn de datasets uit StatLine versies voor andere overheden (VWS, Politie, etc.) beschikbaar via dit dataportaal. Tot slot zijn hier ook datatsets te vinden die niet in StatLine voorkomen, maar die het CBS&nbsp;in opdracht van derden samenstelt, de aanvullende statistische diensten. Aan de catalogus is te zien wat de bron is van een dataset.",
        ImageAlt: "",
        Image: "https://cdn.cbs.nl/images/6878667a68357679735864436b7152432f4c763338673d3d/900x450.jpg",
        UnderEmbargo: false,
        PublishTimeUnknown: false,
        RegionalClassification: undefined,
        ReleaseTime: "2019-08-15T14:25:20+02:00",
        Language: "nl-NL",
        SortOrder: 400,
        Url: "https://www-acc.cbs.nl/nl-nl/statline/dataportaal/homepage/tekstblokken/welke-data-zijn-er-",
        Title: "Welke data zijn er?",
        Themes: [],
        UniqueId: "b16980ad-e31a-4399-813a-c7291ceda266-nl-nl"
    },
    {
        CbsType: "3",
        Type: "Page",
        Permalink: "https://www.cbs.nl/oData/v1/Pages('33b9ea18-72d9-4f2b-963d-a45de9e90f27-nl-nl')",
        Snippet: "<p>CBS veröffentlicht die meisten Zahlen in Tabellen. Auf diese Tabellen kann über StatLine auf der CBS-Website zugegriffen werden. Um diese Zahlen besser verfügbar zu machen, werden Tabellen auch als offene Daten veröffentlicht. CBS veröffentlicht offene Daten ab Juli 2014.</p><p>Seit Anfang 2018 bereitet CBS eine neue Version von Open Data vor. Dieses Datenportal ist der Zugang zu dieser neuen Version von offenen Daten</p>",
        ImageAlt: "",
        Image: "https://cdn.cbs.nl/images/34475031533542435141777748646e334758625455413d3d/1200x600.jpg",
        UnderEmbargo: false,
        PublishTimeUnknown: false,
        RegionalClassification: "",
        ReleaseTime: "2019-02-18T11:03:26+01:00",
        Language: "de-DE",
        SortOrder: 100,
        Url: "https://www.cbs.nl/nl-nl/statline/cbs/homepage/nederland-in-cijfers",
        Title: "Einleitung",
        Themes: [],
        UniqueId: "33b9ea18-72d9-4f2b-963d-a45de9e90f27-nl-nl"
    },
    {
        CbsType: "3",
        Type: "Page",
        Permalink: "https://www.cbs.nl/oData/v1/Pages('1397ff4b-e0ff-4f77-8229-ce31f1be6789-nl-nl')",
        Snippet: '<p>Open Data sind (Regierungs-) Daten, die auf eindeutige Weise elektronisch verfügbar gemacht werden, sodass Programmierer sie direkt für alle Arten von Anwendungen verwenden können. Viele neue Arten von Diensten und Apps können aus (Kombinationen von) diesen Datentypen entwickelt werden. Dies erfolgt häufig in Form von Apps für Smartphones und Tablets, es sind jedoch auch Anwendungen für die Verwendung am PC oder auf anderen Geräten möglich.</p>',
        ImageAlt: "",
        Image: "https://cdn.cbs.nl/images/34475031533542435141777748646e334758625455413d3d/1200x600.jpg",
        UnderEmbargo: false,
        PublishTimeUnknown: false,
        RegionalClassification: "",
        ReleaseTime: "2019-02-04T12:03:34+01:00",
        Language: "de-DE",
        SortOrder: 200,
        Url: "https://www.cbs.nl/nl-nl/statline/cbs/homepage/statline",
        Title: "Was sind offene Daten?",
        Themes: [],
        UniqueId: "1397ff4b-e0ff-4f77-8229-ce31f1be6789-nl-nl"
    },
    {
        CbsType: "3",
        Type: "Page",
        Permalink: "https://www.cbs.nl/oData/v1/Pages('eb6d09e3-0fd1-48cd-be55-089bfab95bcb-nl-nl')",
        Snippet: '<p>In diesem Service finden Sie eine Übersicht der verfügbaren Kataloge, einschließlich einiger Eigenschaften des Katalogs. Basierend auf der Kennung, oder Sie können den Link zum Katalog erstellen.</p>',
        ImageAlt: "Spreeuwen, spectaculair zwermend boven de stad Utrecht",
        Image: "https://cdn.cbs.nl/images/63304262314c3057537057397571766d7969716747773d3d/1200x600.jpg",
        UnderEmbargo: false,
        PublishTimeUnknown: false,
        RegionalClassification: "",
        ReleaseTime: "2019-02-04T11:55:29+01:00",
        Language: "de-DE",
        SortOrder: 300,
        Url: "https://www.cbs.nl/nl-nl/statline/cbs/homepage/zoek-op-trefwoord",
        Title: "Webdienste",
        Themes: [],
        UniqueId: "eb6d09e3-0fd1-48cd-be55-089bfab95bcb-nl-nl"
    },
    {
        CbsType: "3",
        Type: "Page",
        Permalink: "https://www-acc.cbs.nl/oData/v1/Pages('0480672a-6570-4b10-966e-2ef88cbda2e4-en-gb')",
        Snippet: "<p>Welcome to the beta version of the odata 4 data portal. CBS presents its open data services through this portal. It concerns among others the datasets from the database StatLine. Every dataset has a short description of its contents and structure. There is also a link to the web service and a preview. The datasets are ordered by catalog.</p>\n<p>This beta version of the data portal offers for the time being a limited number of the CBS datasets.</p>",
        ImageAlt: "",
        Image: "https://cdn.cbs.nl/images/6878667a68357679735864436b7152432f4c763338673d3d/900x450.jpg",
        UnderEmbargo: false,
        PublishTimeUnknown: false,
        RegionalClassification: undefined,
        ReleaseTime: "2019-08-15T13:52:36+02:00",
        Language: "en-GB",
        SortOrder: 150,
        Url: "https://www-acc.cbs.nl/en-gb/statline/dataportaal/homepage/tekstblokken/introduction",
        Title: "Introduction",
        Themes: [],
        UniqueId: "0480672a-6570-4b10-966e-2ef88cbda2e4-en-gb"
    },
    {
        CbsType: "3",
        Type: "Page",
        Permalink: "https://www-acc.cbs.nl/oData/v1/Pages('9b0c367e-733f-4cc2-9e52-de9cd4dc7711-en-gb')",
        Snippet: "<p>Open data are government data made available electronically. Open data are particularly interesting for data analysts and developers. They can automate loading the data into a software tool or retrieve them for developing services for apps and websites. </p>\n<p>The use of CBS open data is free of charge and free of copyright. The open data services (API's) use open standards, the so-called odata 4 protocol.</p>",
        ImageAlt: "",
        Image: "https://cdn.cbs.nl/images/6878667a68357679735864436b7152432f4c763338673d3d/900x450.jpg",
        UnderEmbargo: false,
        PublishTimeUnknown: false,
        RegionalClassification: undefined,
        ReleaseTime: "2019-08-19T10:29:40+02:00",
        Language: "en-GB",
        SortOrder: 300,
        Url: "https://www-acc.cbs.nl/en-gb/statline/dataportaal/homepage/tekstblokken/what-are-open-data-",
        Title: "What are open data?",
        Themes: [],
        UniqueId: "9b0c367e-733f-4cc2-9e52-de9cd4dc7711-en-gb"
    },
    {
        CbsType: "3",
        Type: "Page",
        Permalink: "https://www-acc.cbs.nl/oData/v1/Pages('b16980ad-e31a-4399-813a-c7291ceda266-en-gb')",
        Snippet: "The tables from the CBS database StatLine are all available through the services for open data. The data portal also offers access to datasets from StatLine versions for other government organisations (Ministry of Public Health, Police, etc.). Finally datasets can be found that are not part of StatLine, but that the CBS has compiled at the request of a third party, the customised statistical services. The catalog shows what the source is of a dataset.",
        ImageAlt: "",
        Image: "https://cdn.cbs.nl/images/6878667a68357679735864436b7152432f4c763338673d3d/900x450.jpg",
        UnderEmbargo: false,
        PublishTimeUnknown: false,
        RegionalClassification: undefined,
        ReleaseTime: "2019-08-20T14:17:11+02:00",
        Language: "en-GB",
        SortOrder: 400,
        Url: "https://www-acc.cbs.nl/en-gb/statline/dataportaal/homepage/tekstblokken/which-data-are-here-",
        Title: "Which data are here?",
        Themes: [],
        UniqueId: "b16980ad-e31a-4399-813a-c7291ceda266-en-gb"
    }
];

export const siteCoreResponsesGermanHomepage: ISiteCoreResponse[] = siteCoreResponses.filter((e: ISiteCoreResponse) => e.Language === "de-DE");

export const siteCoreResponsesEnglishHomepage: ISiteCoreResponse[] = siteCoreResponses.filter((e: ISiteCoreResponse) => e.Language === "en-GB");

export const siteCoreResponsesDutchHomepage: ISiteCoreResponse[] = siteCoreResponses.filter((e: ISiteCoreResponse) => e.Language === "nl-NL");

export default siteCoreResponses;
