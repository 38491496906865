import React from "react";

export interface IStepProps {
    currentStep: number;
}

export default class Step<T extends IStepProps> extends React.Component<T> {

    public render() {
        return (
            <p>{`${this.props.currentStep}`}</p>
        );
    }
}
