import React from "react";
import Collapsible from "react-collapsible";
import 'font-awesome/css/font-awesome.min.css';

import "./Accordeon.scss";

export interface IAccordeonProps {
    Header: string;
    Content: JSX.Element;
    Index: number;
    ShouldBeOpen: boolean;
    ShowTotalCells: boolean;
}

class Accordeon extends React.Component<React.PropsWithChildren<IAccordeonProps>, {}> {
    public render() {
        const handleCollapsibleTrigger = (accordionPosition: any, e: any) => {
            const { refs } = e;
            const currentTargetCollapsible: any = Object.values(refs).find((ref: any) => ref.props.accordionPosition === accordionPosition);
            const otherOpenCollapsible: any = Object.values(refs).find((ref: any) => ref.props.accordionPosition !== accordionPosition && ref.state.isClosed === false);
            if (currentTargetCollapsible.state.isClosed) {
                if (otherOpenCollapsible) {
                    otherOpenCollapsible.closeCollapsible();
                }

                currentTargetCollapsible.openCollapsible();
            } else {
                currentTargetCollapsible.closeCollapsible();
            }
        };

        const collapsibleButton = () => {
            return (
                
                <React.Fragment>
                    <span className="status-icon"><i className="fa fa-plus"></i></span>
                    <button className="collapsibleButton">{Header}</button>
                    {ShowTotalCells && <span className="cell-info">Aantal cellen</span>}                    
                </React.Fragment>
            );
        };

        const { Header, Content, Index, ShouldBeOpen, ShowTotalCells } = this.props;
        return (
            <Collapsible open={ShouldBeOpen} ref={Index.toString()} accordionPosition={Index} key={Index} trigger={collapsibleButton()} handleTriggerClick={(accordionPosition) => handleCollapsibleTrigger(accordionPosition, this)} triggerTagName="div">
                {Content}
            </Collapsible>
        );
    }
}

export default (Accordeon);