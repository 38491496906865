import React, {Component} from "react";
import {IntlProvider} from "react-intl";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import messages_en from "../../translations/en";
import messages_nl from "../../translations/nl";
import messages_de from "../../translations/de";
import {ILocalizationState} from "../../types/ILocalizationState";
import * as actions from "../../actions/Localization/LocalizationActions";
import AppContainer from "../App/AppContainer";

export class RootContainer extends Component <IRootProps, { }> {

    private messages: any = {
        de: messages_de,
        en: messages_en,
        nl: messages_nl
    };

    public render() {
        const { localization } = this.props;
        return (
            <IntlProvider locale={localization.currentLanguage} messages={this.messages[localization.currentLanguage]} >
                <AppContainer />
            </IntlProvider>
        );
    }
}

export interface IRootProps {
    localization: ILocalizationState;
    setCurrentLanguage: (language: string) => void;
}

export const mapStateToProps = (state: any) => {
    return {
        localization: state.Localization,
    };
};

export function mapDispatchToProps(dispatch: Dispatch<actions.LocalizationActions>) {
    return {
        setCurrentLanguage: (language: string) => dispatch(actions.setCurrentLanguage(language)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);
