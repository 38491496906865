import axios from "axios";
import { Dispatch } from "redux";
import { IStoreState } from "../../types/IStoreState";
import * as constants from "./SettingsConstants";

export interface ISettingsResponse {
    hostSiteCoreODataApiUrl: string;
    hostCcbODataApiUrl: string;
    hostThemesTreeUrl: string;
    canonicalNlRootUrl: string;
    zipLocation: string;
}

// ACTION DEFINITIONS
export interface IActionGetSettings {
    type: constants.GET_SETTINGS;
    settings: ISettingsResponse;
}

export interface IActionSetFetchingSettings {
    type: constants.SET_FETCHING_SETTINGS;
    isFetching: boolean;
}

// ACTION CREATORS
export const getSettingsSuccess = (settings: ISettingsResponse): IActionGetSettings => {
    return {
        settings,
        type: constants.GET_SETTINGS
    };
};

const isFetchingSettings = (isFetching: boolean): IActionSetFetchingSettings => {
    return {
        isFetching,
        type: constants.SET_FETCHING_SETTINGS
    };
};

// Union Action Types
export type SettingsActions = IActionGetSettings | IActionSetFetchingSettings;

export function getSettings() {
    return (dispatch: Dispatch, getState: () => IStoreState) : Promise<void> => {
        dispatch(isFetchingSettings(true));
        return axios.get(`/Settings`)
            .then(({ data }: any) => {
                dispatch(getSettingsSuccess(data));
            })
            .finally(() => {
                dispatch(isFetchingSettings(false));
            });
    };
};