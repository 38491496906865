import axios, { AxiosRequestConfig, Canceler } from "axios";
import { IntlFormatters } from "react-intl";
import {Dispatch} from "redux";
import {IStoreState} from "../../types/IStoreState";
import { IFilter } from "../Filters/FilterActions";
import * as constants from "./CCBDatasetConstants";

let ObservationFetchCancelToken: Canceler | null = null;
let CountObservationFetchCancelToken: Canceler | null = null;

// ACTION DEFINITION
export interface IActionGetMetaData {
    type: constants.GET_METADATA;
    metadata: IMetaDataResponse;
}

export interface IActionGetDatasetProperties {
    type: constants.GET_DATASET_PROPERTIES;
    datasetProperties: IDatasetProperties;
}

export interface IActionGetDatasetDimensions {
    type: constants.GET_DATASET_DIMENSIONS;
    datasetDimensions: IDatasetDimension[];
}

export interface IActionGetDatasetDimensionTreeModel {
    type: constants.GET_DATASET_DIMENSIONS_TREE_MODEL;
    dimensionTreeModel: IDatasetDimensionTreeModel;
}

export interface IActionGetDatasetMeasureTreeModel {
    type: constants.GET_DATASET_MEASURES_TREE_MODEL;
    measureTreeModel: IDatasetMeasureTreeModel;
}

export interface IActionGetCatalogs {
    type: constants.GET_CATALOGS;
    catalogs: ICatalogResponse[];
}

export interface IActionGetCatalogCount {
    type: constants.GET_CATALOGCOUNT;
    catalog: ICatalogResponse;
}

export interface IActionSetFetchingCurrentDataset {
    type: constants.SET_FETCHING_CURRENTDATASET;
    isFetching: boolean;
}

export interface IActionSetSearch {
    type: constants.SET_SEARCH;
    searchParams: string;
}

export interface IActionGetDatasets {
    type: constants.GET_DATASETS;
    datasets: IMetaDataResponse[];
}

export interface IActionGetDatasetObservations {
    type: constants.GET_DATASET_OBSERVATIONS;
    observations: IDatasetObservationsResponse;
    observationsUrl: string;
    observationsCount: number;
    hasSucceed: boolean;
}

export interface IActionCreateFilteredObservationsUrl {
    type: constants.CREATE_FILTERED_OBSERVATIONS_URL,
    observationsUrl: string;
}

export interface IActionSetDatasetObservationsFail {
    type: constants.SET_DATASET_OBSERVATIONS_FAIL_MESSAGE;
    message: IAlertMessage;
}

export interface IActionCleanUpDatasetObservationsErrorMessages {
    type: constants.CLEANUP_DATASET_OBSERVATIONS_ERROR_MESSAGES;
}

export interface IActionCleanUpCurrentDataset {
    type: constants.CLEAN_UP_CURRENTDATASET;
}

export interface IObservation {
    Id: number;
    Measure: string;
    ValueAttribute: string;
    Value: number;
}

export interface IDatasetObservationsResponse extends Array<string[]> { }

export enum AlertEnum {
    Info = "info",
    Question = "question",
    Success = "success",
    Warning = "warning",
    Error = "error"
}

export interface IAlertMessage {
    Type: AlertEnum;
    Title: string;
    Description: string;
}

export interface IMetaDataResponse {
    Description?: string;
    Identifier?: string;
    Language?: string;
    Title?: string;
    Modified?: string;
    Catalog?: string;
    Version?: string;
    VersionNotes?: string;
    VersionReason?: string;
    Status?: string;
    ObservationsModified?: string;
    ObservationCount?: string;
    DatasetType?: string;
    Distributions?: IDistribution[];
}

export interface IDistribution {
    Format: string;
    MediaType: string;
    DownloadUrl: string;
    AccessUrl: string;
    Language: string;
}

export interface ILink {
    Title?: string;
    Url?: string;
}

export interface IDatasetProperties {
    Identifier: string;
    Title: string;
    Description: string;
    Modified: string; // TODO: Type="Edm.DateTimeOffset"
    Language: string;
    TemporalCoverage: string;
    Authority: string;
    Catalog: string;
    Publisher?: string;
    Version?: string;
    VersionNotes?: string;
    VersionReason?: string;
    Frequency?: string;
    Provenance?: ILink[]; // Type="Collection(Default.Link)
    Status?: string;
    ObservationCount: number;
    ObservationsModified: string; // TODO: Type="Edm.DateTimeOffset"
    DatasetType?: string;
    RelatedSources?: ILink[]; // Type="Collection(Default.Link)
    GraphTypes?: string;
    License?: string;
    Source?: string;
    Summary?: string;
    LongDescription?: string;
    Distributions: IDistribution[];
}

export interface IDatasetDimension {
    Identifier: string;
    Title?: string;
    Description?: string;
    Kind?: string;
    MapYear?: string;
    ReleasePolicy?: string;
    hasCodes: boolean;
    hasGroups: boolean;
}

export interface IDatasetMeasureCode {
    Identifier: string;
    Index: number;
    Title?: string;
    Description?: string;
    MeasureGroupId?: string;
    DataType: string;
    Unit: string;
    Decimals: number;
    PresentationType?: string;
}

export interface IDatasetMeasureGroup {
    Id: string;
    Index: number;
    Title?: string;
    Description?: string;
    ParentId?: string;
}

export interface IDatasetMeasureTreeModel {
    MeasureIdentifier: string;
    MeasureTree: any;
}

export interface IDatasetDimensionGroup {
    Id: string;
    Index: number;
    Title?: string;
    Description?: string;
    ParentId?: string;
}

export interface IDatasetDimensionGroups {
    DimensionGroupIdentifier: string;
    DimensionGroups: IDatasetDimensionGroup[];
}

export interface IDatasetDimensionTreeModel {
    DimensionIdentifier: string;
    DimensionTree: any;
}

export interface IDatasetDimensionCodes {
    DimensionIdentifier: string;
    DimensionCodes: IDatasetDimensionCode[];
}

export interface IDatasetDimensionCode {
    Identifier: string;
    Index: number;
    Title?: string;
    Description?: string;
    DimensionGroupId?: string;
}

export interface ICatalogResponse {
    Identifier: string;
    Index: number;
    Title: string;
    Description: string;
    Publisher: string;
    Language: string;
    License: string;
    Homepage: string;
    Authority: string;
    DatasetCount?: number;
}

// Union Action Types
export type CCBDatasetActions
    = IActionGetMetaData
    | IActionGetDatasetProperties
    | IActionGetDatasetDimensions
    | IActionGetDatasetDimensionTreeModel
    | IActionGetDatasetMeasureTreeModel
    | IActionSetFetchingCurrentDataset
    | IActionSetSearch
    | IActionCreateFilteredObservationsUrl
    | IActionGetDatasets
    | IActionGetCatalogs
    | IActionGetCatalogCount
    | IActionGetDatasetObservations
    | IActionCleanUpCurrentDataset
    | IActionCleanUpDatasetObservationsErrorMessages
    | IActionSetDatasetObservationsFail;

// Action Creators
const getMetaDataSuccess = (metadata: IMetaDataResponse): IActionGetMetaData => {
    return {
        metadata,
        type: constants.GET_METADATA
    };
};

const getDatasetPropertiesSuccess = (datasetProperties: IDatasetProperties): IActionGetDatasetProperties => {
    return {
        datasetProperties,
        type: constants.GET_DATASET_PROPERTIES
    };
};

const getDimensionTreeModelSuccess = (dimensionTreeModel: IDatasetDimensionTreeModel): IActionGetDatasetDimensionTreeModel => {
    return {
        dimensionTreeModel,
        type: constants.GET_DATASET_DIMENSIONS_TREE_MODEL
    };
};

const getMeasureTreeModelSuccess = (measureTreeModel: IDatasetMeasureTreeModel): IActionGetDatasetMeasureTreeModel => {
    return {
        measureTreeModel,
        type: constants.GET_DATASET_MEASURES_TREE_MODEL
    };
};

const getDatasetDimensionsSuccess = (datasetDimensions: IDatasetDimension[], entitySetNames: string[]): IActionGetDatasetDimensions => {
    datasetDimensions.forEach(dimension => {
        dimension.hasCodes = entitySetNames.includes(`${dimension.Identifier}Codes`);
        dimension.hasGroups = entitySetNames.includes(`${dimension.Identifier}Groups`);
    })
    return {
        datasetDimensions,
        type: constants.GET_DATASET_DIMENSIONS
    };
};

const getCatalogsSuccess = (catalogs: ICatalogResponse[]): IActionGetCatalogs => {
    return {
        catalogs,
        type: constants.GET_CATALOGS
    };
};

const getCatalogCountSuccess = (catalog: ICatalogResponse): IActionGetCatalogCount => {
    return {
        catalog,
        type: constants.GET_CATALOGCOUNT
    };
};

const isFetching = (isFetching: boolean): IActionSetFetchingCurrentDataset => {
    return {
        isFetching,
        type: constants.SET_FETCHING_CURRENTDATASET
    };
};

export const setSearch = (searchParams: string): IActionSetSearch => {
    return {
        searchParams,
        type: constants.SET_SEARCH
    };
};

const getDatasetsSuccess = (datasets: IMetaDataResponse[]): IActionGetDatasets => {
    return {
        datasets,
        type: constants.GET_DATASETS
    };
};

const getDatasetObservationsSuccess = (observations: IDatasetObservationsResponse, observationsUrl: string, observationsCount: number, hasSucceed: boolean): IActionGetDatasetObservations => {
    return {
        observationsUrl,
        observations,
        observationsCount,
        type: constants.GET_DATASET_OBSERVATIONS,
        hasSucceed
    };
};

const handleDatasetObservationsFailed = (observations: IDatasetObservationsResponse, observationsUrl: string, observationsCount: number, hasSucceed: boolean): IActionGetDatasetObservations => {
    return {
        observationsUrl,
        observations,
        observationsCount,
        type: constants.GET_DATASET_OBSERVATIONS,
        hasSucceed
    };
};

const setDatasetObservationsFail = (message: IAlertMessage): IActionSetDatasetObservationsFail => {
    return {
        message,
        type: constants.SET_DATASET_OBSERVATIONS_FAIL_MESSAGE
    };
};

const createFilteredObservationsUrl = (observationsUrl: string): IActionCreateFilteredObservationsUrl => {
    return {
        observationsUrl,
        type: constants.CREATE_FILTERED_OBSERVATIONS_URL
    }
}

const cleanUpDatasetObservationsErrorMessages = (): IActionCleanUpDatasetObservationsErrorMessages => {
    return {
        type: constants.CLEANUP_DATASET_OBSERVATIONS_ERROR_MESSAGES,
    };
};

export const cleanUpCurrentDataset = (): IActionCleanUpCurrentDataset => {
    return {
        type: constants.CLEAN_UP_CURRENTDATASET,
    };
};

export function getListOfDatasets(path: string = "Datasets"): any {
    return (dispatch: Dispatch, getState: () => IStoreState) => {
        const { hostCcbODataApiUrl } = getState().Settings;
        dispatch(isFetching(true));
        return axios.get(`${hostCcbODataApiUrl}${path}`)
            .then(({ data }: any) => {
                dispatch(getDatasetsSuccess(data.value));
            })
            .finally(() => {
                dispatch(isFetching(false));
            });
    };
}

export function getMetaData(id: string): any {
    return (dispatch: Dispatch, getState: () => IStoreState) => {
        const { hostCcbODataApiUrl } = getState().Settings;
        dispatch(isFetching(true));
        return axios.get(`${hostCcbODataApiUrl}Datasets/?$filter=Identifier eq '${id}'`)
            .then(({ data }: any) => {
                dispatch(getMetaDataSuccess(data.value[0]));
            })
            .finally(() => {
                dispatch(isFetching(false));
            });
    };
}

export function getDatasetProperties(catalog: string, id: string): any {
    return (dispatch: Dispatch, getState: () => IStoreState) => {
        const { hostCcbODataApiUrl } = getState().Settings;
        dispatch(isFetching(true));
        return axios.get(`${hostCcbODataApiUrl}${catalog}/${id}/Properties`)
            .then(({ data }: any) => {
                dispatch(getDatasetPropertiesSuccess(data));
            })
            .finally(() => {
                dispatch(isFetching(false));
            });
    };
}

export function getDatasetMeasureTreeModal(catalog: string, id: string): any {
    const getMeasureGroups = (baseUrl: string) => new Promise((resolve, reject) => {
        return axios.get(`${baseUrl}${catalog}/${id}/MeasureGroups?$orderby=Index`).then(resolve).catch(resolve);
    });

    const getMeasureCodes = (baseUrl: string) => new Promise((resolve, reject) => {
        return axios.get(`${baseUrl}${catalog}/${id}/MeasureCodes?$orderby=Index`).then(resolve).catch(resolve);
    });

    const createMeasureTreeModel = (measureGroups: IDatasetMeasureGroup[], measureCodes: IDatasetMeasureCode[]) => {
        const tree: any[] = [];
        const mappedArr: any = {};

        let arrElem: IDatasetMeasureGroup;
        let mappedElem;

        if (measureGroups === undefined) {
            return measureCodes;
        }

        // First map the nodes of the array to an object -> create a hash table.
        for (let i = 0, len = measureGroups.length; i < len; i++) {
            arrElem = measureGroups[i];
            mappedArr[arrElem.Id] = arrElem;
            mappedArr[arrElem.Id].children = measureCodes.filter((e) => e.MeasureGroupId === arrElem.Id); // eslint-disable-line
        }

        for (const mappedArrayId in mappedArr) {
            if (mappedArr.hasOwnProperty(mappedArrayId)) {
                mappedElem = mappedArr[mappedArrayId];
                // If the element is not at the root level, add it to its parent array of children.
                if (mappedElem.ParentId) {
                    mappedArr[mappedElem.ParentId].children.push(mappedElem);
                } else {
                    tree.push(mappedElem);
                }
            }
        }

        // if contains item without parent
        const itemsWithoutParent = measureCodes.filter((e) => !e.MeasureGroupId);
        if (itemsWithoutParent && itemsWithoutParent.length > 0) {
            itemsWithoutParent.forEach((item) => {
                tree.push(item);
            });
        }

        // sorting tree by index asc
        tree.sort((a, b) =>
            a.Index < b.Index ? -1 : a.Index > b.Index ? 1 : 0
        );

        return tree;
    };

    return (dispatch: Dispatch, getState: () => IStoreState) => {
        const { hostCcbODataApiUrl } = getState().Settings;
        dispatch(isFetching(true));

        return axios.get(`${hostCcbODataApiUrl}${catalog}/${id}/`)
            .then((entitySets: any) => {
                const entitySetNames: string[] = entitySets.data.value.map((entitySet: any) => entitySet.name);
                const hasMeasureGroups = entitySetNames.includes(`MeasureGroups`);

                // hierarchy tree
                const datasetMeasureTreeModel: IDatasetMeasureTreeModel = {
                    MeasureIdentifier: constants.MEASURE_IDENTIFIER,
                    MeasureTree: []
                };

                if (!hasMeasureGroups) {
                    return getMeasureCodes(hostCcbODataApiUrl)
                        .then((codes: any) => {
                            if (codes.data) {
                                datasetMeasureTreeModel.MeasureTree = codes.data.value;
                            }
                            dispatch(getMeasureTreeModelSuccess(datasetMeasureTreeModel));
                        });
                } else {
                    return axios.all([getMeasureGroups(hostCcbODataApiUrl), getMeasureCodes(hostCcbODataApiUrl)])
                        .then((data: any) => {
                            const groups = data[0];
                            const codes = data[1];

                            if (groups.data && codes.data) {
                                // Build Tree
                                datasetMeasureTreeModel.MeasureTree = createMeasureTreeModel(groups.data.value, codes.data.value);
                            } else if (codes.data && groups.data === undefined) {
                                // non hierarchy flat list
                                datasetMeasureTreeModel.MeasureTree = codes.data.value;
                            }
                            dispatch(getMeasureTreeModelSuccess(datasetMeasureTreeModel));
                        })
                }
            })
            .finally(() => {
                dispatch(isFetching(false));
            });
    };
}

export function getDatasetDimensionsTreeModal(catalog: string, id: string): any {
    const createDimensionTreeModel = (dimensionGroups: IDatasetDimensionGroup[], dimensionCodes: IDatasetDimensionCode[]) => {
        const tree: any[] = [];
        const mappedArr: any = {};

        let arrElem: IDatasetDimensionGroup;
        let mappedElem;

        // First map the nodes of the array to an object -> create a hash table.
        for (let i = 0, len = dimensionGroups.length; i < len; i++) {
            arrElem = dimensionGroups[i];
            mappedArr[arrElem.Id] = arrElem;
            mappedArr[arrElem.Id].children = dimensionCodes.filter((e) => e.DimensionGroupId === arrElem.Id); // eslint-disable-line
        }

        for (const mappedArrayId in mappedArr) {
            if (mappedArr.hasOwnProperty(mappedArrayId)) {
                mappedElem = mappedArr[mappedArrayId];
                // If the element is not at the root level, add it to its parent array of children.
                if (mappedElem.ParentId) {
                    mappedArr[mappedElem.ParentId].children.push(mappedElem);
                } else {
                    tree.push(mappedElem);
                }
            }
        }

        // if contains item without parent
        const itemsWithoutParent = dimensionCodes.filter((e) => e.DimensionGroupId === undefined);
        if (itemsWithoutParent && itemsWithoutParent.length > 0) {
            itemsWithoutParent.forEach((item) => {
                tree.push(item);
            });
        }

        return tree;
    };

    return (dispatch: Dispatch, getState: () => IStoreState) => {
        const { hostCcbODataApiUrl } = getState().Settings;
        dispatch(isFetching(true));

        return axios.all([axios.get(`${hostCcbODataApiUrl}${catalog}/${id}/Dimensions`), axios.get(`${hostCcbODataApiUrl}${catalog}/${id}/`)])
            .then((responses: any[]) => {
                const [dimensions, entitySets] = responses;
                const entitySetNames: string[] = entitySets.data.value.map((entitySet: any) => entitySet.name);
                const dimensionList: IDatasetDimension[] = dimensions.data.value

                dispatch(getDatasetDimensionsSuccess(dimensionList, entitySetNames));

                dimensionList.forEach((dimension: IDatasetDimension) => {
                    const getDimensionGroups = (baseUrl: string) => new Promise((resolve, reject) => {
                        return axios.get(`${baseUrl}${catalog}/${id}/${dimension.Identifier}Groups?$orderby=Index`).then(resolve).catch(resolve);
                    });

                    const getDimensionCodes = (baseUrl: string) => new Promise((resolve, reject) => {
                        return axios.get(`${baseUrl}${catalog}/${id}/${dimension.Identifier}Codes?$orderby=Index`).then(resolve).catch(resolve);
                    });

                    const datasetDimensionTreeModel: IDatasetDimensionTreeModel = {
                        DimensionIdentifier: dimension.Identifier,
                        DimensionTree: []
                    };

                    if (!dimension.hasCodes) {
                        dispatch(getDimensionTreeModelSuccess(datasetDimensionTreeModel));
                        return;
                    } else if (!dimension.hasGroups) {
                        return getDimensionCodes(hostCcbODataApiUrl)
                            .then((codes: any) => {
                                if (codes.data) {
                                    datasetDimensionTreeModel.DimensionTree = codes.data.value;
                                }
                                dispatch(getDimensionTreeModelSuccess(datasetDimensionTreeModel));
                            });
                    } else {
                        return axios.all([getDimensionGroups(hostCcbODataApiUrl), getDimensionCodes(hostCcbODataApiUrl)])
                            .then((data: any) => {
                                const groups = data[0];
                                const codes = data[1];

                                if (groups.data && codes.data) {
                                    // Build Tree
                                    datasetDimensionTreeModel.DimensionTree = createDimensionTreeModel(groups.data.value, codes.data.value);
                                } else if (codes.data && groups.data === undefined) {
                                    // non hierarchy flat list
                                    datasetDimensionTreeModel.DimensionTree = codes.data.value;
                                }
                                dispatch(getDimensionTreeModelSuccess(datasetDimensionTreeModel));
                            });
                    }
                });
            })
            .finally(() => {
                dispatch(isFetching(false));
            });
    };
}

export const getCatalogs = (): any => {
    return (dispatch: Dispatch, getState: () => IStoreState) => {
        const { hostCcbODataApiUrl } = getState().Settings;
        dispatch(isFetching(true));
        return axios.get(`${hostCcbODataApiUrl}Catalogs?$orderby=Index`)
            .then(({ data }: any) => {
                dispatch(getCatalogsSuccess(data.value));
                return countNumberOfDatasetsForCatalog(hostCcbODataApiUrl, data.value, dispatch);
            })
            .finally(() => {
                dispatch(isFetching(false));
            });
    };
};

export function countNumberOfDatasetsForCatalog(baseUrl: string, catalogs: ICatalogResponse[], dispatch: Dispatch): any {
    catalogs.forEach((catalog, i) => {
        axios.get(`${baseUrl}${catalog.Identifier}/Datasets/$count`)
            .then(({ data } : any) => {
                const cat = { ...catalog, DatasetCount: data };
                dispatch(getCatalogCountSuccess(cat));
            });
    });
}

const isFilterEmpty = (filter?: IFilter): boolean => {
    return filter === undefined || filter.FilteredIdentifiers === undefined || filter.FilteredIdentifiers!.length < 1;
};

const getDefaultMeasureFilter = (): IFilter => {
    return {
        FilteredIdentifiers: [],
        Identifier: constants.MEASURE_IDENTIFIER
    };
};

const getDefaultDimensionFilter = (dimensionIdentifier: string): IFilter => {
    return {
        FilteredIdentifiers: [],
        Identifier: dimensionIdentifier
    };
};

const truncateFilteredIdentifiers = (filter: IFilter): IFilter => {
    switch (filter.Identifier) {
        case constants.MEASURE_IDENTIFIER:
            return {
                FilteredIdentifiers: filter.FilteredIdentifiers,
                Identifier: filter.Identifier
            };
        default:
            return {
                FilteredIdentifiers: filter.FilteredIdentifiers,
                Identifier: filter.Identifier
            };
    }
};

const complementFilters = (filters: Map<string, IFilter>, storeState: IStoreState): Map<string, IFilter> => {
    const datasetDimensions = storeState.CurrentDataset.datasetDimensions;
    if (datasetDimensions) {
        const dimensionIdentifiers = datasetDimensions.map((dimension) => dimension.Identifier);
        const complementedFilters = new Map<string, IFilter>();

        const measureFilter = filters.get(constants.MEASURE_IDENTIFIER);
        complementedFilters.set(constants.MEASURE_IDENTIFIER, (isFilterEmpty(measureFilter))
            ? getDefaultMeasureFilter()
            : truncateFilteredIdentifiers(measureFilter!)
        );

        dimensionIdentifiers.forEach((dimensionIdentifier) => {
            const dimensionFilter = filters.get(dimensionIdentifier);
            complementedFilters.set(dimensionIdentifier, (isFilterEmpty(dimensionFilter))
                ? getDefaultDimensionFilter(dimensionIdentifier)
                : truncateFilteredIdentifiers(dimensionFilter!)
            );
        });

        return complementedFilters;
    }
    return filters;
};

const convertToODataFilter = (filters: Map<string, IFilter>, dimensions: IDatasetDimension[]): string => {
    const operands: string[] = [];
    filters
        .forEach((filter) => {
            if ((filter.FilteredIdentifiers && filter.FilteredIdentifiers.length > 0)) {
                    var dimension = dimensions.find(d => d.Identifier === filter.Identifier);
                    if (dimension && !dimension.hasCodes) {
                        const items = filter.FilteredIdentifiers
                            .map((key) => `startswith(${filter.Identifier},'${key}')`)
                            .join(" or ");
                        operands.push(`(${items})`);
                    } else {
                        const items = filter.FilteredIdentifiers
                            .map((key) => `'${key}'`).join(", ");
                        operands.push(`(${filter.Identifier} in (${items}))`);
                    }
            }
        });

    operands.push("(ValueAttribute in ('None', 'Zero'))");
    
    return (operands.length > 0) ? `$filter=${operands.join(" and ")}` : "";
};

const convertCsvToArrayOfStringArray = (csvData: string, seperator: string): string[][] => {
    const previewData: string[] = csvData.split('\n');
    const previewRecords: string[][] = [];
    const regExp = new RegExp(`${seperator}+(?=(?:(?:[^"]*"){2})*[^"]*$)`, 'g');
    previewData.forEach((record) => {
        previewRecords.push(record.replace(`${seperator}${seperator}`, `${seperator} ${seperator}`)
            .split(regExp));
    });

    return previewRecords;
};

const createMessageForLongUrl = (intl?: IntlFormatters): any => {
    if (intl) {
        const message: IAlertMessage = {
            Type: AlertEnum.Info,
            Title: "",
            Description: intl.formatMessage(
                {
                    id: "dataset.preview.urltolong",
                    defaultMessage: "You have made a CSV download with many items (filters). Your browser may not be able to process it. See the FAQ for more info."
                })
        };

        return message;
    }
}

const createFilteredObservationsQuery = (filters: Map<string, IFilter>, store: IStoreState): string => {
        const { datasetDimensions } = store.CurrentDataset;
        const complementedFilters = complementFilters(filters, store);
        const filter = convertToODataFilter(complementedFilters, datasetDimensions!);
        const queryParameters = [filter]
            .filter((p) => p.length > 0)
            .join("&");
        const query = `${queryParameters}`;
        
        return query;
}

export const setFilteredObservationsQuery = (): any => {
    return (dispatch: Dispatch, getState: () => IStoreState) => {
        const filters = getState().Selection.Filters;
        var query = createFilteredObservationsQuery(filters, getState());
        const { hostCcbODataApiUrl } = getState().Settings;
        const { datasetProperties } = getState().CurrentDataset;

        const requestUrl = `${hostCcbODataApiUrl}${datasetProperties?.Catalog}/${datasetProperties?.Identifier}/Observations?${query}`;
        dispatch(createFilteredObservationsUrl(requestUrl));
    };
};

export const getDatasetObservations = (catalog: string, id: string, filters: Map<string, IFilter>, intl?: IntlFormatters): any => {
    return (dispatch: Dispatch, getState: () => IStoreState) => {

        if (ObservationFetchCancelToken != null) {
            ObservationFetchCancelToken();
            ObservationFetchCancelToken = null;
        }

        if (CountObservationFetchCancelToken != null) {
            CountObservationFetchCancelToken();
            CountObservationFetchCancelToken = null;
        }

        const { hostCcbODataApiUrl } = getState().Settings;
        const query = createFilteredObservationsQuery(filters, getState());
        const requestUrl = `${hostCcbODataApiUrl}${catalog}/${id}/Observations?${query}`;
        dispatch(createFilteredObservationsUrl(requestUrl));

        const requestUrlPreview = `${hostCcbODataApiUrl}${catalog}/${id}/Observations?${query}&$top=${constants.MAX_CELLS}`;
        const requestUrlForCount = `${hostCcbODataApiUrl}${catalog}/${id}/Observations/$count?${query}`; 

        const fetchObservationsRequestConfig: AxiosRequestConfig = {
            cancelToken: new axios.CancelToken((canceler: Canceler) => { ObservationFetchCancelToken = canceler }),
            headers: {
                Accept: 'text/csv'
            }
        };

        const fetchCountObservationsRequestConfig: AxiosRequestConfig = {
            cancelToken: new axios.CancelToken((canceler: Canceler) => { CountObservationFetchCancelToken = canceler })
        };

        dispatch(cleanUpDatasetObservationsErrorMessages());

        if (requestUrl.length > constants.MAX_URL_LENGTH) {
            if (intl) {
                dispatch(setDatasetObservationsFail(createMessageForLongUrl(intl) as IAlertMessage));
            }
        }

        dispatch(isFetching(true));

        var getObservations = axios.get(requestUrlPreview, fetchObservationsRequestConfig);
        var countObservations = axios.get(requestUrlForCount, fetchCountObservationsRequestConfig);

        return axios.all([getObservations, countObservations])
            .then((data: any) => {
                const observations = data[0].data;
                const observationsCount = data[1].data;

                // observations
                const previewData = convertCsvToArrayOfStringArray(observations, ';');
                if (previewData.length === 0 && intl) {
                    const message: IAlertMessage = {
                        Type: AlertEnum.Warning,
                        Title: intl.formatMessage({
                            id: "alert.warning.title",
                            defaultMessage: "Oeps, er is iets mis gegaan!"
                        }),
                        Description: intl.formatMessage({
                            id: "dataset.preview.nodata",
                            defaultMessage: "No data found"
                        })
                    };
                    dispatch(setDatasetObservationsFail(message));
                }

                // count observations
                dispatch(getDatasetObservationsSuccess(previewData, requestUrl, Number(observationsCount), true));

                dispatch(isFetching(false));
            })
            .catch((e: any) => {
                if (!e.__CANCEL__) {
                    dispatch(isFetching(false));
                }
                if (intl) {
                    dispatch(handleDatasetObservationsFailed([], requestUrl, Number(0), false));
                }
            });
    };
};
