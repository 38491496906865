import React, { useRef } from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { IDatasetDimensionTreeModel, setFilteredObservationsQuery } from "../../actions/CCBDataset/CCBDatasetActions";
import { IActionSetFilter, IFilter, setFilter } from "../../actions/Filters/FilterActions";
import FancyTree from "../FancyTree/FancyTree";
import {DimensionStepDefinition} from "./DimensionStepDefinition";
import { IStepProps } from "./Step";
import { WrappedComponentProps, injectIntl } from "react-intl";

export interface IDimensionStepProps extends IStepProps {
    dimensionData: DimensionStepDefinition;
    dimensionTree: IDatasetDimensionTreeModel[];
    isFetching: boolean;
    setFilter: (selection: IFilter) => IActionSetFilter;
    filters: Map<string, IFilter>;
    setFilteredObservationsQuery: () => any
}

export const DimensionStep: React.FC<IDimensionStepProps & WrappedComponentProps> = (props) => {
    const fancyTreeRef = useRef<FancyTree>(null);

    const getCurrentSelection = (identifier: string): IFilter => {
        const {filters} = props;

        const currentSelection = filters.get(identifier);

        if (currentSelection) {
            return currentSelection;
        }

        return {
            FilteredIdentifiers: [],
            Identifier: identifier
        };
    }

    const setFilterFromFancyTree = () => {
        const {setFilter, dimensionData} = props;
        const fancyTree: any = fancyTreeRef.current;

        if (fancyTree === undefined || fancyTree === null)
            return;

        setFilter({
            FilteredIdentifiers: fancyTree.getSelectedKeys(),
            Identifier: dimensionData.dimension.Identifier
        });
        props.setFilteredObservationsQuery();
    }

    
    if (props.currentStep === props.dimensionData.index) {
        const { dimensionData, dimensionTree } = props;
        const filter = getCurrentSelection(dimensionData.dimension.Identifier);
        const currentDimension = dimensionTree.filter((e) =>
            e.DimensionIdentifier === dimensionData.dimension.Identifier)[0];
        return (
            <React.Fragment>
                <FancyTree
                    intl={props.intl} 
                    ref={fancyTreeRef}
                    source={currentDimension.DimensionTree}
                    triggerSelectionChanged={() => setFilterFromFancyTree()}
                    selection={(filter && filter.FilteredIdentifiers && filter.FilteredIdentifiers.length > 0)
                        ? filter.FilteredIdentifiers
                        : []}
                />
            </React.Fragment>
        );
    } else {
        return <React.Fragment />;
    }
}

export const mapStateToProps = ({CurrentDataset, Selection}: any) => {
    return {
        dimensionTree: CurrentDataset.datasetDimensionTreeModel,
        filters: Selection.Filters,
        isFetching: CurrentDataset.isFetching
    };
};

export const mapDispatchToProps = (dispatch: any) => { // tslint:disable-line
    return {
        setFilter: bindActionCreators(setFilter, dispatch),
        setFilteredObservationsQuery: bindActionCreators(setFilteredObservationsQuery, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DimensionStep));
