import React from "react";

import "./PhotoBanner.scss";

export interface IPhotoBannerProps {
    Url: string;
    AltText: string;
}

export default class PhotoBanner extends React.Component<IPhotoBannerProps, {}> {

    public render() {
        const { Url, AltText } = this.props;
        return (
            <div className="banner">
                <div className="banner-inner">
                    <img src={Url} alt={AltText} />
                </div>
            </div>
        );
    }
}
