import {
    IAlertMessage,
    IDatasetDimension,
    IDatasetDimensionTreeModel,
    IDatasetMeasureTreeModel,
    IDatasetObservationsResponse,
    IDatasetProperties, IMetaDataResponse
} from "../actions/CCBDataset/CCBDatasetActions";

export interface ICurrentDatasetState {
    isFetching?: boolean;
    metadata?: IMetaDataResponse;
    datasetProperties?: IDatasetProperties;
    datasetDimensions?: IDatasetDimension[];
    datasetDimensionTreeModel: IDatasetDimensionTreeModel[];
    datasetMeasureTreeModel?: IDatasetMeasureTreeModel;
    observations: IDatasetObservationsResponse;
    observationsUrl: string;
    observationsCount: number;
    alertMessage?: IAlertMessage;
    hasSucceed: boolean;
}

export function defaultCurrentDatasetState(): ICurrentDatasetState {
    return {
        datasetDimensionTreeModel: [],
        isFetching: false,
        metadata: {},
        observations: [],
        observationsUrl: "",
        observationsCount: 0,
        hasSucceed: false
    };
}
