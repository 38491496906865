import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { mainReducer } from "./reducers/mainReducer";
import { defaultState } from "./types/IStoreState";

// because of the code below you can use the "Redux Devtools" in the browser for checking the active state and history.
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(mainReducer as any, defaultState(), composeEnhancers(applyMiddleware(thunk)));

export default store;