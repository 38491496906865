import { defaultSettingsState, ISettingsState } from "../../types/ISettingsState";
import { SettingsActions } from "../../actions/Settings/SettingsActions";
import {
    GET_SETTINGS,
    SET_FETCHING_SETTINGS
} from "../../actions/Settings/SettingsConstants";

export const SettingsReducer = (state: ISettingsState, action: SettingsActions): ISettingsState => {
    switch (action.type) {
        case GET_SETTINGS:
            return {...state, 
                hostCcbODataApiUrl: action.settings.hostCcbODataApiUrl, 
                hostSiteCoreODataApiUrl: action.settings.hostSiteCoreODataApiUrl,
                hostThemesTreeUrl: action.settings.hostThemesTreeUrl != null ? action.settings.hostThemesTreeUrl : defaultSettingsState().hostThemesTreeUrl,
                canonicalNlRootUrl: action.settings.canonicalNlRootUrl,
                zipLocation: action.settings.zipLocation
            };
        case SET_FETCHING_SETTINGS:
            return { ...state, isFetching: action.isFetching };
        default:
            return state;
    }
}
