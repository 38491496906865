import {
    ICatalogResponse,
    IMetaDataResponse,
} from "../actions/CCBDataset/CCBDatasetActions";

export interface ICatalogsState {
    isFetching: boolean;
    datasets?: IMetaDataResponse[];
    catalogs?: ICatalogResponse[];
    searchParams?: string;
}

export function defaultCatalogsState(): ICatalogsState {
    return {
        catalogs: undefined,
        datasets: undefined,
        isFetching: false,
        searchParams: undefined
    };
}
