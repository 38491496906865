import React from "react";
import { Helmet } from "react-helmet-async";
import { WrappedComponentProps, injectIntl } from "react-intl";
import { ISiteCorePageResponse } from "../../actions/SiteCore/SiteCoreActions";
import { ILocalizationState } from "../../types/ILocalizationState";
import Accordeon from "../Accordeon/Accordeon";
import Alert, { AlertEnum } from "../Alert/Alert";

//import "./Faq.scss";

export interface IFaqProps extends WrappedComponentProps {
    Localization: ILocalizationState;
    Faq: ISiteCorePageResponse[];
    getFaq: () => (dispatch: any) => Promise<void>;
}

export class FAQ extends React.Component<IFaqProps, {}> {

    public componentDidMount(): void {
        this.props.getFaq();
    }

    componentDidUpdate(prevProps: Readonly<IFaqProps>, prevState: Readonly<{}>): void {
        if (this.props.Localization.currentLanguage !== prevProps.Localization.currentLanguage) {
            this.props.getFaq();
        }
    }

    content = (siteCorePageResponse: ISiteCorePageResponse) => {
        if (siteCorePageResponse && siteCorePageResponse.Body) {
            return <div dangerouslySetInnerHTML={{ __html: siteCorePageResponse.Body }}></div>
        }
        return <React.Fragment />;
    }

    messageGermanContentNotAvailable = () => {
        const { Localization, intl } = this.props;
        if (Localization.currentLanguage === "de") {

            return (
                <Alert
                    Title={intl.formatMessage({ id: "alert.info.title", defaultMessage: "Ter Info" })}
                    Description={intl.formatMessage({ id: "alert.germanContentNotAvailable", defaultMessage: "Ter Info" })}
                    Status={AlertEnum.Info}
                />
            );
        }
    }

    private _setPageTitle() {
        const { intl } = this.props;
        return (
            <Helmet>
                <title>{intl.formatMessage({ id: "faq.header", defaultMessage: "Veelgestelde vragen" })}</title>
            </Helmet>
        );
    }

    public render() {
        const { Faq, intl } = this.props;

        let questions = <React.Fragment />;

        if (Faq && Faq.length > 0) {
            const question = Faq.map((item: ISiteCorePageResponse, i) => {
                if (item.Title && item.Body) {
                    return <Accordeon key={i} ShouldBeOpen={false} Index={i} Header={item.Title} Content={this.content(item)} ShowTotalCells={false} />;
                }
                return null;
            });
            questions =
                <React.Fragment>
                    {question}
                </React.Fragment>;
        }

        return (<React.Fragment>
            <div className="page catalogs-datasets-overview">
                {this._setPageTitle()}
                {this.messageGermanContentNotAvailable()}
                <h1>{intl.formatMessage({ id: "faq.header", defaultMessage: "Veelgestelde vragen" })}</h1>
                {questions}
            </div>
        </React.Fragment>);
    }
}

export default injectIntl(FAQ);
