import React from "react";
import { WrappedComponentProps, injectIntl } from "react-intl";
import { IDatasetProperties } from "../../actions/CCBDataset/CCBDatasetActions";
import 'font-awesome/css/font-awesome.min.css';

export interface IDownloadOptions {
    datasetProperties: IDatasetProperties;
    observationsUrl: string;
}

export class DownloadOptions extends React.Component<IDownloadOptions & WrappedComponentProps, {}> {

    public render() {
        const { datasetProperties } = this.props;
        const { observationsUrl } = this.props;

        // Based on https://stackoverflow.com/questions/40526102/how-do-you-format-a-date-time-in-typescript
        function dateAsYYYYMMDDHHNNSS(date: Date): string {
            
            return date.getFullYear()
                + '-' + leftpad(date.getMonth() + 1)
                + '-' + leftpad(date.getDate())
                + 'T' + leftpad(date.getHours())
                + ':' + leftpad(date.getMinutes())
                + ':' + leftpad(date.getSeconds());
        }

        function leftpad(val: number, resultLength = 2, leftpadChar = '0'): string {
            return (String(leftpadChar).repeat(resultLength)
                + String(val)).slice(String(val).length);
        }

        const csvFilename = `${datasetProperties.Identifier}_${dateAsYYYYMMDDHHNNSS(new Date())}`;
        const csvSelectieDownloadLink = `${observationsUrl}&csv=${csvFilename}`;

        return (
            <React.Fragment>
                <div className="download-options">
                    <a download href={csvSelectieDownloadLink} className="piwik-download" >
                        <button disabled={!(csvSelectieDownloadLink.length > 0)}>
                            <i className="fa fa-download"></i>
                            {
                                this.props.intl.formatMessage({
                                    id: "download.button.csv.selectie",
                                    defaultMessage: "Download CSV selectie"
                                })
                            }
                        </button>
                    </a>
                </div>   
            </React.Fragment> 
        );
    }
}

export default injectIntl(DownloadOptions);