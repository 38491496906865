export interface IUIState {
    navToggle: boolean;
    searchbarToggle: boolean;
    subnavInfoToggle: boolean;
    subnavDatasetsToggle: boolean;
}

export function defaultUIState(): IUIState {
    return {
        navToggle: false,
        searchbarToggle: false,
        subnavInfoToggle: false,
        subnavDatasetsToggle: false
    };
}
