const exportedObject = {
    "app.title": "Datenportal",
    "header.skiplink": "Zum Hauptinhalt springen",
    "header.title": "Datenportal",
    "header.link.logo": "CBS-Logo, Link zur Homepage",
    "searchbar.placeholdertext": "Suche...",
    "searchbar.buttontext": "Suchtaste",
    "searchbar.input.field.text": "Suche Eingabefeld",
    "search.notfound": "Bitte überprüfen Sie die Schreibweise der Suchbegriffe, versuchen Sie es mit einem anderen Suchbegriff oder beschränken Sie sich auf einen Suchbegriff.",
    "search.subject.input": "Suche nach Thema...",
    "search.refine.input": "Suchergebnisse verfeinern...",
    "search.facets.title": "Filter",
    "search.results.title": "Ergebnisse für",
    "search.alert.temporary.title": "Suche wurde noch nicht implementiert.",
    "search.alert.temporary.description": "Diese Funktionalität wird später hinzugefügt.",
    "nav.label.main": "Hauptnavigation",
    "nav.link.current": "Aktuelle Seite: ",
    "nav.link.home": "Home",
    "nav.link.datasets": "Datensätze",
    "nav.link.audience": "Informationen für",
    "nav.link.users": "Benutzer",
    "nav.link.analysts": "Datenanalytiker",
    "nav.link.developers": "Entwickler",
    "nav.link.faq": "Häufig gestellte Fragen",
    "motto": "<strong>Tatsachen</strong>, die wichtig sind",
    "nav.label.footer": "Fußzeilennavigation",
    "footer.link.privacy": "Privatsphäre",
    "footer.link.text.cbs": "CBS.nl",
    "footer.link.url.cbs": "https://www.cbs.nl/en-gb",
    "tableWidget.firstHeader": "Katalog",
    "tableWidget.secondHeader": "Anzahl der Datensätze",
    "tableWidget.caption": "Katalogs und Datensätze",
    "dataset.tab.information": "Information",
    "dataset.tab.metadata": "Metadata",
    "dataset.tab.wizard": "die Selektion",
    "dataset.tab.downloads": "Downloads",
    "dataset.details.identifier": "Datensatzkennung",
    "dataset.details.description": "Beschreibung",
    "dataset.details.versionNotes": "Zusätzliche Informationen zu dieser Lieferung",
    "dataset.details.relatedSources": "Relevante Links",
    "dataset.details.themes": "Standort im Themenbaum",
    "dataset.details.provenance": "Quellen",
    "dataset.details.frequency": "Frequenz",
    "dataset.details.temporalCoverage": "Berichtszeitraum",
    "dataset.details.versionReason": "Versionsgrund",
    "dataset.details.modified": "modifiziert",
    "dataset.details.status": "Status",
    "dataset.details.datasetType": "Datensatztyp",
    "dataset.details.datasetTypeNumeric": "Numeric",
    "dataset.details.datasetTypeMixed": "Mixed (sowohl numerische als auch textuelle Werte)",
    "dataset.details.datasetTypeText": "Text",
    "dataset.details.observationCount": "Anzahl der Zellen",
    "dataset.details.license": "lizenz",
    "dataset.details.source": "Quelle",
    "dataset.details.linkToApi": "Link zu API",
    "dataset.details.linkToCsvDistribution": "Download CSV Verteilung",
    "dataset.preview.columnSelection": "Klassifizierungsdatensatz",
    "dataset.preview.columnSelection.caption": "Wählen Sie die Dimension für den Datensatz",
    "dataset.preview.measure": "Messen",
    "dataset.preview.measures": "Themen",
    "dataset.preview.measures.caption": "Themen (Measures)",
    "dataset.preview.unit": "Einheit",
    "dataset.preview.nodata": "Keine Daten gefunden",
    "dataset.preview.urltolong": "Sie haben einen CSV-Download mit vielen Elementen (Filtern) durchgeführt. Ihr Browser kann es möglicherweise nicht verarbeiten. Weitere Informationen finden Sie in den FAQ.",
    "dataset.preview.dimensionsWithoutCodeLists.input": "Beginnt mit...",
    "dataset.preview.dimensionsWithoutCodeLists.add": "Hinzufügen",
    "dataset.preview.dimensionsWithoutCodeLists.explanation": "Filtern Sie Codes nach einer oder mehreren Initialen und drücken Sie +",
    "alert.error.title": "Hoppla, etwas ist schief gelaufen!",
    "alert.error.description": "zusätzliche Informationen zur Fehlermeldung",
    "alert.warning.title": "Warnung!",
    "alert.warning.description": "zusätzliche Informationen zur warnung",
    "alert.info.title": "Für Infos",
    "alert.info.description": "zusätzliche Informationen zur bericht",
    "alert.description.downloads": "Geen downloads beschikbaar.",
    "alert.germanContentNotAvailable": "Dieser Inhalt ist derzeit nur in englischer Sprache nicht in deutscher Sprache verfügbar",
    "download.button.csv": "Download CSV",
    "download.alert.title": "Derzeit ist die Download-Option noch nicht implementiert.",
    "download.alert.description": "Um Daten aus diesem Datensatz herunterzuladen, können Sie die <a href='{API_Url}'>API</a> verwenden. Dies erfordert einige technische Kenntnisse. Klicken Sie <a href='/info/analyst'>here</a>, um eine Anleitung zur Verwendung der API zu erhalten.",
    "download.zip.disclaimer": "Let op de data is mogelijk niet actueel. [TODO german]",
    "download.zip.header.text": "Download volledige dataset als CSV [TODO german]",
    "preview.csv.integraal": "vollständige CSV",
    "download.button.csv.selectie": "Download CSV Auswahl",
    "download.button.csv.integraal": "Download vollständige CSV",
    "preview.csv.waarschuwing.maxcellen": "Bitte beachten Sie, dass der CSV-Download nur die ersten 100.000 Zellen enthält.",
    "preview.niet.succes.response": "Sie haben einen CSV-Download mit zu vielen Elementen durchgeführt. Begrenzen Sie die Anzahl der Elemente (z. B. mit klaren Filtern). Weitere Informationen finden Sie in den FAQ.",
    "csv.download.information": "<i role=\"img\" class=\"internal-links__icon\">—</i>De \"Download CSV selectie\" bevat alleen gevulde cellen. <br/> <i role=\"img\" class=\"internal-links__icon\">—</i>De \"Download CSV selectie\" bevat alleen de eerste 100.000 cellen. <br/> <i role=\"img\" class=\"internal-links__icon\">—</i>Download de volledige dataset via \"Download volledige CSV\".",
    "spinner.image.alt": "CBS spinner.",
    "spinner.loading.text": "Die daten werden geladen.",
    "datasets.table.header.title": "Titel",
    "datasets.table.header.totalcells": "Anzahl der Zellen",
    "themestree.parentnode.title": "Themen",
    "faq.header": "Häufig gestellte Fragen",
    "themestree.info": "Auf dieser Seite finden Sie die Datensätze, die Statistics Netherlands auch über StatLine anbietet. Sie sind in Themen und Unterthemen gegliedert. Durch Klicken auf ein (Unter-)Thema können Sie durch die Themen blättern. Das <i aria-label=\"tabel\" class=\"fa fa-table fa-fw\"></i> Symbol zeigt an, dass es sich um einen Datensatz handelt. Wenn Sie zurückgehen möchten, können Sie auf die Breadcrumbs über den Themen klicken.",
    "datasetforcatalog.info": "Op deze pagina vindt u de datasets terug die het CBS niet via StatLine aanbiedt.",
    "themetree.asdDatasetTitle": "CBS aanvullend"
};

export default exportedObject;