import $ from "jquery";
import "jquery.fancytree";
import "jquery.fancytree/dist/modules/jquery.fancytree.filter";
import "jquery.fancytree/dist/skin-lion/ui.fancytree.min.css";
import React, { Component } from "react";
import { WrappedComponentProps } from "react-intl";

// See https://github.com/nathanial/react-fancy-tree/blob/master/src/TreeView.jsx for react-fancy-tree
// See http://wwwendt.de/tech/fancytree/demo/#sample-ext-filter.html for filtering
// See http://wwwendt.de/tech/fancytree/demo/#sample-ext-multi.html for multi-select
// See http://wwwendt.de/tech/fancytree/demo/#sample-select.html for sample-select

export interface IFancyTreeProps {
    source: any;
    singleSelect?: boolean;
    selection?: string[];
    triggerSelectionChanged?: () => void;
    children?: React.ReactNode;
}

export default class FancyTree extends Component<IFancyTreeProps & WrappedComponentProps, any> {
    constructor(props: IFancyTreeProps & WrappedComponentProps) {
        super(props);

        this.doFilter = this.doFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.deselectAll = this.deselectAll.bind(this);
        this.getTree = this.getTree.bind(this);
        this.getSelectedKeys = this.getSelectedKeys.bind(this);
        this.setSelectedKeys = this.setSelectedKeys.bind(this);
        this.throttle = this.throttle.bind(this);
    }

    private filterOptions: any = {
        autoApply: true,   // Re-apply last filter if lazy data is loaded
        autoExpand: true, // Expand all branches that contain matches while filtered
        counter: true,     // Show a badge with number of matching child nodes near parent icons
        fuzzy: false,      // Match single characters in order, e.g. 'fb' will match 'FooBar'
        hideExpandedCounter: true,  // Hide counter badge if parent is expanded
        hideExpanders: false,       // Hide expanders if all child nodes are hidden by filter
        highlight: true,   // Highlight matches by wrapping inside <mark> tags
        leavesOnly: false, // Match end nodes only
        nodata: false,      // Display a 'no data' status node if result is empty
        mode: "hide"       // "dimm" to Grayout unmatched nodes (pass "hide" to remove unmatched node instead)
    };

    private timer: any = null

    private getTree(): any {
        const tree: Element = this.refs._fancytree as Element;
        return ($.ui.fancytree as any).getTree(tree);
    }

    private doFilter(event: any) {
        const filter: HTMLInputElement = this.refs._filter as HTMLInputElement;
        const tree = this.getTree();
        tree.filterNodes.call(tree, filter.value);
    }

    private resetFilter() {
        const filter: HTMLInputElement = this.refs._filter as HTMLInputElement;
        filter.value = "";
        this.getTree().clearFilter();
    }

    private getSelectedKeys(): string[] {
        const tree: Fancytree.Fancytree = this.getTree();
        return tree.getSelectedNodes()
            .filter((node: Fancytree.FancytreeNode) => !(node.hasChildren()))
            .map((node: Fancytree.FancytreeNode) => node.key);
    }

    private setSelectedKeys(keys: string[]) {
        const tree: Fancytree.Fancytree = this.getTree();
        if (tree && keys && keys.length > 0) {
            let keysObject: any = {};
            keys.forEach(key => keysObject[key] = key);
            if (typeof tree.visit === "function") {
                tree.visit(node => {
                    //Voor kinderen die bestaan onder een root groep(parent) gebruiken we Identifier ipv Id
                    if (node && keysObject[node.data.Identifier]) {
                        node.setSelected(true)
                    }
                    //Voor root groepen(parents) die geen childrens hebben, gebruiken we Id ipv Identifier
                    else if (node && !node.hasChildren() && keysObject[node.data.Id]) {
                        node.setSelected(true)
                    }
                });
            }
        }
    }

    private toggleOption(event: JQueryEventObject, data: Fancytree.EventData): boolean {
        event.preventDefault();
        event.stopPropagation();
        if (data.node.isSelected()) {
            return false;
        } else {
            data.node.setSelected();
            data.node.setFocus();
            data.node.setActive();
            return false;
        }
    }

    private throttle() {
        this.timer = setTimeout(this.doFilter, 700);
    }

    private deselectAll() {
        const tree = this.getTree();
        if (tree && tree.visit) {
            tree.visit((node: any) => {
                node.setSelected(false);
            });
        }
        return false;
    }

    private renderFilter() {
        if (!this.props.singleSelect) {
            return <div className="searchbar filtersearch" ref="_filterTools">
                <label htmlFor="search" className="hidden">Zoek:</label>
                <input name="search" ref="_filter"
                    placeholder={this.props.intl.formatMessage({ id: 'searchbar.placeholdertext', defaultMessage: "Zoeken..." })}
                    onFocus={(e) => { e.target.placeholder = ''; }}
                    onBlur={(e) => { e.target.placeholder = this.props.intl.formatMessage({ id: 'searchbar.placeholdertext', defaultMessage: "Zoeken..." }); }}
                    onKeyUp={this.throttle}
                    aria-label={this.props.intl.formatMessage({ id: 'searchbar.input.field.text', defaultMessage: "Zoeken invoerveld" })}
                />
                <button id="btnResetSearch" onClick={this.resetFilter} className="erase">&times;</button>
                <button id="btnDeselectAll" onClick={this.deselectAll} className="erase-all">Wis alle filters
                </button>
            </div>;
        }

        return <React.Fragment />
    }

    render() {
        return (
            <div className="tree-view">
                {this.renderFilter()}
                <div className="fancytree" ref="_fancytree"></div>
                {this.props.children}
            </div>
        );
    }

    shouldComponentUpdate(nextProps: Readonly<IFancyTreeProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        return (this.props.source !== nextProps.source) || (this.props.singleSelect !== nextProps.singleSelect);
    }

    componentDidMount(): void {
        this.buildTree(this.props.source);
    }

    private _fancyTreeOption(source: any, singleSelect: boolean): Fancytree.FancytreeOptions {
        if (singleSelect) {
            const self = this;
            return {
                selectMode: 1,
                checkbox: "radio",
                source: source,
                init: function (_event, data) {
                    // Set key from Identifier or Id
                    data.tree.visit(function (node) {
                        node.key = node.data.Identifier || node.data.Id;
                    });
                },
                renderTitle: function (_event, data) {
                    data.node.title = data.node.data.Title;
                },
                click: function (event, data) {
                    return self.toggleOption(event, data);
                },
                keydown: function (event, data) {
                    if (event.key === " ") {
                        return self.toggleOption(event, data);
                    }
                    return true;
                }
            }
        } else {
            return {
                selectMode: 3,
                checkbox: true,
                extensions: ["filter"],
                filter: this.filterOptions,
                quicksearch: true,
                source: source,
                select: (_event, data) => {
                    if (this.props.triggerSelectionChanged)
                        this.props.triggerSelectionChanged();
                },
                init: function (_event, data) {
                    // Set key from Identifier or Id
                    data.tree.visit(function (node) {
                        node.key = node.data.Identifier || node.data.Id;
                    });
                },
                renderTitle: function (_event, data) {
                    data.node.title = data.node.data.Title;
                },
                click: function (_event, data) {
                    if (data.targetType !== "expander" && data.targetType !== "checkbox") {
                        data.node.toggleSelected();
                        return false;
                    }
                    return true;
                }
            }
        }
    }

    public buildTree(source: any) {
        const tree: Element = this.refs._fancytree as Element;
        const selection = this.props.selection;
        $(tree).fancytree(this._fancyTreeOption(source, this.props.singleSelect!));

        if (selection) {
            this.setSelectedKeys(selection);
        }
        const thisTree: any = this.getTree();
        if (thisTree && typeof thisTree.expandAll === "function") {
            const nodesCount = thisTree.count();
            if (nodesCount < 1000) {
                thisTree.expandAll();
            } else {
                const rootNode: Fancytree.FancytreeNode = thisTree.getRootNode();
                const test = this.getComposites(rootNode.children, 1);
                this.expandComposites(thisTree, test);
            }
        }
    }

    private getComposites(components: Fancytree.FancytreeNode[], level: number = 1): Fancytree.FancytreeNode[] {
        const composites: any[] = [];
        if (level > 0) {
            components.forEach((component) => {
                const children: any[] = component.children;
                if (children && children.length > 0) {
                    composites.push(component);
                    composites.push(...this.getComposites(children, level - 1));
                }
            })
        }
        return composites;
    }

    private expandComposites(tree: any, composites: Fancytree.FancytreeNode[]) {
        const prev = tree.enableUpdate(false);
        composites.forEach((composite) => {
            if (!composite.isExpanded()) {
                composite.setExpanded();
            }
        });
        tree.enableUpdate(prev);
    }

    private destroyTree() {
        const tree: Element = this.refs._fancytree as Element;
        $(tree).fancytree("destroy");
    }

    public componentWillUnmount() {
        this.destroyTree();
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }
}
