import axios from "axios";
import { IntlFormatters } from "react-intl";
import { Dispatch } from "redux";
import { IStoreState } from "../../types/IStoreState";
import { IMetaDataResponse } from "../CCBDataset/CCBDatasetActions";
import * as constants from "./ThemesTreeConstants";

// ACTION DEFINITION
export interface IActionGetThemeTree {
    type: constants.GET_THEME_TREE;
    themeTree: IThemeTreeNode[];
}

export interface IThemeTreeNode {
    ID: number;
    ParentId: number | null;
    Parent?: IThemeTreeNode | null;
    Number?: string;
    IsTable: boolean;
    Data: IThemesDataResponse | IMetaDataResponse;
    Children: IThemeTreeNode[];
}

export interface IThemesDataResponse {
    ID: number;
    ParentID: number | null;
    Number: string;
    Title?: string;
    Language?: string;
    Catalog?: string;
}

export interface ITableThemesDataResponse {
    ID: number;
    TableID: number;
    TableIdentifier?: string;
    ThemeID: number;
    ThemeNumber?: string;
}

export interface IActionSetFetchingThemes {
    type: constants.SET_FETCHING_THEMES;
    isFetching: boolean;
}


// Union Action Types
export type ThemesTreeActions
    = IActionGetThemeTree
    | IActionSetFetchingThemes;

// Action Creators
const getThemeTreeSuccess = (themeTree: IThemeTreeNode[]): IActionGetThemeTree => {
    return {
        themeTree,
        type: constants.GET_THEME_TREE
    };
};

const isFetching = (isFetching: boolean): IActionSetFetchingThemes => {
    return {
        isFetching,
        type: constants.SET_FETCHING_THEMES
    };
};

function AddDatasetsWithoutAThemeToCbsAsdTheme(tableThemes: ITableThemesDataResponse[], datasets: IMetaDataResponse[], nodes: IThemeTreeNode[], intl?: IntlFormatters): IThemeTreeNode[] {
    var cbsAsdTheme: IThemeTreeNode = {
        ID: 999999,
        Parent: null,
        ParentId: null,
        IsTable: false,
        Number: "999999",
        Data: {
            ID: 999999,
            Number: "999999",
            ParentID: null,
            Catalog: "CBS",
            Language: "nl",
            Title: intl?.formatMessage(
                {
                    id: "themetree.asdDatasetTitle",
                    defaultMessage: "CBS aanvullend"
                })
        } as IThemesDataResponse,
        Children: []
    }

    cbsAsdTheme.Children = getAllDatasetsWithoutATheme(tableThemes, datasets, cbsAsdTheme);

    nodes.push(cbsAsdTheme);

    return nodes;
}

function getAllDatasetsWithoutATheme(tableThemes: ITableThemesDataResponse[], datasets: IMetaDataResponse[], rootTheme: IThemeTreeNode): IThemeTreeNode[]
{
    const tableThemeIdentifiers = tableThemes.map(e => e.TableIdentifier);
    
    const datasetsWithoutATheme = datasets.filter(d => !tableThemeIdentifiers.includes(d.Identifier));
    return datasetsWithoutATheme.map(dataset => {
        const node: IThemeTreeNode = {
            ID: 999999,
            ParentId: rootTheme.ID,
            Parent: rootTheme,
            IsTable: true,
            Number: "999999",
            Data: dataset,
            Children: []
        }
        return node;
    });
}

function BuildThemeTree(themes: IThemesDataResponse[], tableThemes: ITableThemesDataResponse[], datasets: IMetaDataResponse[], parent: IThemesDataResponse | null, prevNode: IThemeTreeNode | null): IThemeTreeNode[] {
    var nodes: IThemeTreeNode[] = [];
    var parentID = parent === null ? null : parent.ID;
    var tables = tableThemes?.filter(e => e.ThemeID === parentID);

    if (tables !== undefined) {
        tables.forEach((t, i) => {
            if (t === null)
                return;

            const dataset = datasets.filter(d => d.Identifier === t.TableIdentifier)[0];

            // TODO Add Dataset Info
            let node: IThemeTreeNode = {
                ID: t.TableID,
                ParentId: parentID,
                Parent: prevNode,
                IsTable: true,
                Number: t.ThemeNumber,
                Data: dataset,
                Children: []
            }
            nodes.push(node);
        });
    }

    themes?.forEach((t, i) => {
        if (t.ParentID === parentID && t.Language && t.Language.toLowerCase() === 'nl') {
            let node: IThemeTreeNode = {
                ID: t.ID,
                ParentId: t.ParentID,
                Parent: prevNode,
                IsTable: false,
                Number: t.Number,
                Data: t,
                Children: []
            }

            var children = BuildThemeTree(themes, tableThemes, datasets, t, node);

            node.Children = children;
            nodes.push(node);
        }
    });

    return nodes;
}

export function getThemeTree(catalog: string, language: string, intl?: IntlFormatters): any {
    return (dispatch: Dispatch, getState: () => IStoreState) => {
        const { hostThemesTreeUrl, hostCcbODataApiUrl } = getState().Settings;
        dispatch(isFetching(true));
        var getDatasets = axios.get(hostCcbODataApiUrl + `${catalog}/Datasets/?$filter=Language eq '${language}'&$select=Title,Identifier`);
        var getThemes = axios.get(hostThemesTreeUrl + `Themes/?$filter=Language eq '${language}' and Catalog eq '${catalog}'&$format=json`);
        var getTableThemes = axios.get(hostThemesTreeUrl + "Tables_Themes?$format=json");
        return axios.all([getThemes, getTableThemes, getDatasets])
            .then((data: any) => {
                const themes: IThemesDataResponse[] = data[0].data.value;
                const tableThemes: ITableThemesDataResponse[] = data[1].data.value;
                const datasets: IMetaDataResponse[] = data[2].data.value;

                let themeTree = BuildThemeTree(themes, tableThemes, datasets, null, null);

                //Add datasets that don't have a theme to the theme CBS-aanvullend
                themeTree = AddDatasetsWithoutAThemeToCbsAsdTheme(tableThemes, datasets, themeTree, intl);

                dispatch(getThemeTreeSuccess(themeTree));
            })
            .finally(() => {
                dispatch(isFetching(false));
            });
    };
}