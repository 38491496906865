import React, { KeyboardEvent, MouseEvent, useRef } from "react";
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IUIState } from "../../types/IUIState";
import "./Searchbar.scss";
import { withRouter, RouteComponentProps } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';

export interface ISearchbarProps {
    uiState: IUIState;
    toggleSearchbar?: () => void;
    actionSetSearch: (searchParams: string) => void;
}

export const Searchbar: React.FunctionComponent<ISearchbarProps & WrappedComponentProps & RouteComponentProps> = (props) =>
{
    const searchBarInputRef = useRef<HTMLInputElement>(null);
    const search = () => {
        let input: HTMLInputElement | null = searchBarInputRef.current;
        if (input !== null) {
            props.actionSetSearch(input.value);
            props.history.push('/list');
        }
    }

    const searchKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            search()
        }
    }

    const searchOnClick = (event: MouseEvent) => {
        search()
    }

    const { toggleSearchbar, uiState } = props;
    const searchbarVisible = uiState.searchbarToggle ? 'show' : 'hide';
    const buttonClose = uiState.searchbarToggle ? 'close' : 'open';

    return (
        <React.Fragment>
            <button onClick={toggleSearchbar} className={`slide-out-trigger ${buttonClose}`} type="button" aria-label="open searchbar">
                <i className="fa fa-search"></i>
            </button>

            <div className={`searchbar ${searchbarVisible}`} role='search'>
                <label htmlFor="searchbar" className="hidden">{props.intl.formatMessage({ id: 'searchbar.placeholdertext', defaultMessage: "Zoeken" })}</label>
                <input type="text" id="searchbar"
                    ref={searchBarInputRef}
                    placeholder={props.intl.formatMessage({ id: 'searchbar.placeholdertext', defaultMessage: "Zoeken..." })}
                    onFocus={(e) => { e.target.placeholder = ''; }}
                    onBlur={(e) => { e.target.placeholder = props.intl.formatMessage({ id: 'searchbar.placeholdertext', defaultMessage: "Zoeken..." }); }}
                    onKeyPress={e => searchKeyPress(e)}
                    defaultValue= ""/>

                <button onClick={(e) => searchOnClick(e)} aria-label={props.intl.formatMessage({ id: 'searchbar.buttontext', defaultMessage: "Zoekknop" })}>
                    <i className="fa fa-search"></i>
                </button>
            </div>
        </React.Fragment>
    );
}

export default injectIntl(withRouter(Searchbar));
