import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    cleanUpCurrentDataset,
    getDatasetDimensionsTreeModal,
    getDatasetMeasureTreeModal,
    getDatasetProperties,
} from "../../actions/CCBDataset/CCBDatasetActions";
import {cleanUpCurrentFilters} from "../../actions/Filters/FilterActions";
import DatasetDisplay from "../../components/CCBDataset/DatasetDisplay";
import { getThemeTree } from "../../actions/ThemesTree/ThemesTreeActions";

export const mapStateToProps = ({ CurrentDataset, Settings, Themes, Catalogs }: any) => {
    return {
        baseUrl: Settings.hostCcbODataApiUrl,
        canonicalNlRootUrl: Settings.canonicalNlRootUrl,
        zipLocation: Settings.zipLocation,
        datasetDimensions: CurrentDataset.datasetDimensions,
        datasetMeasureTreeModal: CurrentDataset.datasetMeasureTreeModel,
        datasetDimensionTreeModal: CurrentDataset.datasetDimensionTreeModel,
        datasetProperties: CurrentDataset.datasetProperties,
        isFetching: CurrentDataset.isFetching,
        isFetchingDimensions: CurrentDataset.isFetchingDimensions,
        themeTree: Themes.themeTree,
        isFetchingThemes: Themes.isFetching
    };
};

const mapDispatchToProps = (dispatch: any) => { // tslint:disable-line
    return {
        cleanUpCurrentDataset: bindActionCreators(cleanUpCurrentDataset, dispatch),
        cleanUpCurrentFilters: bindActionCreators(cleanUpCurrentFilters, dispatch),
        getDatasetDimensionsTreeModal: bindActionCreators(getDatasetDimensionsTreeModal, dispatch),
        getDatasetMeasureTreeModal: bindActionCreators(getDatasetMeasureTreeModal, dispatch),
        getDatasetProperties: bindActionCreators(getDatasetProperties, dispatch),
        getThemeTree: bindActionCreators(getThemeTree, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DatasetDisplay);
