import React from "react";
import { WrappedComponentProps, injectIntl } from "react-intl";
import "./Spinner.scss";
import cbsspinner from "./cbs-spinner.svg";

export interface ISpinnerProps extends WrappedComponentProps {
    SpinnerAriaLabel: string
}

class Spinner extends React.Component<ISpinnerProps & WrappedComponentProps, {}> {
    public render() {
        var { SpinnerAriaLabel } = this.props;
        return (
            <div className={"spinner-container center"}
                role="alert"
                aria-live="assertive"
                aria-label={SpinnerAriaLabel}>

                <img
                    alt={this.props.intl.formatMessage({ id: "spinner.image.alt", defaultMessage: "CBS spinner" })}
                    className={"spinner"}
                    src={cbsspinner}
                />
            </div>
        );
    }
}

export default injectIntl(Spinner);
