import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getHomePage, getHomePageBanner} from "../../actions/SiteCore/SiteCoreActions";
import HomePage from "../../components/HomePage/HomePage";
import {IStoreState} from "../../types/IStoreState";

export const mapStateToProps = (state: IStoreState) => {
    return {
        Catalogs: state.Catalogs,
        HomePageData: state.SiteCore,
        Localization: state.Localization
    };
};

export const mapDispatchToProps = (dispatch: any) => { // tslint:disable-line
    return {
        getHomePageBanner: bindActionCreators(getHomePageBanner, dispatch),
        getHomePageContext: bindActionCreators(getHomePage, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
