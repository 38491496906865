// store/session/reducers.ts
import {SiteCoreActions} from "../../actions/SiteCore/SiteCoreActions";
import {
    GET_FAQ,
    GET_HOMEPAGE,
    GET_HOMEPAGE_BANNER,
    GET_INFOPAGE,
    GET_PRIVACYPAGE,
    SET_FETCHING_SITECORE
} from "../../actions/SiteCore/SiteCoreConstants";
import {defaultSiteCoreState, ISiteCoreState} from "../../types/ISiteCoreState";

export const SiteCoreReducer = (state: ISiteCoreState = defaultSiteCoreState(), action: SiteCoreActions): ISiteCoreState => {
    switch (action.type) {
        case GET_HOMEPAGE:
            return { ...state, HomePage: action.siteCoreResponses };
        case GET_HOMEPAGE_BANNER:
            return { ...state, HomePageBanner: action.siteCoreResponse };
        case GET_FAQ:
            return { ...state, Faq: action.siteCoreResponses };
        case GET_INFOPAGE:
            return { ...state, CurrentInfoPage: action.siteCoreResponse };
        case GET_PRIVACYPAGE:
            return { ...state, CurrentPrivacyPage: action.siteCoreResponse };
        case SET_FETCHING_SITECORE:
            return { ...state, isFetching: action.isFetching };
        default:
            return state;
    }
};
