// store/session/reducers.ts
import {
    ThemesTreeActions,
} from "../../actions/ThemesTree/ThemesTreeActions";
import {
    GET_THEME_TREE,
    SET_FETCHING_THEMES
} from "../../actions/ThemesTree/ThemesTreeConstants";
import { IThemesState } from "../../types/IThemesState";

export const ThemesTreeReducer = (state: IThemesState, action: ThemesTreeActions): IThemesState => {
    switch (action.type) {
        case GET_THEME_TREE:
            return { ...state, themeTree: action.themeTree }
        case SET_FETCHING_THEMES:
            return { ...state, isFetching: action.isFetching };
        default:
            return state;
    }
};
