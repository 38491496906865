import { IFilter } from "../actions/Filters/FilterActions";

export interface IFiltersState {
    Filters: Map<string, IFilter>;
}

export function defaultFiltersState(): IFiltersState {
    return {
        Filters: new Map<string, IFilter>(),
    };
}
