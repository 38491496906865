export interface ILocalizationState {
    languages: string[];
    currentLanguage: string;
}

export function defaultLocalizationState(): ILocalizationState {
    return {
        currentLanguage: "nl",
        languages: ["en", "nl", "de"]
    };
}
