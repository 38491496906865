import { TOGGLE_NAVTOGGLE, TOGGLE_INFOSUBNAV, CHANGE_INFOSUBNAV, TOGGLE_DATASETSSUBNAV, CHANGE_DATASETSSUBNAV, TOGGLE_SEARCHBAR_TOGGLE } from "../../actions/UI/UIConstants";
import {UIActions} from "../../actions/UI/UIActions";
import {IUIState} from "../../types/IUIState";

export function UIReducer(state: IUIState, action: UIActions): IUIState {
    switch (action.type) {
        case TOGGLE_NAVTOGGLE:
            return { ...state, navToggle: !state.navToggle };
        case TOGGLE_INFOSUBNAV:
            return { ...state, subnavInfoToggle: !state.subnavInfoToggle };
        case CHANGE_INFOSUBNAV:
            return { ...state, subnavInfoToggle: action.value };
        case TOGGLE_DATASETSSUBNAV:
            return { ...state, subnavDatasetsToggle: !state.subnavDatasetsToggle };
        case CHANGE_DATASETSSUBNAV:
            return { ...state, subnavDatasetsToggle: action.value };
        case TOGGLE_SEARCHBAR_TOGGLE:
            return { ...state, searchbarToggle: !state.searchbarToggle };
        default:
            return state;
    }
}
