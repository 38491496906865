import { connect } from "react-redux";
import { getFaq } from "../../actions/SiteCore/SiteCoreActions";
import FaqPage from "../../components/Faq/FaqPage";
import { IStoreState } from "../../types/IStoreState";

export const mapStateToProps = ({Localization, SiteCore}: IStoreState) => {
    return {
        Faq: SiteCore.Faq,
        Localization: Localization
    };
};

export const mapDispatchToProps = (dispatch: any) => { // tslint:disable-line
    return {
        getFaq: () => dispatch(getFaq())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FaqPage);
