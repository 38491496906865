import React from "react";
import { WrappedComponentProps, injectIntl} from "react-intl";
import {ICatalogsState} from "../../types/ICatalogsState";
import {ILocalizationState} from "../../types/ILocalizationState";
import {ISiteCoreState} from "../../types/ISiteCoreState";
import PhotoBanner from "../PhotoBanner/PhotoBanner";
import Spinner from "../Spinner/Spinner";
import TableWidget, {ITableWidgetRowsProps} from "../TableWidget/TableWidget";
import TextBlock from "../TextBlock/TextBlock";

import "./HomePage.scss";

export interface IHomePageProps extends WrappedComponentProps{
    HomePageData: ISiteCoreState;
    getHomePageContext: () => (dispatch: any) => Promise<void>;
    getHomePageBanner: () => (dispatch: any) => Promise<void>;
    getCatalogs: () => (dispatch: any) => Promise<void>;
    Catalogs: ICatalogsState;
    Localization: ILocalizationState;
}

class HomePage extends React.Component<IHomePageProps, {}> {

    componentDidMount(): void {
        this.props.getHomePageContext();
        this.props.getHomePageBanner();
    }

    componentDidUpdate(prevProps: Readonly<IHomePageProps>, prevState: Readonly<{}>): void {
        if (this.props.Localization.currentLanguage !== prevProps.Localization.currentLanguage) {
            this.props.getHomePageContext();
            this.props.getHomePageBanner();
        }
    }

    renderBanner(HomePageData: ISiteCoreState) {
        if (HomePageData.HomePageBanner && HomePageData.HomePageBanner.Image) {
            return <PhotoBanner AltText={HomePageData.HomePageBanner.ImageAlt!} Url={HomePageData.HomePageBanner.Image}/>
        }

        return <React.Fragment/>
    }

    render() {
        const { HomePageData, Catalogs } = this.props;
        if (HomePageData.isFetching || Catalogs.isFetching) {
            return <Spinner SpinnerAriaLabel="Loading!!!" />;
        }

        if (HomePageData && HomePageData.HomePage) {
            const textblock = HomePageData.HomePage.map(
                (data, i) =>
                    <TextBlock key={i} Title={data.Title ? data.Title: ""} Content={data.Snippet ? data.Snippet : ""} />
            );

            let tableRows: ITableWidgetRowsProps[] = [];
            if (Catalogs.catalogs && Catalogs.catalogs.length > 0) {
                tableRows = Catalogs.catalogs.map((catalog, i) => ({ Identifier: catalog.Identifier, Title: catalog.Title, NumberOfDatasets: catalog.DatasetCount})) as ITableWidgetRowsProps[];
            }

            return (
                <React.Fragment>
                    <h1 className="homeH1">Homepage</h1>
                    {this.renderBanner(HomePageData)}

                    <div className="pagegrid">
                        {textblock}

                        <TableWidget TableRows={tableRows} />
                    </div>
                </React.Fragment>
            );
        }

        return <React.Fragment/>;
    }
}

export default injectIntl(HomePage);
