import {connect} from "react-redux";
import * as localizationActions from "../../actions/Localization/LocalizationActions";
import * as settingsActions from "../../actions/Settings/SettingsActions";
import App from "../../components/App/App";
import { IStoreState } from "../../types/IStoreState";

export const mapStateToProps = (state: IStoreState) => {
    return {
        localization: state.Localization,
        settings: state.Settings
    };
};

export const mapDispatchToProps = (dispatch: any) => { // tslint:disable-line
    return {
        setCurrentLanguage: (language: string) => dispatch(localizationActions.setCurrentLanguage(language)),
        getSettings: () => dispatch(settingsActions.getSettings())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
