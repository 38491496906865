export interface ISettingsState {
    hostSiteCoreODataApiUrl: string,
    hostCcbODataApiUrl: string,
    hostThemesTreeUrl: string,
    zipLocation: string,
    canonicalNlRootUrl: string,
    isFetching?: boolean;
}

export function defaultSettingsState(): ISettingsState {
    return {
        hostSiteCoreODataApiUrl: "https://www-acc.cbs.nl/odata/v1/",
        hostCcbODataApiUrl: "https://datasetsapigateway-dev.workload11.user-np01.k8s.cbsp.nl/",
        hostThemesTreeUrl: "https://opendata.cbs.nl/ODataCatalog/",
        zipLocation: "",
        canonicalNlRootUrl: "https://www.cbs.nl/nl-nl/cijfers/detail",
        isFetching: true
    };
}