import React, { useEffect, useState, useRef, createRef } from "react";
import {connect} from "react-redux";
import {
    IDatasetDimension,
    IDatasetDimensionTreeModel,
    IDatasetProperties
} from "../../actions/CCBDataset/CCBDatasetActions";
import { AbstractStepDefinition } from "./AbstractStepDefinition";
import DimensionSteps from "./DimensionStep";
import {DimensionStepDefinition} from "./DimensionStepDefinition";
import MeasuresStep from "./MeasuresStep";
import { MeasureStepDefinition } from "./MeasureStepDefinition";
import DimensionsWithoutCodeListsStep from "./DimensionsWithoutCodeListsStep";
import { DimensionsWithoutCodeListsStepDefinition } from "./DimensionsWithoutCodeListsStepDefinition";
import PreviewStep from "./PreviewStep";
import { PreviewStepDefinition } from "./PreviewStepDefinition";
import Collapsible from "react-collapsible";
import MediaQuery from "react-responsive";
import "./Wizard.scss";
import { injectIntl, WrappedComponentProps } from "react-intl";
import 'font-awesome/css/font-awesome.min.css';

export interface ISelectionWizardProps {
    dimensions: IDatasetDimension[];
    datasetProperties: IDatasetProperties;
    datasetDimensionTreeModel: IDatasetDimensionTreeModel[];
    isFetching: boolean;
}

export const SelectionWizard: React.FC<ISelectionWizardProps & WrappedComponentProps> = (props) => {
    const [currentStep, setCurrentStep] = useState(-1);
    const [maxStep, setMaxStep] = useState(-1);
   
    const [stepDefinitions, setStepDefinitions] = useState<AbstractStepDefinition[]>([]);

    useEffect(() => {
        _createStepDefinitions();
    }, []);

    const _isPreviewStep = (): boolean => {
        return (maxStep === currentStep);
    };

    const _renderDesktop = (): JSX.Element => {
        return (
            <div className="wizard">
                <div className="tabs">
                    {_renderStepTabs()}
                </div>
                {_renderTabPanes()}
            </div>
        );
    };

    const _renderTabPanes = (): React.ReactNode => {
        return (
            <div className="tab-panes">
                {_renderDesktopSteps()}
            </div>
        );
    };

    const _renderMobile = () => {
        return (
            <div className="wizard">
                <div className="tabs">
                    {_renderMobileSteps()}
                </div>
            </div>
        );
    };

    const _createStepDefinitions = (): void => {
        const { dimensions } = props;
        let index = 1;
        const stepDefinitions = [
        ] as AbstractStepDefinition[];

        const measureTitle = (props.intl)
            ? props.intl.formatMessage({ id: 'dataset.preview.measures', defaultMessage: "Measures" })
            : "Measures";
        stepDefinitions.push(new MeasureStepDefinition(measureTitle, index));
        index++;

        if (dimensions && dimensions.length > 0) {
            dimensions.forEach((e: IDatasetDimension, i) => {
                if (e && e.Title) {
                    if (e.hasCodes) {
                        stepDefinitions.push(new DimensionStepDefinition(e.Title, index, e));
                    } else {
                        stepDefinitions.push(new DimensionsWithoutCodeListsStepDefinition(e.Title, index, e));
                    }
                    index++;
                }
            });
        }

        stepDefinitions.push(new PreviewStepDefinition("Preview", index));

        setMaxStep(stepDefinitions.length);
        setStepDefinitions(stepDefinitions);
        setCurrentStep(stepDefinitions.length);
    }

    const _tabButtonOnClickHandler = (key: number): void => {
        const gotoStep: number = key + 1;
        setCurrentStep(gotoStep);
    }

    const _renderStepTabs = (): React.ReactNode => {
        const stepTabs: JSX.Element[] = [];
        stepDefinitions.forEach((e: AbstractStepDefinition, i: number) => {
            stepTabs.push(
                <li key={i}>
                    <button className={currentStep === e.index ? "active" : ""} key={i} onClick={() => _tabButtonOnClickHandler(i)}>{e.title}</button>
                </li>);
        });
        return <ul> {stepTabs} </ul>;
    }

    const _renderMobileSteps = (): JSX.Element[] => {
        const stepTabs: JSX.Element[] = [];

        const collapsibleButton = (title: string) => {
            return (
                <React.Fragment>
                    <span className="status-icon"><i className="fa fa-plus" /></span>
                    <button className="collapsibleButton">{title}</button>
                </React.Fragment>
            );
        };

        const accordionRefs = useRef([]);

        if (stepDefinitions.length !== accordionRefs.current.length) {
            accordionRefs.current = Array(stepDefinitions.length).fill([]).map((_, i) => accordionRefs.current[i] || createRef());
        }

        const handleCollapsibleTrigger = (accordionPosition: any, e: any) => {
            const currentTargetCollapsible: any = Object.values(accordionRefs.current).find((ref: any) =>
                ref.current.props.accordionPosition === accordionPosition);
            const otherOpenCollapsible: any = Object.values(accordionRefs.current).find((ref: any) =>
                ref.current.props.accordionPosition !== accordionPosition && ref.current.state.isClosed === false);

            if (currentTargetCollapsible.current.state && currentTargetCollapsible.current.state.isClosed) {
                if (otherOpenCollapsible) {
                    otherOpenCollapsible.current.closeCollapsible();
                }

                currentTargetCollapsible.current.openCollapsible();
                setCurrentStep(accordionPosition + 1);

            } else {
                currentTargetCollapsible.current.closeCollapsible();
            }
        };

        const collapsiblePane = (step: AbstractStepDefinition, current: number, i: number) => {
            if (step instanceof MeasureStepDefinition) {
                return <MeasuresStep key={i} currentStep={current} measureData={step} />
            } else if (step instanceof DimensionStepDefinition) {
                return <DimensionSteps key={i} currentStep={current} dimensionData={step as DimensionStepDefinition} />
            } else if (step instanceof DimensionsWithoutCodeListsStepDefinition) {
                return <DimensionsWithoutCodeListsStep key={i} currentStep={current} dimensionsWithoutCodeListsStepDefinition={step} />
            } else if (step instanceof PreviewStepDefinition) {
                return <PreviewStep key={i} currentStep={current} previewStepDefinition={step} isVisible={_isPreviewStep()} />
            }
        };

        stepDefinitions.forEach((step: AbstractStepDefinition, i: number) => {
            stepTabs.push(
                <Collapsible ref={accordionRefs.current[i]} accordionPosition={i} key={i} trigger={collapsibleButton(step.title)} handleTriggerClick={(accordionPosition) => handleCollapsibleTrigger(accordionPosition, this)} triggerTagName="div">
                    {collapsiblePane(step, currentStep, i)}
                </Collapsible>);
        });

        return stepTabs;
    }

    const _renderDesktopSteps = (): JSX.Element[] => {
        const steps: JSX.Element[] = [];

        stepDefinitions.forEach((step: AbstractStepDefinition, i: number) => {
            if (step instanceof MeasureStepDefinition) {
                steps.push(<MeasuresStep key={i} currentStep={currentStep} measureData={step} />);
            } else if (step instanceof DimensionStepDefinition) {
                steps.push(<DimensionSteps key={i} currentStep={currentStep} dimensionData={step} />);
            } else if (step instanceof DimensionsWithoutCodeListsStepDefinition) {
                steps.push(<DimensionsWithoutCodeListsStep key={i} currentStep={currentStep} dimensionsWithoutCodeListsStepDefinition={step} />);
            } else if (step instanceof PreviewStepDefinition) {
                steps.push(<PreviewStep key={i} currentStep={currentStep} previewStepDefinition={step} isVisible={_isPreviewStep()} />);
            }
        });

        return steps;
    };
    
    return (
        <>
            <MediaQuery minWidth={992}>
                {_renderDesktop()}
            </MediaQuery>
            <MediaQuery maxWidth={992}>
                {_renderMobile()}
            </MediaQuery>
        </>
    );

}

export const mapStateToProps = ({CurrentDataset}: any) => {
    return {
        datasetDimensionTreeModel: CurrentDataset.datasetDimensionTreeModel,
        datasetProperties: CurrentDataset.datasetProperties,
        dimensions: CurrentDataset.datasetDimensions,
        isFetching: CurrentDataset.isFetching
    };
};

export default connect(mapStateToProps)(injectIntl(SelectionWizard));
