import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    getDatasetObservations, IAlertMessage,
    IDatasetObservationsResponse,
    IDatasetProperties
} from "../../actions/CCBDataset/CCBDatasetActions";
import { IFilter } from "../../actions/Filters/FilterActions";
import { PreviewStepDefinition } from "./PreviewStepDefinition";
import { IStepProps } from "./Step";
import MediaQuery from "react-responsive";
import { WrappedComponentProps, injectIntl, IntlShape } from "react-intl";
import Alert, { AlertEnum } from "./../Alert/Alert";
import Spinner from "./../Spinner/Spinner";
import DownloadOptions from "./DownloadOptions";

export interface IPreviewStepProps extends IStepProps {
    datasetProperties: IDatasetProperties;
    previewStepDefinition: PreviewStepDefinition;
    filters: Map<string, IFilter>;
    observations: IDatasetObservationsResponse;
    observationsUrl: string;
    observationsCount: number;
    hostCcbODataApiUrl: string;
    getDatasetObservations: (catalog: string, id: string, filters: Map<string, IFilter>, intl: IntlShape) => Promise<void>;
    errorMessage: string | undefined;
    alertMessage: IAlertMessage;
    hasSucceed: boolean;
    isFetching: boolean;
    isVisible: boolean;
}

export const PreviewStep: React.FC<IPreviewStepProps & WrappedComponentProps> = (props) => {

    useEffect(() => {
        const tryGetObservations = (intl: IntlShape): Promise<void> => {
            const { datasetProperties } = props;
            return props.getDatasetObservations(datasetProperties.Catalog, datasetProperties.Identifier, props.filters, intl)
                .then((t) => {
                    if (props.errorMessage) {
                        alert(props.errorMessage);
                    }
                });
        }

        if (props.isVisible) {
            tryGetObservations(props.intl)
        };
    }, [props.isVisible, props.observationsUrl]);


    const _alertMessage = () => {
        if (props.alertMessage) {
            return <Alert Status={props.alertMessage.Type}
                Title={props.alertMessage.Title}
                Description={props.alertMessage.Description}
            />
        }
    }

    const getCsvIntegraalDistributionUrl = () => {
        const { datasetProperties } = props;
        const { Distributions } = datasetProperties;
        const csvDistribution = Distributions.find(e => e.Format === 'csv');
        const tekstCsvIntegraal = props.intl.formatMessage({ id: "preview.csv.integraal", defaultMessage: "volledige CSV" });

        if (csvDistribution) {
            return "<a href='" + csvDistribution.DownloadUrl + "'>" + tekstCsvIntegraal + "</a>";
        }
    };

    const _renderMobile = () => {
        const dataMobile = props.observations;

        if (props.observations.length > 0) {
            return (
                <table className="preview column">
                    <tbody>
                    {
                        dataMobile.map((numList, i) => (
                            <tr key={i}>
                                {
                                    numList.map((num, j) => {
                                        if (j === 0) {
                                            return <th key={j}>{num}</th>;
                                        } else {
                                            return <td key={j}>{num}</td>
                                        }
                                    })
                                }
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            );
        } else { return <React.Fragment />; }
    }

    const _renderDesktop = () => {
        const [headers, ...data] = props.observations;

        if (props.observations.length > 0) {
            return (
                <section className="table-container" tabIndex={0}>
                    <table className="preview row">
                        <thead>
                            <tr>
                                {
                                    headers.map((header, i) => (
                                        <th key={i}>{header}</th>
                                    ))
                                }
                            </tr>
                        </thead>

                        <tbody>
                            {
                                data.map((numList, i) => (
                                    <tr key={i}>
                                        {
                                            numList.map((num, j) =>
                                                <td key={j}>{num}</td>
                                            )
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </section>
            );
        } else { return <React.Fragment />; }
    }

    
    if (props.currentStep === props.previewStepDefinition.index && props.previewStepDefinition instanceof PreviewStepDefinition) {
        //tryGetObservations(props.intl);
        const { datasetProperties } = props;

        if (props.isFetching) {
            return (
                <Spinner SpinnerAriaLabel={props.intl.formatMessage({ id: "spinner.loading.text", defaultMessage: "De data is aan het laden." })} />
            );
        }

        return (
            <React.Fragment>
                {props.hasSucceed &&
                    _alertMessage()
                }

                {!props.hasSucceed &&
                    <Alert
                        Status={AlertEnum.Warning}
                        Title={props.intl.formatMessage({ id: "alert.warning.title", defaultMessage: "Waarschuwing" })}
                        Description={props.intl.formatMessage({
                            id: "preview.niet.succes.response",
                            defaultMessage: "Let op, de CSV download bevat niet alle cellen."
                        },
                        {
                            CSV_Url: getCsvIntegraalDistributionUrl()
                        }
                        )}
                    />
                }

                {props.hasSucceed &&
                    <div className="preview-box">
                        <MediaQuery maxDeviceWidth={992}>
                            {_renderMobile()}
                        </MediaQuery>

                        <MediaQuery minDeviceWidth={992}>
                            {_renderDesktop()}
                        </MediaQuery>
                    </div>
                }

                {props.hasSucceed &&
                    <div className="download-box">

                        <Alert
                            Status={AlertEnum.Info}
                            Title={props.intl.formatMessage({ id: "alert.info.title", defaultMessage: "Informatie" })}
                            Description={props.intl.formatMessage({
                                id: "csv.download.information",
                                defaultMessage: "De CSV download bevat alleen gevulde cellen."
                            })}
                        />

                        <DownloadOptions
                            datasetProperties={datasetProperties}
                            observationsUrl={props.observationsUrl}
                        />
                           
                    </div>
                }
            </React.Fragment>
        );
    } else {
        return <React.Fragment />;
    }
}

export const mapStateToProps = ({ CurrentDataset, Selection, Settings }: any) => {
    return {
        hostCcbODataApiUrl: Settings.hostCcbODataApiUrl,
        filters: Selection.Filters,
        observations: CurrentDataset.observations,
        observationsUrl: CurrentDataset.observationsUrl,
        observationsCount: CurrentDataset.observationsCount,
        datasetProperties: CurrentDataset.datasetProperties,
        errorMessage: CurrentDataset.errorMessage,
        alertMessage: CurrentDataset.alertMessage,
        hasSucceed: CurrentDataset.hasSucceed,
        isFetching: CurrentDataset.isFetching
    };
};

const mapDispatchToProps = (dispatch: any) => { // tslint:disable-line
    return {
        getDatasetObservations: bindActionCreators(getDatasetObservations, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PreviewStep));
