import React from "react";
import { WrappedComponentProps, injectIntl, IntlFormatters } from 'react-intl';
import { Link } from "react-router-dom";
import { IThemesDataResponse, IThemeTreeNode } from "../../actions/ThemesTree/ThemesTreeActions";
import "./ThemesTree.scss";
import { ICatalogResponse, IMetaDataResponse } from "../../actions/CCBDataset/CCBDatasetActions";
import Spinner from "../Spinner/Spinner";
import { Helmet } from "react-helmet-async";
import Alert, { AlertEnum } from "../Alert/Alert";
import { BreadCrumbs } from "../BreadCrumbs/BreadCrumbs";

export interface IMatchParam {
    themenumber: string
}

export interface IThemeTreeProps {
    themeTree?: IThemeTreeNode[];
    catalogs?: ICatalogResponse[];
    isFetchingCatalogs: boolean;
    isFetchingThemes: boolean;
    getThemeTree: (catalog: string, language: string, intl: IntlFormatters) => (dispatch: any) => Promise<void>;
    getCatalogs: () => (dispatch: any) => Promise<void>;
    match: any;
}

export class ThemesTree extends React.Component<IThemeTreeProps & WrappedComponentProps> {
    readonly DEFAULTLANGUAGE = "nl";
    readonly DEFAULTCATALOG = "CBS";
    readonly DEFAULTTHEMESPATH = "/themes/CBS/";
    readonly DEFAULTCBSDETAILSPATH = "/detail/CBS/";

    public componentDidMount(): void {
        if (this.props.catalogs === undefined || this.props.catalogs.length === 0) {
            this.props.getCatalogs();
        }

        if (this.props.themeTree === undefined || this.props.themeTree.length === 0) {
            //todo for different catalogs and languages
            this.props.getThemeTree(this.DEFAULTCATALOG, this.DEFAULTLANGUAGE, this.props.intl);
        }
    }

    private getThemeTree(): IThemeTreeNode[] {
        if (this.props.themeTree)
            return this.props.themeTree;

        return [];
    }

    // Render the themetree layout based on the ThemeNumber or Just je Root nodes when we don't have a number.
    private RenderLayout(themenumber: string | null): JSX.Element | undefined {
        if (themenumber) {
            const parentNode = this.GetParentNodeByNumber(themenumber);
            if (parentNode != null) {
                return this.RenderChildren(parentNode, parentNode.Children);
            }
        }
        else {
            return this.RenderChildren(null, this.getThemeTree());
        }
    }

    // create the list of nodes to display.
    private RenderChildren(parentNode: IThemeTreeNode | null, nodes: IThemeTreeNode[]) {
        if (nodes === undefined)
            return <ul> </ul>;

        return <ul className="list-group">
            {this.RenderParentNode(parentNode)}
            {nodes.map((e, i) => this.RenderNode(e, i))}
        </ul>;
    }

    // Renders a node. This could be a ThemeNode or a TableNode
    private RenderNode(node: any, index: number): JSX.Element | undefined {
        if (node.IsTable)
            return this.RenderTableNode(node, index);

        return this.RenderThemeNode(node, index);
    }

    // render a TableNode or nothing if we don't have any tableInformation 
    // (this is because we are using the themetree from OData3 with data from OData4.Not all datasets are available in odata4)
    private RenderTableNode(node: IThemeTreeNode, index: number): JSX.Element | undefined {
        let nodeData: IMetaDataResponse = node.Data;
        if (node.Data === null)
            return;

        let linkToDatasetDetails = this.DEFAULTCBSDETAILSPATH + nodeData.Identifier;
        return (<li key={index} className="list-group-item table">
            <Link to={linkToDatasetDetails}>
                <i className="fa fa-table fa-fw" aria-label="tabel icoon" role="link"/>
                <span>{nodeData.Title}</span>
            </Link>
        </li>
        );
    }

    // Render a ThemeNode (navigation node)
    private RenderThemeNode(node: any, index: number): JSX.Element {
        var nodeData: IThemesDataResponse = node.Data;
        const link = this.DEFAULTTHEMESPATH + nodeData.Number;
        return (<li key={index} className="list-group-item folder">
            <Link to={link}>
                <span>{nodeData.Title}</span>
            </Link>
            <i className="fa fa-angle-right fa-fw" aria-hidden="true" />

        </li>);
    }

    // Render the first node in the list to navigate back to previouse node or see the text "Thema's" when you are at the root of the Tree.
    private RenderParentNode(parentNode: IThemeTreeNode | null) {
        if (parentNode === null) {
            return (
                <li key={-1} className="previous-item root">
                    <span>{this.props.intl.formatMessage({ id: "themestree.parentnode.title", defaultMessage: "Thema's" })}</span>
                </li>
            );
        }

        return (
            <li key={parentNode.ID} className="previous-item">
                {this.RenderBreadcrumbs(parentNode)}

            </li>
        );
    }

    private RenderBreadcrumbs(parentNode: IThemeTreeNode | null) {
        if (parentNode) {
            let themes = [] as IThemesDataResponse[];

            while (true) {
                themes.push(parentNode?.Data as IThemesDataResponse);
                if (!parentNode?.Parent) {
                    break;
                } else {
                    parentNode = parentNode?.Parent;
                }
            }

            themes.reverse().map((theme, index) => (
                <Link
                    key={theme.Number + index}
                    to={this.DEFAULTTHEMESPATH + theme.Number}
                    className="themestree-breadcrumbs__item">

                    {theme.Title}

                    <i
                        className="fa fa-angle-right fa-fw"
                        aria-hidden="true" />

                </Link>
            ));

            const themesList = themes.reverse().map((theme, index) => ({
                key: index + 1, text: theme.Title, url: this.DEFAULTTHEMESPATH + theme.Number
            }));

            themesList.push({ key: 0, text: this.props.intl.formatMessage({ id: "themestree.parentnode.title", defaultMessage: "Thema's" }), url: this.DEFAULTTHEMESPATH });

            return (<BreadCrumbs open={false} key="1" items={themesList} />)
        }
    }

    private RenderThemetreeHeader(parentNode: IThemeTreeNode, parentNodeData: IThemesDataResponse) {
        var nodeData: IThemesDataResponse = parentNode.Data as IThemesDataResponse;
        let link = this.DEFAULTTHEMESPATH + ((parentNode.Parent === null) ? "" : parentNodeData?.Number);
        return (
            <Link to={link} className="hide-on-large">
                <i className="fa fa-angle-left fa-fw" aria-hidden="true" />
                <span>{nodeData.Title}</span>
            </Link>
        );
    }

    private RenderHeader(): JSX.Element {
        const { catalogs } = this.props;

        if (catalogs) {
            const catalog = catalogs.filter(e => e.Identifier === this.DEFAULTCATALOG)[0];
            return (
                <h1>{catalog.Title}</h1>
            );
        }

        return (<h1>Empty</h1>);
    }

    // find the right ThemeNode for the givin themenumber;
    private GetParentNodeByNumber(number: string): IThemeTreeNode | null {
        let themeTree = this.getThemeTree();
        if (themeTree.length === 0)
            return null;

        let target: IThemeTreeNode[] = [];
        function filterData(data: IThemeTreeNode[], _number: string) {
            data.forEach((node, index) => {
                if (node.Children) { filterData(node.Children, _number); }
                if (node.Number === _number && node.IsTable === false) { target.push(node); }
            });
            return;
        }

        filterData(themeTree, number);
        return target.length > 0 ? target[0] : null;
    }

    private _setPageTitle(title: string | undefined | null) {
        if (title) {
            return (
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            );
        }
    }

    renderExplanatoryNotes = () => {
        const { intl } = this.props;

        return (
            <Alert
                Title={intl.formatMessage({ id: "alert.info.title", defaultMessage: "Informatie" })}
                Description={intl.formatMessage({ id: "themestree.info", defaultMessage: "Op deze pagina vindt u de datasets die het CBS ook via StatLine aanbiedt. Ze zijn ondergebracht in thema�s en subthema�s. Door te klikken op een (sub)thema kunt u door de thema�s bladeren. Het <span class=\"fa fa-table fa-fw\" aria-hidden=\"true\" role=\"img\"></span> icoon geeft aan dat het om een dataset gaat. Wilt u terug, dan  kunt u klikken in het kruimelpad boven de thema�s." })}
                Status={AlertEnum.Info}
            />
        );
    }

    public render() {
        const { themenumber } = this.props.match.params;
        const { isFetchingCatalogs, isFetchingThemes, catalogs } = this.props;

        if (isFetchingThemes || isFetchingCatalogs) {
            return (
                <React.Fragment>
                    <Spinner SpinnerAriaLabel={this.props.intl.formatMessage({ id: "spinner.loading.text", defaultMessage: "De data is aan het laden." })} />
                </React.Fragment>
            );
        }

        const catalog = catalogs?.filter(e => e.Identifier === this.DEFAULTCATALOG)[0];

        return (
            <React.Fragment>
                <div className="page">
                    {this._setPageTitle(catalog?.Title)}
                    {this.RenderHeader()}
                    {this.renderExplanatoryNotes()}
                    <div className="themetree">
                        {this.RenderLayout(themenumber)}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(ThemesTree);