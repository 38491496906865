import React from "react";
import { WrappedComponentProps, injectIntl } from "react-intl";

import "./Alert.scss";

export interface IAlertProps extends WrappedComponentProps {
    Status: AlertEnum;
    Title: string;
    Description: string;
    Closeable?: boolean;
    CloseHandler?: Function;
    QuestionOkHandler?: Function;
    QuestionCancelHandler?: Function;
    QuestionOkButtonText?: string;
    QuestionCancelButtonText?: string;
}

interface IAlertState {
    AlertVisible: boolean
}

export enum AlertEnum {
    Info = "info",
    Question = "question",
    Success = "success",
    Warning = "warning",
    Error = "error"
}

class Alert extends React.Component<IAlertProps & WrappedComponentProps, IAlertState> {

    constructor(props: IAlertProps & WrappedComponentProps) {
        super(props);
        this.state = {
            AlertVisible: true
        };

        this._setAlertVisible = this._setAlertVisible.bind(this);
    }

    private _setAlertVisible(): void {
        this.setState({
            AlertVisible: !this.state.AlertVisible
        });
    }

    private _getAlertIcon() {
        switch (this.props.Status) {
            case AlertEnum.Info:
                return "fa-info-circle";
            case AlertEnum.Question:
                return "fa-question-circle";
            case AlertEnum.Success:
                return "fa-check-circle";
            case AlertEnum.Warning:
                return "fa-exclamation-circle";
            case AlertEnum.Error:
                return "fa-times-circle";
            default:
                return "fa-info-circle";
        }
    }

    private _getAriaLabel() {
        switch (this.props.Status) {
            case AlertEnum.Info:
                return "informatie icoon";
            case AlertEnum.Question:
                return "vraagteken icoon";
            case AlertEnum.Success:
                return "succes icoon";
            case AlertEnum.Warning:
                return "waarschuwing icoon";
            case AlertEnum.Error:
                return "error";
            default:
                return "informatie icoon";
        }
    }

    private _closeButtonClick() {
        this._setAlertVisible();

        if (this.props.CloseHandler)
            this.props.CloseHandler();
    }

    private _okButtonClick() {
        let { QuestionOkHandler } = this.props;
        this._closeButtonClick()

        if (QuestionOkHandler)
            QuestionOkHandler();
    }

    private _cancelButtonClick() {
        let { QuestionCancelHandler } = this.props;
        this._closeButtonClick()

        if (QuestionCancelHandler)
            QuestionCancelHandler();
    }

    private _renderCloseButton() {
        const { Closeable } = this.props;

        if (Closeable) {
            return (
                <button className="close-button" onClick={(e) => this._closeButtonClick()}>
                    <i className="fa fa-times" aria-hidden></i>
                </button>
            );
        }
    }

    private _renderQuestionButtons() {
        const { Status, QuestionOkButtonText, QuestionCancelButtonText } = this.props;

        if (Status === AlertEnum.Question) {
            return (
                <div className="button-container">
                    <button className="button-ok" onClick={(e) => this._okButtonClick()}>
                        {QuestionOkButtonText === undefined ? "Ok" : QuestionOkButtonText}
                    </button>

                    <button className="button-cancel" onClick={(e) => this._cancelButtonClick()}>
                        {QuestionCancelButtonText === undefined ? "Cancel" : QuestionCancelButtonText}
                    </button>
                </div>
            );
        }
    }

    public render() {
        const { Status, Title, Description } = this.props;

        return (
            <React.Fragment>
                <div className={"alert alert-" + Status} hidden={!this.state.AlertVisible} role="alert">
                    <div className="grid-container">
                        <i className={"fa " + this._getAlertIcon() + " alert-icon"} aria-label={this._getAriaLabel()} role="img"></i>
                        <div>
                            <h2> {Title} </h2>
                            <p dangerouslySetInnerHTML={{ __html: Description }}></p>
                            {this._renderQuestionButtons()}
                        </div>
                        {this._renderCloseButton()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(Alert);