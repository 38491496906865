import {
    IThemeTreeNode
} from "../actions/ThemesTree/ThemesTreeActions";

export interface IThemesState {
    themeTree?: IThemeTreeNode[];
    isFetching: boolean;
}

export function defaultThemesState(): IThemesState {
    return {
        themeTree: [],
        isFetching: false
    };
}
