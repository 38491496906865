import {ISiteCoreResponse} from "../actions/SiteCore/SiteCoreActions";

const siteCoreResponses: ISiteCoreResponse[] = [
    {
        CbsType: "3",
        Type: "Page",
        Permalink: "https://www.cbs.nl/oData/v1/Pages('33b9ea18-72d9-4f2b-963d-a45de9e90f27-nl-nl')",
        Snippet: "<p>Banner foto nl</p>",
        ImageAlt: "Vrouw loopt met fiets aan de hand over brug, kijkend naar spoorwegbrug over de Waal.",
        Image: "https://cdn.cbs.nl/images/69364b7275365a5037685746675269576e43497a62773d3d/1200x600.jpg",
        UnderEmbargo: false,
        PublishTimeUnknown: false,
        RegionalClassification: "",
        ReleaseTime: "2019-02-18T11:03:26+01:00",
        Language: "nl-NL",
        SortOrder: 100,
        Url: "https://www.cbs.nl/nl-nl/statline/cbs/homepage/nederland-in-cijfers",
        Title: "Banner foto nl",
        Themes: [],
        UniqueId: "33b9ea18-72d9-4f2b-963d-a45de9e90f27-nl-nl"
    },
    {
        CbsType: "3",
        Type: "Page",
        Permalink: "https://www.cbs.nl/oData/v1/Pages('1397ff4b-e0ff-4f77-8229-ce31f1be6789-nl-nl')",
        Snippet: '<p>Banner foto en</p>',
        ImageAlt: "Vrouw loopt met fiets aan de hand over brug, kijkend naar spoorwegbrug over de Waal.",
        Image: "https://cdn.cbs.nl/images/69364b7275365a5037685746675269576e43497a62773d3d/1200x600.jpg",
        UnderEmbargo: false,
        PublishTimeUnknown: false,
        RegionalClassification: "",
        ReleaseTime: "2019-02-04T12:03:34+01:00",
        Language: "en-GB",
        SortOrder: 200,
        Url: "https://www.cbs.nl/nl-nl/statline/cbs/homepage/statline",
        Title: "Banner foto en",
        Themes: [],
        UniqueId: "1397ff4b-e0ff-4f77-8229-ce31f1be6789-nl-nl"
    }
];

export const siteCoreResponsesEnglishBanner: ISiteCoreResponse[] = siteCoreResponses.filter((e: ISiteCoreResponse) => e.Language === "en-GB");

export const siteCoreResponsesDutchBanner: ISiteCoreResponse[] = siteCoreResponses.filter((e: ISiteCoreResponse) => e.Language === "nl-NL");

export default siteCoreResponses;
