import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCatalogs } from "../../actions/CCBDataset/CCBDatasetActions";
import { getThemeTree } from "../../actions/ThemesTree/ThemesTreeActions";
import ThemesTree from "../../components/ThemesTree/ThemesTree";
import { IStoreState } from "../../types/IStoreState";

export const mapStateToProps = ({ Themes, Catalogs }: IStoreState) => {
    return {
        themeTree: Themes.themeTree,
        catalogs: Catalogs.catalogs,
        isFetchingCatalogs: Catalogs.isFetching,
        isFetchingThemes: Themes.isFetching
    };
};

export const mapDispatchToProps = (dispatch: any) => { // tslint:disable-line
    return {
        getThemeTree: bindActionCreators(getThemeTree, dispatch),
        getCatalogs: bindActionCreators(getCatalogs, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemesTree);
