import React from "react";
import { Link } from "react-router-dom";
import { ICatalogResponse, IMetaDataResponse } from "../../actions/CCBDataset/CCBDatasetActions";
import { WrappedComponentProps, injectIntl } from "react-intl";
import "./DatasetList.scss";
import Accordeon from "../Accordeon/Accordeon";
import Alert, { AlertEnum } from "../Alert/Alert";
import { Helmet } from "react-helmet-async";

export interface ICCBDatasetProps {
    catalogs?: ICatalogResponse[];
    datasets?: IMetaDataResponse[];
    isFetching?: boolean;
    getCatalogs: () => (dispatch: any) => Promise<void>;
    getListOfDatasets: () => (dispatch: any) => Promise<void>;
    match: any;
    searchParams: string;
}

class DatasetsList extends React.Component<ICCBDatasetProps & WrappedComponentProps> {

    public componentDidMount(): void {
        this.props.getCatalogs();
        this.props.getListOfDatasets();
    }

    public datasetComparer(d1: IMetaDataResponse, d2: IMetaDataResponse): number {
        if (d1.Title && d2.Title) {
            return (d1.Title < d2.Title) ? -1 : +1;
        }
        return 0;
    }

    public getDatasetsForCatalog(datasets: IMetaDataResponse[], catalogIdentifier: string, isSelected: boolean): any {
        const selectedCatalog = (isSelected) ? "open" : "closed";

        if (datasets && datasets.length !== 0) {
            const datasetRows = datasets
                .filter(d => d.Catalog === catalogIdentifier)
                .sort(this.datasetComparer)
                .map((dataset, i) =>
                    <li key={dataset.Identifier}>
                        <span>{dataset.Identifier}</span>
                        <Link to={`/detail/${dataset.Catalog}/${dataset.Identifier}`}>{dataset.Title}</Link>
                        <span>{Number(dataset.ObservationCount).toLocaleString()}</span>
                    </li>
                );
            if (datasetRows.length > 0) {
                return (
                    <ul className={`datasetlist ${selectedCatalog}`}>
                        {datasetRows}
                    </ul>);
            }
        }
        return <React.Fragment />;
    }

    private _setPageTitle(title: string | undefined | null)
    {
        if (title)
        {
            return (
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            );
        }

    }

    public render() {
        const { datasets, catalogs, searchParams } = this.props;
        const catalogIdentifier = this.props.match.params.catalog;
        const datasetsListHeader = "Catalogi en Datasets";
        let catalogChoice = <React.Fragment></React.Fragment>;

        let regex: RegExp;
        try {
            regex = new RegExp(searchParams, "i");

        } catch (e) {
            regex = new RegExp("", "i");
        }

        let filteredDatasets =
            searchParams && datasets ?
                datasets.filter(dataset =>
                    dataset.Identifier!.search(regex) > -1 ||
                    dataset.Title!.search(regex) > -1 ||
                    dataset.Description!.search(regex) > -1
                ) : datasets as IMetaDataResponse[];

        if (catalogs && filteredDatasets) {
            const catalogOptions = catalogs.map((catalog, i) =>
                <Accordeon
                    ShouldBeOpen={searchParams ? true : false}
                    key={i}
                    Index={i}
                    Header={catalog.Title}
                    Content={
                        this.getDatasetsForCatalog(
                            filteredDatasets,
                            catalog.Identifier,
                            catalog.Identifier === catalogIdentifier)}
                    ShowTotalCells={ true }
                >
                </Accordeon>
            );
            catalogChoice =
                <React.Fragment>
                    {catalogOptions}
                </React.Fragment>;
        }

        if (catalogs && filteredDatasets && filteredDatasets.length <= 0) {
            return (
                <React.Fragment>
                    <div className="page catalogs-datasets-overview">
                        <Alert
                            Title={this.props.intl.formatMessage({ id: "alert.info.title", defaultMessage: "Informatie" })}
                            Description={this.props.intl.formatMessage({ id: "search.notfound", defaultMessage: "Controleer a.u.b. de spelling van de zoektermen of probeer een andere zoekterm." })} Status={AlertEnum.Info} />
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {this._setPageTitle(datasetsListHeader)}
                <div className="page catalogs-datasets-overview">
                    <h1>{datasetsListHeader}</h1>

                    {catalogChoice}
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(DatasetsList);
