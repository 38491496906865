export const GET_PAGE = "GET_PAGE";
export type GET_PAGE = typeof GET_PAGE;

export const GET_HOMEPAGE = "GET_HOMEPAGE";
export type GET_HOMEPAGE = typeof GET_HOMEPAGE;

export const GET_HOMEPAGE_BANNER = "GET_HOMEPAGE_BANNER";
export type GET_HOMEPAGE_BANNER = typeof GET_HOMEPAGE_BANNER;

export const GET_FAQ = "GET_FAQ";
export type GET_FAQ = typeof GET_FAQ;

export const GET_INFOPAGE = "GET_INFOPAGE";
export type GET_INFOPAGE = typeof GET_INFOPAGE;

export const GET_PRIVACYPAGE = "GET_PRIVACYPAGE";
export type GET_PRIVACYPAGE = typeof GET_PRIVACYPAGE;

export const SET_FETCHING_SITECORE = "SET_FETCHING_SITECORE";
export type SET_FETCHING_SITECORE = typeof SET_FETCHING_SITECORE;

export const MAX_SITECORE_TIMEOUT_MS: number = 1000; // for now 1 second to respond.

export const urlToSitecoreUniqueId: object[] = [
        {id: "gebruikers", uniqueId: "d223752f-b7c4-4763-932b-64000a4635d1"},
        {id: "users", uniqueId: "d223752f-b7c4-4763-932b-64000a4635d1"},
        {id: "benutzer", uniqueId: "d223752f-b7c4-4763-932b-64000a4635d1"},
        {id: "analisten", uniqueId: "d4bd194d-f1e6-4faa-9407-2ac1ee7b3204"},
        {id: "analysts", uniqueId: "d4bd194d-f1e6-4faa-9407-2ac1ee7b3204"},
        {id: "analyst", uniqueId: "d4bd194d-f1e6-4faa-9407-2ac1ee7b3204"},
        {id: "ontwikkelaars", uniqueId: "25d69b80-48ee-46ee-9266-4c03c3584b8d"},
        {id: "developers", uniqueId: "25d69b80-48ee-46ee-9266-4c03c3584b8d"},
        {id: "entwickler", uniqueId: "25d69b80-48ee-46ee-9266-4c03c3584b8d"}
    ];

export const privacyPageSitecoreId: string = "e5edbccb-5b4b-49de-8b92-b2bfa4125995";
