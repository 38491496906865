import {LocalizationActions} from "../../actions/Localization/LocalizationActions";
import {SET_CURRENTLANGUAGE} from "../../actions/Localization/LocalizationConstants";
import {ILocalizationState} from "../../types/ILocalizationState";

export const LocalizationReducer = (state: ILocalizationState, action: LocalizationActions): ILocalizationState => {
    switch (action.type) {
        case SET_CURRENTLANGUAGE:
            return { ...state, currentLanguage: action.language };
        default:
            return state;
    }
};
