import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCatalogs, getListOfDatasets } from "../../actions/CCBDataset/CCBDatasetActions";
import DatasetsList from "../../components/CCBDataset/DatasetsList";

export const mapStateToProps = ({ Catalogs } : any) => {
    return {
        catalogs: Catalogs.catalogs,
        datasets: Catalogs.datasets,
        isFetching: Catalogs.isFetching,
        searchParams: Catalogs.searchParams
    };
};

const mapDispatchToProps = (dispatch: any) => { // tslint:disable-line
    return {
        getCatalogs: bindActionCreators(getCatalogs, dispatch),
        getListOfDatasets: bindActionCreators(getListOfDatasets, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DatasetsList);
