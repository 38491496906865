import * as constants from "./FilterConstants";

export interface IFilter {
    Identifier: string;
    FilteredIdentifiers?: string[];
}

// ACTION DEFINITION
export interface IActionSetFilter {
    filter: IFilter;
    type: constants.SET_FILTER;
}

export interface IActionCleanUpCurrentFilters {
    type: constants.CLEAN_UP_CURRENT_FILTERS;
}

export const setFilter = (filter: IFilter): IActionSetFilter => {
    return {
        filter,
        type: constants.SET_FILTER
    };
};

export const cleanUpCurrentFilters = (): IActionCleanUpCurrentFilters => {
    return {
        type: constants.CLEAN_UP_CURRENT_FILTERS
    };
};

// Union Action Types
export type FilterActions = IActionSetFilter | IActionCleanUpCurrentFilters;
