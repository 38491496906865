// store/session/reducers.ts
import {
    CCBDatasetActions,
} from "../../actions/CCBDataset/CCBDatasetActions";
import {
    GET_DATASET_DIMENSIONS,
    GET_DATASET_DIMENSIONS_TREE_MODEL,
    GET_DATASET_MEASURES_TREE_MODEL,
    GET_DATASET_PROPERTIES,
    GET_METADATA,
    CREATE_FILTERED_OBSERVATIONS_URL,
    GET_DATASET_OBSERVATIONS,
    SET_FETCHING_CURRENTDATASET,
    CLEAN_UP_CURRENTDATASET,
    SET_DATASET_OBSERVATIONS_FAIL_MESSAGE,
    CLEANUP_DATASET_OBSERVATIONS_ERROR_MESSAGES
} from "../../actions/CCBDataset/CCBDatasetConstants";
import {defaultCurrentDatasetState, ICurrentDatasetState} from "../../types/ICurrentDatasetState";

export const CurrentDatasetReducer = (state: ICurrentDatasetState, action: CCBDatasetActions): ICurrentDatasetState => {
    switch (action.type) {
        case GET_METADATA:
            return { ...state, metadata: action.metadata };
        case GET_DATASET_PROPERTIES:
            return { ...state, datasetProperties: action.datasetProperties };
        case GET_DATASET_DIMENSIONS:
            return { ...state, datasetDimensions: action.datasetDimensions };
        case GET_DATASET_DIMENSIONS_TREE_MODEL: {
            return {...state, datasetDimensionTreeModel: state.datasetDimensionTreeModel.concat(action.dimensionTreeModel)};
        }
        case GET_DATASET_MEASURES_TREE_MODEL: {
            return {...state, datasetMeasureTreeModel: action.measureTreeModel};
        }
        case CREATE_FILTERED_OBSERVATIONS_URL: {
            return { ...state, observationsUrl: action.observationsUrl };
        }
        case GET_DATASET_OBSERVATIONS:
            return { ...state, observations: action.observations, observationsCount: action.observationsCount, hasSucceed: action.hasSucceed };
        case SET_FETCHING_CURRENTDATASET:
            return { ...state, isFetching: action.isFetching };
        case CLEAN_UP_CURRENTDATASET: {
            return defaultCurrentDatasetState();
        }
        case SET_DATASET_OBSERVATIONS_FAIL_MESSAGE: {
            return {...state, alertMessage: action.message};
        }
        case CLEANUP_DATASET_OBSERVATIONS_ERROR_MESSAGES: {
            return {...state, alertMessage: undefined};
        }
        default:
            return state;
    }
};
