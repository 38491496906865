import React from "react";
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from "react-router-dom";

import "./TableWidget.scss";

export interface ITableWidgetRowsProps {
    Identifier: string,
    Title: string;
    NumberOfDatasets: number;
}

export interface ITableWidgetProps {
    TableRows: ITableWidgetRowsProps[];
}

class TableWidget extends React.Component<ITableWidgetProps & WrappedComponentProps, {}> {

    public render() {
        const { TableRows } = this.props;
        const tableBody = TableRows.map((row, i) => {
            let url = `/themes/${row.Identifier}`;
            return (
                <tr key={i}>
                    <td className="catalog-name"><Link to={url}>{row.Title}</Link></td>
                    <td>{row.NumberOfDatasets}</td>
                </tr>
            );
        });
        return (
            <div className="tablewidget">
                <table>
                    <caption className="hidden">{this.props.intl.formatMessage({ id: 'tableWidget.caption', defaultMessage: "Catalogi en datasets" })}</caption>
                    <thead>
                        <tr>
                            <th scope="col">{this.props.intl.formatMessage({ id: 'tableWidget.firstHeader', defaultMessage: "Catalogus" })}</th>
                            <th scope="col">{this.props.intl.formatMessage({ id: 'tableWidget.secondHeader', defaultMessage: "Aantal datasets" })}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableBody}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default injectIntl(TableWidget);
