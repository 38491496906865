import {IStoreState} from "../types/IStoreState";
import {CatalogsReducer} from "./Catalogs/CatalogsReducers";
import {CurrentDatasetReducer} from "./CurrentDataset/CurrentDatasetReducers";
import {FiltersReducer} from "./Filters/FiltersReducer";
import {LocalizationReducer} from "./Localization/LocalizationReducers";
import {SiteCoreReducer} from "./SiteCore/SiteCoreReducers";
import {UIReducer} from "./UI/UIReducer";
import { SettingsReducer } from "./Settings/SettingsReducers";
import { ThemesTreeReducer } from "./ThemesTree/ThemesTreeReducers";

export function mainReducer(state: IStoreState, action: any) {
    return {
        Themes: ThemesTreeReducer(state.Themes, action),
        Catalogs: CatalogsReducer(state.Catalogs, action),
        CurrentDataset: CurrentDatasetReducer(state.CurrentDataset, action),
        Localization: LocalizationReducer(state.Localization, action),
        Selection: FiltersReducer(state.Selection, action),
        SiteCore: SiteCoreReducer(state.SiteCore, action),
        UIState: UIReducer(state.UIState, action),
        Settings: SettingsReducer(state.Settings, action)
    };
}
