const exportedObject = {
    "app.title": "Data portal",
    "header.skiplink": "Skip to main content",
    "header.title": "Data portal",
    "header.link.logo": "CBS logo, link to homepage",
    "searchbar.placeholdertext": "Search...",
    "searchbar.buttontext": "Searchbutton",
    "searchbar.input.field.text": "Search inputfield",
    "search.notfound": "Please check the spelling of the search terms, try a different search term or limit yourself to one search term.",
    "search.subject.input": "Search for subject...",
    "search.refine.input": "Refine searchresults...",
    "search.facets.title": "Filters",
    "search.results.title": "results for",
    "search.alert.temporary.title": "Search has not been implemented yet.",
    "search.alert.temporary.description": "This functionality will be added later.",
    "nav.label.main": "Main navigation",
    "nav.link.current": "Current page: ",
    "nav.link.home": "Home",
    "nav.link.datasets": "Datasets",
    "nav.link.audience": "Information for",
    "nav.link.users": "General use",
    "nav.link.analysts": "Data analysts",
    "nav.link.developers": "Developers",
    "nav.link.faq": "Frequently asked questions",
    "motto": "<strong>Facts</strong> that matter",
    "nav.label.footer": "Footer navigation",
    "footer.link.privacy": "Privacy",
    "footer.link.text.cbs": "CBS.nl",
    "footer.link.url.cbs": "https://www.cbs.nl/en-gb",
    "tableWidget.firstHeader": "Catalog",
    "tableWidget.secondHeader": "Amount of datasets",
    "tableWidget.caption": "Catalogs and datasets",
    "dataset.tab.information": "Information",
    "dataset.tab.metadata": "Metadata",
    "dataset.tab.wizard": "Selection",
    "dataset.tab.downloads": "Downloads",
    "dataset.details.identifier": "Dataset identifier",
    "dataset.details.description": "Description",
    "dataset.details.versionNotes": "Version notes",
    "dataset.details.relatedSources": "Relevant links",
    "dataset.details.themes": "Location in the themetree",
    "dataset.details.provenance": "Provenance",
    "dataset.details.frequency": "Frequency",
    "dataset.details.temporalCoverage": "Temporal coverage",
    "dataset.details.versionReason": "Version reason",
    "dataset.details.modified": "Modified",
    "dataset.details.status": "Status",
    "dataset.details.datasetType": "Dataset type",
    "dataset.details.datasetTypeNumeric": "Numeric",
    "dataset.details.datasetTypeMixed": "Mixed (both numerical and textual values)",
    "dataset.details.datasetTypeText": "Text",
    "dataset.details.observationCount": "Observation count",
    "dataset.details.license": "licence",
    "dataset.details.source": "Source",
    "dataset.details.linkToApi": "Link to API",
    "dataset.details.linkToCsvDistribution": "Download complete CSV",
    "dataset.preview.columnSelection": "Record Format",
    "dataset.preview.columnSelection.caption": "Choose dimension for the record",
    "dataset.preview.measure": "Measure",
    "dataset.preview.measures": "Topics",
    "dataset.preview.measures.caption": "Topics (Measures)",
    "dataset.preview.unit": "Unit",
    "dataset.preview.nodata": "No data found",
    "dataset.preview.urltolong": "You have made a CSV download with many items (filters). Your browser may not be able to process it. See the FAQ for more info.",
    "dataset.preview.dimensionsWithoutCodeLists.input": "Begins with...",
    "dataset.preview.dimensionsWithoutCodeLists.add": "Add", 
    "dataset.preview.dimensionsWithoutCodeLists.explanation": "Filter items by one or more initial characters and press +",
    "alert.error.title": "Oh snap, something went wrong!",
    "alert.error.description": "additional information about this error",
    "alert.warning.title": "Warning!",
    "alert.warning.description": "additional information about this warning",
    "alert.info.title": "Heads up!",
    "alert.info.description": "additional information about this alert",
    "alert.description.downloads": "Geen downloads beschikbaar.",
    "alert.germanContentNotAvailable": "This content is currently not available in German only in English",
    "download.button.csv": "Download CSV",
    "download.alert.title": "At this moment, the download option isn't implemented yet.",
    "download.alert.description": "To download data from this dataset, you can use the <a href='{API_Url}'>API</a>. This does require some technical knowledge. Click <a href='/info/analysts'>here</a> for a manual on how to use the API.",
    "download.zip.disclaimer": "Let op de data is mogelijk niet actueel. [TODO english]",
    "download.zip.header.text": "Download volledige dataset als CSV [TODO english]",
    "preview.csv.integraal": "complete CSV",
    "download.button.csv.selectie": "Download CSV selection",
    "download.button.csv.integraal": "Download complete CSV",
    "preview.niet.succes.response": "You have made a CSV download with too many items. Limit the number of items (e.g. with clear filters). See the FAQ for more info.",
    "csv.download.information": "<i role=\"img\" class=\"internal-links__icon\">�</i>De \"Download CSV selectie\" bevat alleen gevulde cellen. <br/> <i role=\"img\" class=\"internal-links__icon\">�</i>De \"Download CSV selectie\" bevat alleen de eerste 100.000 cellen. <br/> <i role=\"img\" class=\"internal-links__icon\">�</i>Download de volledige dataset via \"Download volledige CSV\".",
    "spinner.image.alt": "CBS spinner.",
    "spinner.loading.text": "The data is loading.",
    "datasets.table.header.title": "Title",
    "datasets.table.header.totalcells": "Total cells",
    "themestree.parentnode.title": "Themes",
    "faq.header": "Frequently asked questions",
    "themestree.info": "On this page you will find the datasets that Statistics Netherlands also offers via StatLine. They are organized into themes and sub-themes. By clicking on a (sub) theme you can browse through the themes. The <i aria-label=\"table\" class=\"fa fa-table fa-fw\"></i> icon indicates that it is a dataset. If you want to go back, you can click in the breadcrumbs above the themes.",
    "datasetforcatalog.info": "Op deze pagina vindt u de datasets terug die het CBS niet via StatLine aanbiedt.",
    "themetree.asdDatasetTitle": "CBS aanvullend"
};

export default exportedObject;