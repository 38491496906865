export const GET_METADATA = "GET_METADATA";
export type GET_METADATA = typeof GET_METADATA;

export const GET_DATASET_PROPERTIES = "GET_DATASET_PROPERTIES";
export type GET_DATASET_PROPERTIES = typeof GET_DATASET_PROPERTIES;

export const GET_DATASET_DIMENSIONS = "GET_DATASET_DIMENSIONS";
export type GET_DATASET_DIMENSIONS = typeof GET_DATASET_DIMENSIONS;

export const GET_DATASET_MEASURES_TREE_MODEL = "GET_DATASET_MEASURES_TREE_MODEL";
export type GET_DATASET_MEASURES_TREE_MODEL = typeof GET_DATASET_MEASURES_TREE_MODEL;

export const GET_DATASET_DIMENSIONS_TREE_MODEL = "GET_DATASET_DIMENSIONS_TREE_MODEL";
export type GET_DATASET_DIMENSIONS_TREE_MODEL = typeof GET_DATASET_DIMENSIONS_TREE_MODEL;

export const GET_CATALOGS = "GET_CATALOGS";
export type GET_CATALOGS = typeof GET_CATALOGS;

export const GET_CATALOGCOUNT = "GET_CATALOGCOUNT";
export type GET_CATALOGCOUNT = typeof GET_CATALOGCOUNT;

export const SET_FETCHING_CURRENTDATASET = "SET_FETCHING_CURRENTDATASET";
export type SET_FETCHING_CURRENTDATASET = typeof SET_FETCHING_CURRENTDATASET;

export const SET_SEARCH = "SET_SEARCH";
export type SET_SEARCH = typeof SET_SEARCH;

export const GET_DATASETS = "GET_DATASETS";
export type GET_DATASETS = typeof GET_DATASETS;

export const CREATE_FILTERED_OBSERVATIONS_URL = "CREATE_FILTERED_OBSERVATIONS_URL";
export type CREATE_FILTERED_OBSERVATIONS_URL = typeof CREATE_FILTERED_OBSERVATIONS_URL;

export const GET_DATASET_OBSERVATIONS = "GET_OBSERVATIONS";
export type GET_DATASET_OBSERVATIONS = typeof GET_DATASET_OBSERVATIONS;

export const CLEAN_UP_CURRENTDATASET = "CLEAN_UP_CURRENTDATASET";
export type CLEAN_UP_CURRENTDATASET = typeof CLEAN_UP_CURRENTDATASET;

export const CLEANUP_DATASET_OBSERVATIONS_ERROR_MESSAGES = "CLEANUP_DATASET_OBSERVATIONS_ERROR_MESSAGES";
export type CLEANUP_DATASET_OBSERVATIONS_ERROR_MESSAGES = typeof CLEANUP_DATASET_OBSERVATIONS_ERROR_MESSAGES;

export const SET_DATASET_OBSERVATIONS_FAIL_MESSAGE = "SET_DATASET_OBSERVATIONS_FAIL_MESSAGE";
export type SET_DATASET_OBSERVATIONS_FAIL_MESSAGE = typeof SET_DATASET_OBSERVATIONS_FAIL_MESSAGE;

export const MEASURE_IDENTIFIER: string = "Measure";

export const MAX_PREVIEW_RECORDS: number = 5;

export const MAX_CELLS: number = 10;
export const MAX_URL_LENGTH: number = 1702; // See https://cbs-nl.visualstudio.com/CBS%20Data%20Portal/_wiki/wikis/CBS-Data-Portal.wiki?pagePath=%2FTesten%20Dataportaal%2FURL%20lengte%20beperking%20Browsers&pageId=195&wikiVersion=GBwikiMaster
export const CSV_DOWNLOAD_MAX_CELLS: number = 100000;