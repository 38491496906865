import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { FormEvent } from "react";
import Step, { IStepProps } from "./Step";
import { DimensionsWithoutCodeListsStepDefinition } from "./DimensionsWithoutCodeListsStepDefinition";
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IFilter, IActionSetFilter, setFilter } from "../../actions/Filters/FilterActions";
import Alert, { AlertEnum } from "../Alert/Alert";
import 'font-awesome/css/font-awesome.min.css';

export interface IDimensionsWithoutCodeListsStepProps extends IStepProps {
    dimensionsWithoutCodeListsStepDefinition: DimensionsWithoutCodeListsStepDefinition;
    setFilter: (selection: IFilter) => IActionSetFilter;
    filters: Map<string, IFilter>;
}

export class DimensionsWithoutCodeListsStep extends Step<IDimensionsWithoutCodeListsStepProps & WrappedComponentProps> {
    constructor(props: IDimensionsWithoutCodeListsStepProps & WrappedComponentProps) {
        super(props);
        this.addSelection = this.addSelection.bind(this);
        this.removeSelection = this.removeSelection.bind(this);
    }

    private getCurrentSelection(identifier: string): IFilter {
        const { filters } = this.props;

        const currentSelection = filters.get(identifier);

        if (currentSelection) {
            return currentSelection;
        }

        return {
            FilteredIdentifiers: [],
            Identifier: identifier
        };
    }

    private addToCurrentSelection(newSelection: string) {
        const { setFilter, dimensionsWithoutCodeListsStepDefinition } = this.props;
        if (!newSelection) {
            return;
        }
        let currentSelection = this.getCurrentSelection(dimensionsWithoutCodeListsStepDefinition.dimension.Identifier).FilteredIdentifiers!;
        if (!currentSelection.find(selection => selection === newSelection)) {
            currentSelection.push(newSelection);
            setFilter({
                FilteredIdentifiers: currentSelection,
                Identifier: dimensionsWithoutCodeListsStepDefinition.dimension.Identifier
            });
            this.forceUpdate();
        }
    }

    private removeFromCurrentSelection(oldSelection: string) {
        const { setFilter, dimensionsWithoutCodeListsStepDefinition } = this.props;
        let currentSelection = this.getCurrentSelection(dimensionsWithoutCodeListsStepDefinition.dimension.Identifier).FilteredIdentifiers!;
        if (currentSelection.find(selection => selection === oldSelection)) {
            currentSelection = currentSelection.filter(selection => selection !== oldSelection);
            setFilter({
                FilteredIdentifiers: currentSelection,
                Identifier: dimensionsWithoutCodeListsStepDefinition.dimension.Identifier
            });
            this.forceUpdate();
        }
    }

    addSelection(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const filter: HTMLInputElement = this.refs._filter as HTMLInputElement;
        this.addToCurrentSelection(filter.value);
    }

    removeSelection(event: any) {
        event.preventDefault();
        const value = event.currentTarget.querySelector("span").textContent;
        this.removeFromCurrentSelection(value);
    }

    public render() {
        const { dimensionsWithoutCodeListsStepDefinition } = this.props;
        if (this.props.currentStep === dimensionsWithoutCodeListsStepDefinition.index && dimensionsWithoutCodeListsStepDefinition instanceof DimensionsWithoutCodeListsStepDefinition) {
            const filter = this.getCurrentSelection(dimensionsWithoutCodeListsStepDefinition.dimension.Identifier);
            const selection = (filter && filter.FilteredIdentifiers && filter.FilteredIdentifiers.length > 0)
                ? filter.FilteredIdentifiers
                : [];
            return (
                <div>
                    <form className="searchbar add-zipcode" onSubmit={this.addSelection}>
                        <label htmlFor="zipcode" className="hidden">{this.props.intl.formatMessage({ id: 'dataset.preview.dimensionsWithoutCodeLists.input', defaultMessage: "Begint met..." })}</label>
                        <input type="text" id="zipcode" ref="_filter"
                               placeholder={this.props.intl.formatMessage({ id: 'dataset.preview.dimensionsWithoutCodeLists.input', defaultMessage: "Begint met..." })}
                               onFocus={(e) => {e.target.placeholder = '';}}
                               onBlur={(e) => {e.target.placeholder = this.props.intl.formatMessage({ id: 'dataset.preview.dimensionsWithoutCodeLists.input', defaultMessage: "Begint met..." });}}
                               defaultValue="" />

                        <button type="submit" aria-label={this.props.intl.formatMessage({ id: 'dataset.preview.dimensionsWithoutCodeLists.add', defaultMessage: "Toevoegen" })}>
                            <i className="fa fa-plus"></i>
                        </button>
                    </form>

                    <ul className="tags">
                        {selection.map(value =>
                            <li key={value} onClick= { this.removeSelection }><a href="/#"><span>{value}</span><i className="fa fa-times"></i></a></li>
                        )}
                    </ul>

                    <Alert
                        Status={AlertEnum.Info}
                        Title={this.props.intl.formatMessage({ id: "alert.info.title", defaultMessage: "Informatie" })}
                        Description={this.props.intl.formatMessage({
                            id: 'dataset.preview.dimensionsWithoutCodeLists.explanation',
                            defaultMessage: "uitleg..."
                        })}
                    />
                </div>
            );
        } else {
            return (
                <div>
                </div>
            );
        }
    }
}

export const mapStateToProps = ({ Selection }: any) => {
    return {
        filters: Selection.Filters,
    };
};

export const mapDispatchToProps = (dispatch: any) => { // tslint:disable-line
    return {
        setFilter: bindActionCreators(setFilter, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DimensionsWithoutCodeListsStep));
