import React from "react";
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Link } from "react-router-dom";
import { ILocalizationState } from "../../types/ILocalizationState";
import "./Footer.scss";

export interface IFooterProps {
    setCurrentLanguage: (language: string) => void;
    localization: ILocalizationState;
}

export class Footer extends React.Component<IFooterProps & WrappedComponentProps, {}> {

    public setLanguage = (e: any, lang: string) => {
        const {setCurrentLanguage} = this.props;
        e.preventDefault();
        setCurrentLanguage(lang);
    }

    public render() {
        const now = new Date();
        const currentYear = now.getFullYear();
        let mottoHtml = this.props.intl.formatMessage({ id: "motto", defaultMessage: "Voor wat er <strong>feitelijk</strong> gebeurt" });

        return (
            
            <footer className="footer">
                <div className="box">
                    <div className="grid">
                        <div className="motto" dangerouslySetInnerHTML={{ __html: mottoHtml }}>
                        </div>

                        <nav role="navigation" aria-label="Navigation" aria-labelledby="footermenulabel">
                            <h2 id="footermenulabel" className="hidden">{this.props.intl.formatMessage({ id: "nav.label.footer", defaultMessage: "Footer menu" })}</h2>

                            <ul role="menu">
                                <li role="menuitem">
                                    <Link to="/privacy">{this.props.intl.formatMessage({ id: "footer.link.privacy", defaultMessage: "Privacy" })}</Link>
                                </li>

                                { /*TEMP: COMMENTED OUT TILL WE SUPPORT MULTI LANGUAGE IN CCB*/}
                                {/*<li role="menuitem">*/}
                                {/*    <a href="/#" onClick={(e) => this.setLanguage(e,"nl")}>Nederlands</a>*/}
                                {/*</li>*/}

                                {/*<li role="menuitem">*/}
                                {/*    <a href="/#" onClick={(e) => this.setLanguage(e,"en")}>English</a>*/}
                                {/*</li>*/}

                                {/*<li role="menuitem">*/}
                                {/*    <a href="/#" onClick={(e) => this.setLanguage(e,"de")}>Deutsch</a>*/}
                                {/*</li>*/}

                                <li role="menuitem">
                                    <a href={this.props.intl.formatMessage({ id: "footer.link.url.cbs", defaultMessage: "https://www.cbs.nl" })}>{this.props.intl.formatMessage({ id: 'footer.link.text.cbs', defaultMessage: "CBS.nl" })}</a>
                                </li>
                            </ul>
                        </nav>

                        <div className="copyright">
                            <span>© CBS, {currentYear}</span>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default injectIntl(Footer);
