import {Component} from "react";
import { WrappedComponentProps, injectIntl} from "react-intl";
import Footer from "../../components/Footer/Footer";
import HeaderContainer from "../../containers/Header/HeaderContainer";
import {ILocalizationState} from "../../types/ILocalizationState";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./App.scss";
import { ISettingsState } from "../../types/ISettingsState";
import { Switch } from "react-router-dom";
import MyRoutes from "../../routes/common/common";
import Spinner from "../Spinner/Spinner";

export interface IAppProps extends WrappedComponentProps {
    localization: ILocalizationState;
    settings: ISettingsState;
    setCurrentLanguage: (language: string) => void;
    getSettings: () => (dispatch: any) => Promise<void>;
}

export class App extends Component <IAppProps, { }> {
    constructor(props: IAppProps) {
        super(props);
        this.props.getSettings();
    }

    public render() {
        const { localization, setCurrentLanguage, intl, settings } = this.props;
        if (settings.isFetching === true)
        {
            return (<Spinner SpinnerAriaLabel="Loading settings...." />);
        }
        return (
            <HelmetProvider>
                <div className="app">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{intl.formatMessage({ id: "app.title", defaultMessage: "Data Portal" })}</title>
                    </Helmet>
                    <Helmet htmlAttributes={{ lang: localization.currentLanguage }} />
                    <HeaderContainer />
                    <main id="maincontent" is="main" role="main">
                        <Switch>
                            {MyRoutes}
                        </Switch>
                    </main>
                    <Footer localization={localization} setCurrentLanguage={setCurrentLanguage} />
                </div>
            </HelmetProvider>
        );
    }
}

export default injectIntl(App);
