import React from "react";
import { WrappedComponentProps, injectIntl } from "react-intl";
import {ISiteCorePageResponse} from "../../actions/SiteCore/SiteCoreActions";
import {ILocalizationState} from "../../types/ILocalizationState";

import "./ContentPage.scss";
import { ISiteCoreState } from "../../types/ISiteCoreState";
import Alert, { AlertEnum } from "../Alert/Alert";
import { Helmet } from "react-helmet-async";
import Spinner from "../Spinner/Spinner";

export interface IInfoPageProps extends WrappedComponentProps {
    localization: ILocalizationState;
    InfoPageData?: ISiteCoreState;
    getContentPageContext: (language: string, uniqueId: string) => (dispatch: any) => Promise<void>;
    match: any;
}

export interface IPrivacyPageProps extends WrappedComponentProps {
    PrivacyPageData?: ISiteCoreState;
    getContentPageContext: (language: string, uniqueId: string) => (dispatch: any) => Promise<void>;
}

class ContentPage extends React.Component<IInfoPageProps & IPrivacyPageProps, {}> {

    componentDidMount(): void {
        const uniqueId = this.props.match.params.UniqueId;
        this.props.getContentPageContext(this.props.localization.currentLanguage, uniqueId);
    }

    componentDidUpdate(prevProps: Readonly<IInfoPageProps & IPrivacyPageProps>, prevState: Readonly<{}>) {
        if (this.props.localization.currentLanguage !== prevProps.localization.currentLanguage) {
            const uniqueId = this.props.match.params.UniqueId;
            this.props.getContentPageContext(this.props.localization.currentLanguage, uniqueId);
        }
    }

    messageGermanContentNotAvailable = () => {
        const { localization, intl } = this.props;
        if (localization.currentLanguage === "de") {

            return (
                <Alert
                    Title={intl.formatMessage({ id: "alert.info.title", defaultMessage: "Ter Info" })}
                    Description={intl.formatMessage({ id: "alert.germanContentNotAvailable", defaultMessage: "Ter Info" })}
                    Status={AlertEnum.Info}
                />
            );
        }
    }

    private _setPageTitle(title: string | undefined | null)
    {
        if (title)
        {
            return (
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            );
        }

    }

    renderPageContent(PageContent: ISiteCorePageResponse, isFetching: boolean) {
        if (isFetching) {
            return <Spinner SpinnerAriaLabel="Loading page..." />;
        }

        if (PageContent && PageContent.Body) {
            return (
                <div className="page">
                    {this._setPageTitle(PageContent.Title)}
                    {this.messageGermanContentNotAvailable()}
                    <h1>{PageContent.Title}</h1>
                    <div className="blockcontent" dangerouslySetInnerHTML={{ __html: PageContent.Body }}>
                    </div>
                </div>
            );
        }
        return <React.Fragment/>;
    }

    render() {
        const { PrivacyPageData, InfoPageData } = this.props;

        if (InfoPageData) {
            return this.renderPageContent(InfoPageData.CurrentInfoPage, InfoPageData.isFetching);
        }

        if (PrivacyPageData) {
            return this.renderPageContent(PrivacyPageData.CurrentPrivacyPage, PrivacyPageData.isFetching);
        }

        return <React.Fragment/>;
    }
}

export default injectIntl(ContentPage);
