import { connect } from "react-redux";
import { getInfoPage } from "../../actions/SiteCore/SiteCoreActions";
import ContentPage from "../../components/ContentPage/ContentPage";
import { IStoreState } from "../../types/IStoreState";

export const mapStateToProps = (state: IStoreState) => {
    return {
        InfoPageData: state.SiteCore,
        localization: state.Localization
    };
};

export const mapDispatchToProps = (dispatch: any) => { // tslint:disable-line
    return {
        getContentPageContext: (language: string, uniqueId: string) => dispatch(getInfoPage(language, uniqueId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentPage);
