const exportedObject = {
    "app.title": "Dataportaal",
    "header.skiplink": "Ga direct naar paginainhoud",
    "header.title": "Dataportaal",
    "header.link.logo": "CBS logo, link naar homepage",    
    "searchbar.placeholdertext": "Zoeken...",
    "searchbar.buttontext": "Zoekknop",
    "searchbar.input.field.text": "Zoeken invoerveld",
    "search.notfound": "Controleer a.u.b. de spelling van de zoektermen, probeer een andere zoekterm of beperk u tot één zoekterm.",
    "search.subject.input": "Zoek op onderwerp...",
    "search.refine.input": "Zoekresultaat verfijnen...",
    "search.facets.title": "Filters",
    "search.results.title": "resultaten voor",
    "search.alert.temporary.title": "De zoekfunctie is nog niet geimplemeteerd.",
    "search.alert.temporary.description": "Deze functionaliteit zal in een later stadium worden toegevoegd.",
    "nav.label.main": "Hoofdmenu",
    "nav.link.current": "Huidige pagina: ",
    "nav.link.home": "Home",
    "nav.link.datasets": "Datasets",
    "nav.link.audience": "Informatie voor",
    "nav.link.users": "Algemeen gebruik",
    "nav.link.analysts": "Data-analisten",
    "nav.link.developers": "Ontwikkelaars",
    "nav.link.faq": "Veelgestelde vragen",
    "motto": "Voor wat er <strong>feitelijk</strong> gebeurt",
    "nav.label.footer": "Footer menu",
    "footer.link.privacy": "Privacy",
    "footer.link.text.cbs": "CBS.nl",
    "footer.link.url.cbs": "https://www.cbs.nl",
    "tableWidget.firstHeader": "Catalogus",
    "tableWidget.secondHeader": "Aantal datasets",
    "tableWidget.caption": "Catalogi en datasets",
    "dataset.tab.information": "Informatie",
    "dataset.tab.metadata": "Metadata",
    "dataset.tab.wizard": "Selectie",
    "dataset.tab.downloads": "Downloads",
    "dataset.details.identifier": "Dataset identificatie",
    "dataset.details.description": "Omschrijving",
    "dataset.details.versionNotes": "Extra informatie over deze levering",
    "dataset.details.relatedSources": "Relevante links",
    "dataset.details.themes": "Locatie in de themaboom",
    "dataset.details.provenance": "Bronnen",
    "dataset.details.frequency": "Frequentie",
    "dataset.details.temporalCoverage": "Verslagperiode",
    "dataset.details.versionReason": "Reden levering",
    "dataset.details.modified": "Laatste wijziging",
    "dataset.details.status": "Status",
    "dataset.details.datasetType": "Soort dataset",
    "dataset.details.datasetTypeNumeric": "Numeriek",
    "dataset.details.datasetTypeMixed": "Gemengd (tekstuele en numerieke waardes)",
    "dataset.details.datasetTypeText": "Tekst",
    "dataset.details.observationCount": "Aantal cellen",
    "dataset.details.license": "licentie",
    "dataset.details.source": "bron",
    "dataset.details.linkToApi": "Link naar API",
    "dataset.details.linkToCsvDistribution": "Download volledige CSV",
    "dataset.preview.columnSelection": "Indeling bestand",
    "dataset.preview.columnSelection.caption": "Kies dimensie voor in de regel",
    "dataset.preview.measure": "Onderwerp",
    "dataset.preview.measures": "Onderwerpen",
    "dataset.preview.measures.caption": "Onderwerpen (Measures)",
    "dataset.preview.unit": "Eenheid",
    "dataset.preview.nodata": "Geen data gevonden",
    "dataset.preview.urltolong": "U hebt een CSV download gemaakt met veel items (filters). Het kan zijn dat uw browser deze niet kan verwerken. Zie voor meer info de FAQ.",
    "dataset.preview.dimensionsWithoutCodeLists.input": "Begint met...",
    "dataset.preview.dimensionsWithoutCodeLists.add": "Toevoegen",
    "dataset.preview.dimensionsWithoutCodeLists.explanation": "Filter items op een of meer begintekens en druk op +",
    "alert.error.title": "Oeps, er ging iets mis!",
    "alert.error.description": "aanvullende informatie over de foutmelding",
    "alert.warning.title": "Waarschuwing",
    "alert.warning.description": "aanvullende informatie over de Waarschuwing",
    "alert.info.title": "Informatie",
    "alert.info.description": "aanvullende informatie over de melding",
    "alert.germanContentNotAvailable": "Deze data is momenteel nog niet in het Duits beschikbaar alleen in het Engels.",
    "alert.description.downloads": "Geen downloads beschikbaar.",
    "download.button.csv": "Download CSV",
    "download.button.csv.selectie": "Download CSV selectie",
    "download.button.csv.integraal": "Download volledige CSV",
    "download.alert.title": "Helaas is het nog niet mogelijk om de geselecteerde data te downloaden.",
    "download.alert.description": "Om toch de data van deze tabel te downloaden kunt u gebruik maken van de <a href='{API_Url}'>API</a>. Dit vereist wel enige technische kennis. Voor een handleiding over het gebruik klikt u <a href='/info/analisten'>hier</a>.",
    "download.zip.disclaimer": "Let op de data is mogelijk niet actueel.",
    "download.zip.header.text": "Download volledige dataset als CSV",
    "preview.csv.integraal": "volledige CSV",
    "preview.niet.succes.response": "Mogelijk hebt u een CSV download gemaakt met teveel items. Beperk het aantal items (b.v. met wis filters) of download de {CSV_Url}. Zie voor meer info de “Veelgestelde vragen”.",
    "csv.download.information": "<i role=\"img\" class=\"internal-links__icon\">—</i>De \"Download CSV selectie\" bevat alleen gevulde cellen. <br/> <i role=\"img\" class=\"internal-links__icon\">—</i>De \"Download CSV selectie\" bevat alleen de eerste 100.000 cellen. <br/> <i role=\"img\" class=\"internal-links__icon\">—</i>Download de volledige dataset via \"Download volledige CSV\".",
    "spinner.image.alt": "CBS spinner.",
    "spinner.loading.text": "De data is aan het laden.",
    "datasets.table.header.title": "Titel",
    "datasets.table.header.totalcells": "Aantal cellen",
    "themestree.parentnode.title": "Thema's",
    "faq.header": "Veelgestelde vragen",
    "themestree.info": "Op deze pagina vindt u de datasets die het CBS ook via StatLine aanbiedt. Ze zijn ondergebracht in thema’s en subthema’s. Door te klikken op een (sub)thema kunt u door de thema’s bladeren. Het <i aria-label=\"tabel\" class=\"fa fa-table fa-fw\" role=\"img\"></i> icoon geeft aan dat het om een dataset gaat. Wilt u terug, dan  kunt u klikken in het kruimelpad boven de thema’s.",
    "datasetforcatalog.info": "Op deze pagina vindt u de datasets terug die het CBS niet via StatLine aanbiedt.",
    "themetree.asdDatasetTitle": "CBS aanvullend"
};


export default exportedObject;