import axios from "axios";
import React from "react";
import { Helmet } from "react-helmet-async";
import { injectIntl, IntlFormatters, WrappedComponentProps } from "react-intl";
import { Link } from "react-router-dom";
import {
    IDatasetDimension, IDatasetDimensionTreeModel, IDatasetMeasureTreeModel,
    IDatasetProperties, IDistribution, IMetaDataResponse
} from "../../actions/CCBDataset/CCBDatasetActions";
import { IThemeTreeNode } from "../../actions/ThemesTree/ThemesTreeActions";
import { IStoreState } from "../../types/IStoreState";
import Alert, { AlertEnum } from "../Alert/Alert";
import SelectionWizard from "../Wizard/SelectionWizard";
import "./DatasetDisplay.scss";

export interface ICCBDatasetProps {
    baseUrl: string;
    canonicalNlRootUrl: string;
    zipLocation: string;
    datasetProperties?: IDatasetProperties;
    datasetDimensions: IDatasetDimension[];
    datasetMeasureTreeModal: IDatasetMeasureTreeModel;
    datasetDimensionTreeModal: IDatasetDimensionTreeModel[];
    isFetching?: boolean;
    isFetchingDimensions?: boolean;
    getDatasetProperties: (catalog: string, id: string) => (dispatch: any) => Promise<void>;
    getDatasetDimensionsTreeModal: (catalog: string, id: string) => (dispatch: any, getState: () => IStoreState) => void;
    getDatasetMeasureTreeModal: (catalog: string, id: string) => (dispatch: any, getState: () => IStoreState) => void;
    cleanUpCurrentDataset: () => void;
    cleanUpCurrentFilters: () => void;
    match: any;
    getThemeTree: (catalog: string, language: string, intl: IntlFormatters) => (dispatch: any) => Promise<void>;
    themeTree?: IThemeTreeNode[];
    isFetchingThemes: boolean;
}

export enum AriaLabelIconEnum {
    External,
    Internal
}

class DatasetDisplay extends React.Component<ICCBDatasetProps & WrappedComponentProps, any> {
    readonly DEFAULTCATALOG = "CBS";

    constructor(props: ICCBDatasetProps & WrappedComponentProps) {
        super(props);
        this.state = {
            activeTab: "tabpageInfo"
        };
    }

    public componentDidMount(): void {
        const { catalog, identifier } = this.props.match.params;
        this.props.cleanUpCurrentDataset();
        this.props.cleanUpCurrentFilters();
        this.props.getDatasetProperties(catalog, identifier);
        this.props.getDatasetDimensionsTreeModal(catalog, identifier);
        this.props.getDatasetMeasureTreeModal(catalog, identifier);
        this.props.getThemeTree(catalog, "nl", this.props.intl);
    }

    public createDistributionUrls = (): (JSX.Element | undefined) =>
    {
        const { datasetProperties } = this.props;

        if (!datasetProperties)
            return <React.Fragment />;

        const { Distributions } = datasetProperties;

        if (!Distributions || Distributions.length <= 0)
            return <React.Fragment />;

        var dataBlock = (distribution: IDistribution, i: number): JSX.Element => {
            const fileTypeCsv = 'csv';
            const fileTypeOdata = 'odata';

            if (distribution && distribution.MediaType.toLowerCase() === fileTypeOdata.toLowerCase()) {
                return (
                    <div key={i} className="datablock">
                        <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.linkToApi", defaultMessage: "Link naar API" })}</p>
                        <p className="value">
                            <i
                                role="img"
                                className="fa fa-external-link pr-2 external-links__icon"
                                aria-label={this._getAriaLabelForIcon(AriaLabelIconEnum.External)}
                            ></i>
                            <a href={distribution.DownloadUrl}>{distribution.DownloadUrl}</a>
                        </p>
                    </div>
                );
            }
            else if (distribution && distribution.MediaType.toLowerCase() === fileTypeCsv.toLowerCase())
            {
                return (
                    <div key={i} className="datablock">
                        <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.linkToCsvDistribution", defaultMessage: "Download volledige CSV" })}</p>
                        <p className="value">
                            <i
                                role="img"
                                className="fa fa-external-link pr-2 external-links__icon"
                                aria-label={this._getAriaLabelForIcon(AriaLabelIconEnum.External)}
                            ></i>
                            <a href={distribution.DownloadUrl}>{distribution.DownloadUrl}</a>
                        </p>
                    </div>
                );
            }
            return <React.Fragment />;
        };

        var distributionsHtml = Distributions.map((distribution: IDistribution, i) => {
            return dataBlock(distribution, i);
        });

        return (
            <React.Fragment> {distributionsHtml} </React.Fragment>
        );
    }

    private _setPageTitle(title: string | undefined | null) {
        if (title) {
            return (
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            );
        }
    }

    private _setCanonical(catalog: string | undefined | null, identifier: string | undefined | null) {
        if (catalog && catalog === this.DEFAULTCATALOG && identifier) {
            let canonicalNlRootUrl = this.props.canonicalNlRootUrl;
            let canonicalUrl = `${canonicalNlRootUrl}/${identifier}`;
            return (
                <Helmet>
                    <link rel="canonical" href={canonicalUrl} />
                </Helmet>
            );
        }
    }

    private setActiveTab = (id: string) => () => {
        this.setState({ activeTab: id });
    };

    public render() {
        const { datasetProperties, datasetDimensions, themeTree } = this.props;
        const { catalog, identifier } = this.props.match.params;
        const odataDistributionFormat = 'odata';

        if ((datasetProperties && Object.keys(datasetProperties).length !== 0)
            && (datasetDimensions && Object.keys(datasetDimensions).length !== 0)) {
            let provenanceLinks = [<React.Fragment></React.Fragment>];
            if (datasetProperties.Provenance) {
                provenanceLinks = datasetProperties.Provenance.map((item, key) =>
                    <li key={key} className="external-links">
                        <i
                            role="img"
                            className="fa fa-external-link pr-2 external-links__icon"
                            aria-label={this._getAriaLabelForIcon(AriaLabelIconEnum.External)}
                        ></i>
                        <a href={item.Url}>{item.Title}</a>
                    </li>
                );
            }

            let datasetType = datasetProperties.DatasetType;
            let messageId = `dataset.details.datasetType${datasetType}`;
            let datasetTypeLabel = this.props.intl.formatMessage({ id: messageId, defaultMessage: datasetType });

            let relatedSourcesLinks = [<React.Fragment></React.Fragment>];
            if (datasetProperties.RelatedSources) {
                relatedSourcesLinks = datasetProperties.RelatedSources.map((item, key) =>
                    <li key={key} className="external-links">
                        <i
                            role="img"
                            className="fa fa-external-link pr-2 external-links__icon"
                            aria-label={this._getAriaLabelForIcon(AriaLabelIconEnum.External)}
                        ></i>
                        <a key={key} href={item.Url}>{item.Title}</a>
                    </li>
                );
            }

            return (
                <>
                    {this._setPageTitle(datasetProperties.Title)}
                    {this._setCanonical(catalog, identifier)}
                    <div className="page dataset-details">
                        <h1>{datasetProperties.Title}</h1>
                        <p>{this.props.intl.formatMessage({ id: "dataset.details.modified", defaultMessage: "Laatste wijziging" })}: {this.props.intl.formatDate(datasetProperties.Modified)}</p>

                        <div className="tablabels" role="tablist" data-target="tabpages">
                            <button className={this.state.activeTab === "tabpageInfo" ? "tab active" : "tab"} role="tab" aria-selected={this.state.activeTab === "tabpageInfo"} data-toggle="tab" onClick={this.setActiveTab("tabpageInfo")}>{this.props.intl.formatMessage({ id: 'dataset.tab.information', defaultMessage: "Informatie" })}</button>
                            <button className={this.state.activeTab === "tabpageMetaData" ? "tab active" : "tab"} role="tab" aria-selected={this.state.activeTab === "tabpageMetaData"} data-toggle="tab" onClick={this.setActiveTab("tabpageMetaData")}>{this.props.intl.formatMessage({ id: 'dataset.tab.metadata', defaultMessage: "Metadata" })}</button>
                            {datasetProperties.Distributions && datasetProperties.Distributions.some(e => e.Format.toLowerCase() === odataDistributionFormat.toLowerCase()) &&
                                <button className={this.state.activeTab === "tabpageWizard" ? "tab active" : "tab"} role="tab" aria-selected={this.state.activeTab === "tabpageWizard"} data-toggle="tab" onClick={this.setActiveTab("tabpageWizard")}>{this.props.intl.formatMessage({ id: 'dataset.tab.wizard', defaultMessage: "Selectie" })}</button>
                            }
                            <button
                                className={this.state.activeTab === "tabpageDownloads" ? "tab active" : "tab"}
                                role="tab" aria-selected={this.state.activeTab === "tabpageDownloads"}
                                data-toggle="tab"
                                onClick={() => {
                                    this.CheckIfZipExists(`${datasetProperties.Identifier}`)
                                        .finally(this.setActiveTab("tabpageDownloads"))
                                }}
                            >

                                <div>
                                    {this.props.intl.formatMessage({ id: 'dataset.tab.downloads', defaultMessage: "Downloads" })}
                                </div>

                                <div aria-label="Beta versie" className="tablabels__beta-tag">
                                    BETA
                                </div>

                            </button>
                        </div>

                        <div id="tabpages" ref="tabpages" className="tabpages">
                            <div id="tabpageInfo" ref="tabpageInfo" className={this.state.activeTab === "tabpageInfo" ? "tabpage active" : "tabpage"} role="tabpanel">
                                {this.RenderThemes(themeTree, identifier)}

                                {datasetProperties.Identifier &&
                                    <div className="datablock">
                                        <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.identifier", defaultMessage: "StatLine nummer" })}</p>
                                        <p className="value">{datasetProperties.Identifier}</p>
                                    </div>
                                }

                                { this.createDistributionUrls() }
                                                                
                                {datasetProperties.Description &&
                                    <div className="datablock">
                                        <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.description", defaultMessage: "Omschrijving" })}</p>
                                        <p className="value description">{datasetProperties.Description}</p>
                                    </div>
                                }

                                {datasetProperties.VersionNotes &&
                                    <div className="datablock">
                                        <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.versionNotes", defaultMessage: "Extra informatie over deze levering" })}</p>
                                        <p className="value">{datasetProperties.VersionNotes}</p>
                                    </div>
                                }

                                {(relatedSourcesLinks && relatedSourcesLinks.length > 0) &&
                                    <div className="datablock">
                                        <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.relatedSources", defaultMessage: "Relevante links" })}</p>
                                        <ul className="value">{relatedSourcesLinks}</ul>
                                    </div>
                                }

                                {(provenanceLinks && provenanceLinks.length > 0) &&
                                    <div className="datablock">
                                        <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.provenance", defaultMessage: "Bronnen" })}</p>
                                        <ul className="value">{provenanceLinks}</ul>
                                    </div>
                                }
                                
                            </div>

                            <div id="tabpageMetaData" ref="tabpageMetaData" className={this.state.activeTab === "tabpageMetaData" ? "tabpage active" : "tabpage"} role="tabpanel">
                                {datasetProperties.Frequency &&
                                    <div className="datablock">
                                        <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.frequency", defaultMessage: "Frequentie" })}</p>
                                        <p className="value">{datasetProperties.Frequency}</p>
                                    </div>
                                }

                                {datasetProperties.TemporalCoverage &&
                                    <div className="datablock">
                                        <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.temporalCoverage", defaultMessage: "Verslagperiode" })}</p>
                                        <p className="value">{datasetProperties.TemporalCoverage}</p>
                                    </div>
                                }

                                {datasetProperties.VersionReason &&
                                    <div className="datablock">
                                        <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.versionReason", defaultMessage: "Reden levering" })}</p>
                                        <p className="value">{datasetProperties.VersionReason}</p>
                                    </div>
                                }

                                {datasetProperties.Modified &&
                                    <div className="datablock">
                                        <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.modified", defaultMessage: "Laatste wijziging" })}</p>
                                        <p className="value">{this.props.intl.formatDate(datasetProperties.Modified)}</p>
                                    </div>
                                }

                                {datasetProperties.Status &&
                                    <div className="datablock">
                                        <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.status", defaultMessage: "Status" })}</p>
                                        <p className="value">{datasetProperties.Status}</p>
                                    </div>
                                }

                                {datasetProperties.DatasetType &&
                                    <div className="datablock">
                                        <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.datasetType", defaultMessage: "Soort dataset" })}</p>
                                        <p className="value">{datasetTypeLabel}</p>
                                    </div>
                                }

                                {datasetProperties.ObservationCount &&
                                    <div className="datablock">
                                        <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.observationCount", defaultMessage: "Aantal cellen" })}</p>
                                        <p className="value">{datasetProperties.ObservationCount}</p>
                                    </div>
                                }

                                {datasetProperties.License &&
                                    <div className="datablock">
                                        <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.license", defaultMessage: "Licentie" })}</p>
                                        <p className="value">
                                            <i
                                                role="img"
                                                className="fa fa-external-link pr-2 external-links__icon"
                                                aria-label={this._getAriaLabelForIcon(AriaLabelIconEnum.External)}
                                            ></i>
                                            <a href={datasetProperties.License}>{datasetProperties.License}</a>
                                        </p>
                                    </div>
                                }

                                {datasetProperties.Source &&
                                    <div className="datablock">
                                        <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.source", defaultMessage: "Bron" })}</p>
                                        <p className="value">{datasetProperties.Source}</p>
                                    </div>
                                }
                            </div>

                            {datasetProperties.Distributions && datasetProperties.Distributions.some(e => e.Format.toLowerCase() === odataDistributionFormat.toLowerCase()) &&
                                <div id="tabpageWizard" ref="tabpageWizard" className={this.state.activeTab === "tabpageWizard" ? "tabpage active" : "tabpage"} role="tabpanel">
                                    <div className="datablock">
                                        <SelectionWizard />
                                    </div>
                                </div>
                            }

                            <div
                                id="tabpageDownloads"
                                ref="tabpageDownloads"
                                className={this.state.activeTab === "tabpageDownloads" ? "tabpage active" : "tabpage"}
                                role="tabpanel"
                            >
                                <div className="datablock">
                                    <div id="datablock-zip" className="datablock">
                                        <ul className="value">
                                            <li className="external-links">
                                                {this.state.csvFileExists ? this.GetDownloadsTabContent() : this.ShowMessageZipNotFound()}
                                            </li>
                                        </ul>
                                        {(!this.IsZipFeedbackRendered && this.state.csvFileExists) && this.AppendFeedbackSection()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        return <React.Fragment />;
    }

    private RenderThemes = (themeTree: IThemeTreeNode[] | undefined, identifier: string) => {
        if (!themeTree || themeTree.length === 0 || !identifier) {
            return (<React.Fragment></React.Fragment>)
        }

        let treesByIdentifier = this.GetDatasetNodesByIdentifier(themeTree, identifier);
        let content = treesByIdentifier?.map(tree => {
            return (this.RenderThemesContent(this.GetThemes(tree)!, tree))
        });

        return (
            <div className="datablock">
                <p className="property">{this.props.intl.formatMessage({ id: "dataset.details.themes", defaultMessage: "Locatie in de themaboom" })}</p>
                <ul className="value">
                    {content}
                </ul>
            </div>
        )
    }

    private IsZipFeedbackRendered = false;

    private AppendFeedbackSection = () => {
        var element = document.getElementById('getfeedback-in-app-widget');
        let html = element?.innerHTML as string;
        document.getElementById('datablock-zip')?.insertAdjacentHTML("afterend", html);
        this.IsZipFeedbackRendered = true;
    }

    private CheckIfZipExists = (datasetIdentifier: string) => {
        const { catalog } = this.props.match.params;
        return axios.head(`${this.props.zipLocation}${catalog}/nl/${datasetIdentifier}`)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ csvFileExists: true });
                } else {
                    this.setState({ csvFileExists: false });
                }
            })
            .catch(error => {
                this.setState({ csvFileExists: false });
            });
    }

    private GetDownloadsTabContent() {
        const { datasetProperties } = this.props;
        const { catalog } = this.props.match.params;

        if (!datasetProperties) {
            return (<></>)
        }

        return (
            <>
                <p className="property">
                    {
                        this.props.intl.formatMessage({
                            id: "download.zip.header.text",
                            defaultMessage: "Download volledige dataset als CSV"
                        })
                    }
                </p>
                <i
                    role="img"
                    className="fa fa-download pr-2 external-links__icon"
                    aria-label={this._getAriaLabelForIcon(AriaLabelIconEnum.External)}
                ></i>
                <a href={`${this.props.zipLocation}${catalog}/nl/${datasetProperties.Identifier}`} download>{`${datasetProperties.Identifier}`}</a>
                <p><small>{this.props.intl.formatMessage({ id: "download.zip.disclaimer", defaultMessage: "Let op de data is mogelijk niet actueel." })}</small></p>
            </>
        )
    }

    private ShowMessageZipNotFound() {
        return (
            <Alert
                Title={this.props.intl.formatMessage({ id: "alert.info.title", defaultMessage: "Informatie" })}
                Description={this.props.intl.formatMessage({ id: "alert.description.downloads", defaultMessage: "Geen downloads beschikbaar." })}
                Status={AlertEnum.Info}
            />
        )
    }

    private RenderThemesContent = (nodes: IThemeTreeNode[], node: IThemeTreeNode) => {
        let content = nodes.reverse().map((node, index) => (
            <React.Fragment key={node.Number!}>
                {index === 0 && <i role="img" className="internal-links__icon"
                    aria-label={this._getAriaLabelForIcon(AriaLabelIconEnum.Internal)}
                >&mdash;</i>}
                <Link to={"/themes/CBS/" + node.Number}>
                    <span>{node.Data?.Title}</span>
                </Link>
                <span>{index < (nodes.length - 1) && " / "}</span>
            </React.Fragment>
        ));

        return (
            <li key={"themetree_" + node.Number}>
                {content}
                <br />
            </li>
        )
    }

    private GetThemes = (node: IThemeTreeNode, newArray: IThemeTreeNode[] = []) => {
        if (!node.Parent) {
            return;
        }

        if (node.Parent) {
            newArray.push(node.Parent);
            this.GetThemes(node.Parent, newArray);
        }

        return newArray;
    }

    private GetDatasetNodesByIdentifier(themeTree: IThemeTreeNode[], datasetId: string): IThemeTreeNode[] | null {
        if (themeTree.length === 0)
            return null;

        let target: IThemeTreeNode[] = [];
        function filterData(data: IThemeTreeNode[], _number: string) {
            data.forEach((node, index) => {
                if (node.Children) {
                    filterData(node.Children, _number);
                }

                let nodeData: IMetaDataResponse = node.Data;

                if (nodeData && nodeData.Identifier === _number && node.IsTable === true) {
                    target.push(node);
                }
            });

            return;
        }

        filterData(themeTree, datasetId);

        return target;
    }

    private _getAriaLabelForIcon(enumVALUE: any) {
        const INTERNAL = "interne url icoon";
        const EXTERNAL = "externe url icoon";
        switch (enumVALUE) {
            case AriaLabelIconEnum.Internal:
                return INTERNAL;
            case AriaLabelIconEnum.External:
                return EXTERNAL;
            default:
                return INTERNAL;
        }
    }
}

export default injectIntl(DatasetDisplay);