import {ISiteCorePageResponse, ISiteCoreResponse} from "../actions/SiteCore/SiteCoreActions";

export interface ISiteCoreState {
    CurrentInfoPage: ISiteCorePageResponse;
    CurrentPrivacyPage: ISiteCorePageResponse;
    Faq: ISiteCorePageResponse[];
    HomePage: ISiteCoreResponse[];
    HomePageBanner: ISiteCoreResponse;
    isFetching: boolean;
}

export function defaultSiteCoreState(): ISiteCoreState {
    return {
        CurrentInfoPage: {},
        CurrentPrivacyPage: {},
        Faq: [],
        HomePage: [],
        HomePageBanner: {},
        isFetching: false,
    };
}
