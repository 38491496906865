import * as constants from "./UIConstants";

export interface IToggleNavToggle {
    type: constants.TOGGLE_NAVTOGGLE;
}

export interface IToggleInfoSubnav {
    type: constants.TOGGLE_INFOSUBNAV;
}

export interface IChangeInfoSubnav {
    type: constants.CHANGE_INFOSUBNAV;
    value: boolean;
}

export interface IToggleDatasetsSubnav {
    type: constants.TOGGLE_DATASETSSUBNAV;
}

export interface IChangeDatasetsSubnav {
    type: constants.CHANGE_DATASETSSUBNAV;
    value: boolean;
}

export interface IToggleSearchbarToggle {
    type: constants.TOGGLE_SEARCHBAR_TOGGLE;
}

export type UIActions = IToggleNavToggle | IToggleInfoSubnav | IChangeInfoSubnav | IToggleDatasetsSubnav | IChangeDatasetsSubnav | IToggleSearchbarToggle;

export function ToggleNavToggle(): IToggleNavToggle {
    return {
        type: constants.TOGGLE_NAVTOGGLE
    };
}

export function ToggleInfoSubnav(): IToggleInfoSubnav {
    return {
        type: constants.TOGGLE_INFOSUBNAV
    };
}

export function ChangeInfoSubnav(value: boolean): IChangeInfoSubnav {
    return {
        type: constants.CHANGE_INFOSUBNAV,
        value
    };
}

export function ToggleDatasetsSubnav(): IToggleDatasetsSubnav {
    return {
        type: constants.TOGGLE_DATASETSSUBNAV
    };
}

export function ChangeDatasetsSubnav(value: boolean): IChangeDatasetsSubnav {
    return {
        type: constants.CHANGE_DATASETSSUBNAV,
        value
    };
}

export function ToggleSearchbarToggle(): IToggleSearchbarToggle {
    return {
        type: constants.TOGGLE_SEARCHBAR_TOGGLE
    };
}
