import { connect } from "react-redux";
import * as localizationActions from "../../actions/Localization/LocalizationActions";
import * as sitecoreActions from "../../actions/SiteCore/SiteCoreActions";
import * as ccbDatasetsActions from "../../actions/CCBDataset/CCBDatasetActions";
import * as uiActions from "../../actions/UI/UIActions";
import Header from "../../components/Header/Header";
import { IStoreState } from "../../types/IStoreState";
import { getCatalogs } from "../../actions/CCBDataset/CCBDatasetActions";

export function mapStateToProps({ UIState, Localization, Catalogs }: IStoreState) {
    return {
        localization: Localization,
        uiState: UIState,
        CatalogsState: Catalogs
    };
}

export function mapDispatchToProps(dispatch: any) {
    return {
        getCatalogs: () => dispatch(getCatalogs()),
        toggleNavToggle: () => dispatch(uiActions.ToggleNavToggle()),
        toggleInfoSubnav: () => dispatch(uiActions.ToggleInfoSubnav()),
        changeInfoSubNav: (value: boolean) => dispatch(uiActions.ChangeInfoSubnav(value)),
        toggleDatasetsSubnav: () => dispatch(uiActions.ToggleDatasetsSubnav()),
        changeDatasetsSubNav: (value: boolean) => dispatch(uiActions.ChangeDatasetsSubnav(value)),
        toggleSearchbarToggle: () => dispatch(uiActions.ToggleSearchbarToggle()),
        setCurrentLanguage: (language: string) => dispatch(localizationActions.setCurrentLanguage(language)),
        getContentPageContext: (language: string, uniqueId: string) => dispatch(sitecoreActions.getInfoPage(language, uniqueId)),
        actionSetSearch: (searchParam: string) => dispatch(ccbDatasetsActions.setSearch(searchParam))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
