import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import {Provider} from "react-redux";
import "./index.scss";
import "./style.scss";
import RootContainer from "./containers/RootContainer/RootContainer";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import reportWebVitals from './reportWebVitals';

const container = document.getElementById("root") as HTMLElement
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <RootContainer />
        </BrowserRouter>
    </Provider>
);

reportWebVitals();