// store/session/reducers.ts
import {
    CCBDatasetActions,
} from "../../actions/CCBDataset/CCBDatasetActions";
import {
    GET_CATALOGCOUNT,
    GET_CATALOGS,
    GET_DATASETS,
    SET_FETCHING_CURRENTDATASET,
    SET_SEARCH,
} from "../../actions/CCBDataset/CCBDatasetConstants";
import { ICatalogsState } from "../../types/ICatalogsState";

export const CatalogsReducer = (state: ICatalogsState, action: CCBDatasetActions): ICatalogsState => {
    switch (action.type) {
        case SET_SEARCH:
            return { ...state, searchParams: action.searchParams }
        case GET_DATASETS:
            return { ...state, datasets: action.datasets };
        case SET_FETCHING_CURRENTDATASET:
            return { ...state, isFetching: action.isFetching };
        case GET_CATALOGS:
            return { ...state, catalogs: action.catalogs };
        case GET_CATALOGCOUNT: {
            const { catalogs } = state;
            if (catalogs) {
                return {
                    ...state, catalogs: catalogs.map((catalog) =>
                        catalog.Identifier === action.catalog.Identifier ?
                            { ...catalog, DatasetCount: action.catalog.DatasetCount } :
                            catalog)
                };
            }
            return state;
        }
        default:
            return state;
    }
};
