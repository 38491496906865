import React, { useRef } from "react";
import { connect } from "react-redux";
import {bindActionCreators} from "redux";
import { IDatasetMeasureTreeModel, setFilteredObservationsQuery } from "../../actions/CCBDataset/CCBDatasetActions";
import {MEASURE_IDENTIFIER} from "../../actions/CCBDataset/CCBDatasetConstants";
import {IActionSetFilter, IFilter, setFilter} from "../../actions/Filters/FilterActions";
import FancyTree from "../FancyTree/FancyTree";
import {MeasureStepDefinition} from "./MeasureStepDefinition";
import {IStepProps} from "./Step";
import { injectIntl, WrappedComponentProps } from "react-intl";

export interface IMeasuresStepProps extends IStepProps {
    measureData: MeasureStepDefinition;
    measureTree: IDatasetMeasureTreeModel;
    isFetching: boolean;
    setFilter: (selection: IFilter) => IActionSetFilter;
    filters: Map<string, IFilter>;
    setFilteredObservationsQuery: () => any
}

export const MeasuresStep: React.FC<IMeasuresStepProps & WrappedComponentProps > = (props) => {
    const fancyTreeRef = useRef<FancyTree>(null);

    const getSelectionFromFancyTree = () => {
        const fancyTree: any = fancyTreeRef.current;
        if (fancyTree === undefined || fancyTree === null)
            return;

        const currentSelection: IFilter = {
            FilteredIdentifiers: fancyTree.getSelectedKeys(),
            Identifier: MEASURE_IDENTIFIER
        };

        props.setFilter(currentSelection);
        props.setFilteredObservationsQuery();
    }

    const getCurrentSelection = (identifier: string): IFilter => {
        const {filters} = props;

        const currentSelection = filters.get(identifier);

        if (currentSelection) {
            return currentSelection;
        }

        return {
            FilteredIdentifiers: [],
            Identifier: identifier
        };
    }
    
    const { measureTree, measureData } = props;
    if (props.currentStep === measureData.index && measureTree) {
        const filter = getCurrentSelection(MEASURE_IDENTIFIER);
        return (
            <React.Fragment>
                <FancyTree intl={props.intl} ref={fancyTreeRef} source={measureTree.MeasureTree} triggerSelectionChanged={() => getSelectionFromFancyTree()} selection={
                    (filter && filter.FilteredIdentifiers && filter.FilteredIdentifiers.length > 0) ?
                        filter.FilteredIdentifiers :
                        []
                }
                />
            </React.Fragment>
        );
    } else {
        return <React.Fragment />;
    }
}

export const mapStateToProps = ({Selection, CurrentDataset}: any) => {
    return {
        filters: Selection.Filters,
        isFetching: CurrentDataset.isFetching,
        measureTree: CurrentDataset.datasetMeasureTreeModel
    };
};

export const mapDispatchToProps = (dispatch: any) => { // tslint:disable-line
    return {
        setFilter: bindActionCreators(setFilter, dispatch),
        setFilteredObservationsQuery: bindActionCreators(setFilteredObservationsQuery, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MeasuresStep));
