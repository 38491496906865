import React from "react";
import { Route } from "react-router-dom";
import CCBDatasetDetailContainer from "../../containers/CCBDataset/CCBDatasetDetailContainer";
import CCBDatasetContainer from "../../containers/CCBDataset/CCBDatasetListContainer";
import InfoPageContainer from "../../containers/ContentPage/InfoPageContainer";
import FaqPageContainer from "../../containers/FaqPage/FaqPageContainer";
import PrivacyPageContainer from "../../containers/ContentPage/PrivacyPageContainer";
import HomePageContainer from "../../containers/HomePage/HomePageContainer";
import ThemesPageContainer from "../../containers/ThemesPage/ThemesPageContainer";

const exportedObject =
[
    <Route key="homepage" exact path="/" component={HomePageContainer} />,
    <Route key="infopage" path="/info/:UniqueId" component={InfoPageContainer} />,
    <Route key="detailDataset" path="/detail/:catalog/:identifier" component={CCBDatasetDetailContainer} />,
    <Route key="listDatasets" path="/list/:catalog" component={CCBDatasetContainer} />,
    <Route key="catalogSelector" path="/list/" component={CCBDatasetContainer} />,
    <Route key="faqpage" path="/faq" component={FaqPageContainer} />,
    <Route key="privacypage" path="/privacy" component={PrivacyPageContainer} />,
    <Route key="cbsthemes" path="/themes/CBS" exact component={ThemesPageContainer} />,
    <Route key="cbsthemes" path="/themes/CBS/:themenumber" exact component={ThemesPageContainer} />,
];

export default exportedObject;