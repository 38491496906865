import React from "react";
import { WrappedComponentProps, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { ICatalogsState } from "../../types/ICatalogsState";
import { ILocalizationState } from "../../types/ILocalizationState";
import { IUIState } from "../../types/IUIState";
import "./Nav.scss";

export interface INavProps {
    setCurrentLanguage: (language: string) => void;
    localization: ILocalizationState;
    uiState: IUIState;
    CatalogsState: ICatalogsState;
    toggleNav: () => void;
    toggleInfoSubnav: () => void;
    changeInfoSubNav: (value: boolean) => void;
    toggleDatasetsSubnav: () => void;
    changeDatasetsSubNav: (value: boolean) => void;
    getContentPageContext: (language: string, uniqueId: string) => (dispatch: any) => Promise<void>;
}

export class Nav extends React.Component<INavProps & WrappedComponentProps, {}> {

    // Todo generic solution. For menu items on click close.
    public closeMobileMenu = () => {
        const largewidth = 992;
        if (this.props.uiState.navToggle === true && window.innerWidth <= largewidth) {
            this.props.toggleNav();
        }
    }

    public setLanguage = (e: any, lang: string) => {
        const { setCurrentLanguage } = this.props;
        e.preventDefault();
        setCurrentLanguage(lang);
        this.closeMobileMenu();
    }

    public toggleInfoSubnav = (e: any) => {
        e.preventDefault();
        const { toggleInfoSubnav } = this.props;
        toggleInfoSubnav();
    }

    public changeInfoSubnav = (e: any, value: boolean) => {
        e.preventDefault();
        const { changeInfoSubNav } = this.props;
        changeInfoSubNav(value);
    }

    public toggleDatasetsSubnav = (e: any) => {
        e.preventDefault();
        const { toggleDatasetsSubnav } = this.props;
        toggleDatasetsSubnav();
    }

    public changeDatasetsSubnav = (e: any, value: boolean) => {
        e.preventDefault();
        const { changeDatasetsSubNav } = this.props;
        changeDatasetsSubNav(value);
    }

    public getUserLink = (currentLanguage: string, uniqueId: string, linkTo: string) => {
        return <Link onClick={() => { this.props.getContentPageContext(currentLanguage, uniqueId); this.closeMobileMenu(); }} to={linkTo}>{this.props.intl.formatMessage({
            id: "nav.link.users",
            defaultMessage: "Consument"
        })}</Link>;
    }

    public getAnalystsLink = (currentLanguage: string, uniqueId: string, linkTo: string) => {
        return <Link onClick={() => { this.props.getContentPageContext(currentLanguage, uniqueId); this.closeMobileMenu(); }} to={linkTo}>{this.props.intl.formatMessage({
            id: "nav.link.analysts",
            defaultMessage: "Data analisten"
        })}</Link>;
    }

    public getDevelopersLink = (currentLanguage: string, uniqueId: string, linkTo: string) => {
        return <Link onClick={() => { this.props.getContentPageContext(currentLanguage, uniqueId); this.closeMobileMenu(); }} to={linkTo}>{this.props.intl.formatMessage({
            id: "nav.link.developers",
            defaultMessage: "Ontwikkelaars"
        })}</Link>;
    }

    public setInfoMenu = (currentLanguage: string) => {
        if (currentLanguage === "nl" || currentLanguage === undefined) {
            return <ul role="menu">
                <li role="menuitem">
                    {this.getUserLink(currentLanguage, "gebruikers", "/info/gebruikers")}
                </li>
                <li role="menuitem">
                    {this.getAnalystsLink(currentLanguage, "analisten", "/info/analisten")}
                </li>
                <li role="menuitem">
                    {this.getDevelopersLink(currentLanguage, "ontwikkelaars", "/info/ontwikkelaars")}
                </li>
            </ul>;
        } else if (currentLanguage === "en") {
            return <ul role="menu">
                <li role="menuitem">
                    {this.getUserLink(currentLanguage, "users", "/info/users")}
                </li>
                <li role="menuitem">
                    {this.getAnalystsLink(currentLanguage, "analysts", "/info/analysts")}
                </li>
                <li role="menuitem">
                    {this.getDevelopersLink(currentLanguage, "developers", "/info/developers")}
                </li>
            </ul>;
        } else if (currentLanguage === "de") {
            return <ul role="menu">
                <li role="menuitem">
                    {this.getUserLink(currentLanguage, "benutzer", "/info/benutzer")}
                </li>
                <li role="menuitem">
                    {this.getAnalystsLink(currentLanguage, "analyst", "/info/analyst")}
                </li>
                <li role="menuitem">
                    {this.getDevelopersLink(currentLanguage, "entwickler", "/info/entwickler")}
                </li>
            </ul>;
        }
    }

    public getCatalogLink = (catalog: string, linkTo: string) => {
        return <Link onClick={this.closeMobileMenu} to={linkTo}>{catalog}</Link>;
    }

    public setCatalogMenu = () => {
        const { CatalogsState } = this.props;
        const catalogItems = CatalogsState.catalogs?.map((catalog, i) => {
            let url = `/themes/${catalog.Identifier}`;
            return <li key={catalog.Identifier} role="menuitem">
                {this.getCatalogLink(catalog.Title, url)}
            </li>;
        });
        return <ul role="menu">
            {catalogItems}
        </ul>;
    };

    public render() {
        const { toggleNav, uiState, localization } = this.props;
        const { currentLanguage } = localization;
        const subnavInfoActive = uiState.subnavInfoToggle ? "open" : "";
        const subnavDatasetsActive = uiState.subnavDatasetsToggle ? "open" : "";
        const navVisible = uiState.navToggle ? "show" : "hide";
        const buttonClose = uiState.navToggle ? "close" : "open";

        const infoMenu = this.setInfoMenu(currentLanguage);
        const catalogMenu = this.setCatalogMenu();

        return (
            <React.Fragment>
                <button onClick={toggleNav} className={`hamburger ${buttonClose}`} type="button" aria-label="hamburger menu">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </button>

                <nav className={`menu ${navVisible}`} aria-labelledby="mainmenulabel" role="navigation" aria-label="Navigation">
                    <h2 id="mainmenulabel" className="hidden">{this.props.intl.formatMessage({ id: "nav.label.main", defaultMessage: "Hoofdmenu" })}</h2>

                    <ul role="menubar">
                        <li className="current" role="menuitem">
                            <span className="hidden">{this.props.intl.formatMessage({ id: "nav.link.current", defaultMessage: "Huidige pagina: " })}</span>
                            <Link onClick={this.closeMobileMenu} to="/" aria-current="page">{this.props.intl.formatMessage({ id: "nav.link.home", defaultMessage: "Home" })}</Link>
                        </li>

                        <li onMouseLeave={(e) => this.changeDatasetsSubnav(e, false)}
                            onMouseEnter={(e) => this.changeDatasetsSubnav(e, true)}
                            onFocus={(e) => this.changeDatasetsSubnav(e, true)}
                            onBlur={(e) => this.changeDatasetsSubnav(e, false)}
                            className={`subnav ${subnavDatasetsActive}`}
                            role="menuitem"
                            aria-haspopup="true">
                            {/*<Link onClick={this.closeMobileMenu} to="/list">{this.props.intl.formatMessage({ id: "nav.link.datasets", defaultMessage: "Datasets" })}</Link>*/}
                            <a href="/#"
                                onClick={(e: any) => { e.preventDefault(); }}
                                className="span-anchor">
                                {this.props.intl.formatMessage({ id: "nav.link.datasets", defaultMessage: "Datasets" })}
                            </a>
                            {catalogMenu}
                        </li>

                        <li onMouseLeave={(e) => this.changeInfoSubnav(e, false)}
                            onMouseEnter={(e) => this.changeInfoSubnav(e, true)}
                            onFocus={(e) => this.changeInfoSubnav(e, true)}
                            onBlur={(e) => this.changeInfoSubnav(e, false)}
                            className={`subnav ${subnavInfoActive}`}
                            role="menuitem"
                            aria-haspopup="true">
                            <a href="/#"
                                onClick={(e: any) => { e.preventDefault(); }}
                                className="span-anchor">
                                {this.props.intl.formatMessage({ id: "nav.link.audience", defaultMessage: "Informatie voor" })}
                            </a>
                            {infoMenu}
                        </li>

                        <li role="menuitem">
                            <Link onClick={this.closeMobileMenu} to="/faq">{this.props.intl.formatMessage({ id: "nav.link.faq", defaultMessage: "FAQ" })}</Link>
                        </li>

                        <hr className="smallview" />

                        <li className="smallview" role="menuitem">
                            <Link onClick={this.closeMobileMenu} to="/privacy">{this.props.intl.formatMessage({ id: "footer.link.privacy", defaultMessage: "Privacy" })}</Link>
                        </li>

                        { /*TEMP: COMMENTED OUT TILL WE SUPPORT MULTI LANGUAGE IN CCB*/}
                        {/*<li className="smallview" role="menuitem">*/}
                        {/*    <a href="/#" onClick={(e) => this.setLanguage(e, "nl")}>Nederlands</a>*/}
                        {/*</li>*/}

                        {/*<li className="smallview" role="menuitem">*/}
                        {/*    <a href="/#" onClick={(e) => this.setLanguage(e, "en")}>English</a>*/}
                        {/*</li>*/}

                        {/*<li className="smallview" role="menuitem">*/}
                        {/*    <a href="/#" onClick={(e) => this.setLanguage(e, "de")}>Deutsch</a>*/}
                        {/*</li>*/}

                        <li className="smallview" role="menuitem">
                            <a onClick={this.closeMobileMenu} href={this.props.intl.formatMessage({ id: "footer.link.url.cbs", defaultMessage: "https://www.cbs.nl" })}>{this.props.intl.formatMessage({ id: "footer.link.text.cbs", defaultMessage: "CBS.nl" })}</a>
                        </li>
                    </ul>
                </nav>
            </React.Fragment>
        );
    }
}

export default injectIntl(Nav);
