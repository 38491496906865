import * as constants from "./LocalizationConstants";

// ACTION DEFINITION
export interface IActionSetCurrentLanguage {
    language: string;
    type: constants.SET_CURRENTLANGUAGE;
}

// Union Action Types
export type LocalizationActions = IActionSetCurrentLanguage;

export const setCurrentLanguage = (setToLanguage: string): IActionSetCurrentLanguage => {
    return {
        language: setToLanguage,
        type: constants.SET_CURRENTLANGUAGE
    };
};
