import React from "react";
import { WrappedComponentProps, injectIntl} from "react-intl";
import {Link} from "react-router-dom";
import cbsLogo from "../../assets/img/cbs.svg";
import {ILocalizationState} from "../../types/ILocalizationState";
import { IUIState } from "../../types/IUIState";
import { ICatalogsState } from "../../types/ICatalogsState";
import Nav from "../Nav/Nav";
import Searchbar from "../Search/Searchbar";
import "./Header.scss";

export interface IHeaderProps extends WrappedComponentProps {
    CatalogsState: ICatalogsState;
    setCurrentLanguage: (language: string) => void;
    localization: ILocalizationState;
    uiState: IUIState;
    toggleNavToggle: () => void;
    toggleInfoSubnav: () => void;
    changeInfoSubNav: (value: boolean) => void;
    toggleDatasetsSubnav: () => void;
    changeDatasetsSubNav: (value: boolean) => void;
    toggleSearchbarToggle: () => void;
    getContentPageContext: (language: string, uniqueId: string) => (dispatch: any) => Promise<void>;
    actionSetSearch: (searchParams: string) => void;
    getCatalogs: () => (dispatch: any) => Promise<void>;
}

class Header extends React.Component<IHeaderProps & WrappedComponentProps, {}> {

    componentDidMount(): void {
        this.props.getCatalogs();
    }

    public render() {
        return (
            <header>
                <div className="box">
                    <div className="grid">
                        <a href="#maincontent" className="to-main">{this.props.intl.formatMessage({ id: "header.skiplink", defaultMessage: "Ga direct naar paginainhoud" })}</a>

                        <div className="grid-item logo-area">
                            <Link to="/">
                                <img src={cbsLogo} alt={this.props.intl.formatMessage({ id: "header.link.logo", defaultMessage: "CBS logo, link naar homepage" })} />
                            </Link>
                        </div>

                        <div className="grid-item main-area">
                            <Nav uiState={this.props.uiState}
                                toggleNav={this.props.toggleNavToggle}
                                CatalogsState={this.props.CatalogsState}
                                toggleInfoSubnav={this.props.toggleInfoSubnav}
                                changeInfoSubNav={this.props.changeInfoSubNav}
                                toggleDatasetsSubnav={this.props.toggleDatasetsSubnav}
                                changeDatasetsSubNav={this.props.changeDatasetsSubNav}
                                localization={this.props.localization}
                                setCurrentLanguage={this.props.setCurrentLanguage}
                                getContentPageContext={this.props.getContentPageContext} />

                            <Searchbar uiState={this.props.uiState} toggleSearchbar={this.props.toggleSearchbarToggle} actionSetSearch={this.props.actionSetSearch} />
                        </div>

                        <div className="grid-item nav-area">
                            <div className="addition">
                                <span className="statline">{this.props.intl.formatMessage({ id: "header.title", defaultMessage: "Dataportaal" })}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default injectIntl(Header);
