import {FilterActions} from "../../actions/Filters/FilterActions";
import { CLEAN_UP_CURRENT_FILTERS, SET_FILTER } from "../../actions/Filters/FilterConstants";
import {defaultFiltersState, IFiltersState} from "../../types/IFiltersState";

export function FiltersReducer(state: IFiltersState, action: FilterActions): IFiltersState {
    switch (action.type) {
        case SET_FILTER: {
            return { ...state, Filters: state.Filters.set(action.filter.Identifier, action.filter)};
        }
        case CLEAN_UP_CURRENT_FILTERS: {
            return  defaultFiltersState();
        }
        default:
            return state;
    }
}
